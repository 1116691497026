import * as Yup from "yup";
import { regex } from "../regex/regex";

export const signInValidationSchema = Yup.object().shape({
  username: Yup.string()
    .required("Email is required.")
    .matches(regex.mailFormat, "Invalid email format")
    .transform((val) => val.toLowerCase())
    .trim(),
  password: Yup.string().required("Password is required."),
});
