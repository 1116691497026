import { Link } from "react-router-dom";
import { commonPathConstants } from "../../../Routes/helper/commonRoutes";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer_wrapper">
      <p className="footer_para">
        Copyright 2024 leadhawk.io. All Rights Reserved.
      </p>
      <p className="footer_para_mobile">All Rights Reserved.</p>
      <p className="footer_para_mobile">
        Copyright 2024 <u>leadhawk.io.</u>{" "}
      </p>

      <ul>
        <li>
          <Link to={`/${commonPathConstants.PRIVACY_POLICY}`}>
            Privacy Policy
          </Link>
        </li>
        <li>
          <Link to={`/${commonPathConstants.TERMS_AND_CONDITIONS}`}>
            Terms and Conditions
          </Link>
        </li>
        <li>
          <Link to={`/${commonPathConstants.RETURN_POLICY}`}>
            Return Policy
          </Link>
        </li>
        <li>
          <Link to={`/${commonPathConstants.DISCLAIMER}`}>Disclaimer</Link>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
