import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { newHiresValidationSchema } from "../../validators/newHires-validation-schema";
import {
  editAdminHires,
  postAdminHires,
  resetErrors as resetAdminErrors,
} from "../../store/slice/adminSlice/adminHireSlice";
import { RESPONSE_STATUS, USER_ROLE } from "../../enums/enums";
import {
  editEntry,
  postNewHires,
  resetErrors as resetDataErrors,
} from "../../store/slice/dataHiresSlice";
import ButtonCustom from "../../features/ButtonCustom/ButtonCustom";
import { newHiresFormValues } from "../../interfaces/interfaces";
import { hiresFields, pageName } from "./helper/fieldsData";
import MuiFormInput from "../FormComponents/MuiFormInput";
import useNotification from "../../hooks/useNotification";
import CircularLoader from "../Loaders/CircularLoader";
import CancelButton from "../CancelButton";
import { adminPath, adminPathConstants } from "../../Routes/helper/adminRoutes";
import {
  DATA_ENTRY,
  dataPathConstants,
} from "../../Routes/helper/dataEntryRoutes";

export default function HireForm() {
  const adminEntries = useAppSelector((state) => state.adminHires.entries);
  const executiveEntries = useAppSelector((state) => state.dataHires.entries);
  const userRole = useAppSelector((state) => state.signin.role);
  const hires = useAppSelector((state) =>
    userRole === USER_ROLE.ADMIN ? state.adminHires : state.dataHires
  );

  const { showNotification } = useNotification();

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [dispatchStatus, setDispatchStatus] = useState(false);

  const form = useForm<newHiresFormValues>({
    resolver: yupResolver(newHiresValidationSchema),
  });

  useEffect(() => {
    let result;
    if (userRole === USER_ROLE.ADMIN) {
      result = adminEntries?.find((entry) => entry.id === id);
    } else {
      result = executiveEntries?.find((entry) => entry.id === id);
    }
    if (result) {
      form.reset(result);
    }
  }, [id, adminEntries, executiveEntries, form, userRole]);

  useEffect(() => {
    if (id) {
      if (userRole === USER_ROLE.ADMIN && !adminEntries.length) {
        navigate(
          `/${adminPath}/${adminPathConstants.NEW_HIRES}?page=1&count=25&tab=live`
        );
      } else if (
        userRole === USER_ROLE.DATA_ENTRY_OPERATOR &&
        !executiveEntries.length
      ) {
        navigate(
          `/${DATA_ENTRY}/${dataPathConstants.DATA_HIRES}?page=1&count=25`
        );
      }
    }
  }, [id, executiveEntries, form, userRole, adminEntries, navigate]);

  useEffect(() => {
    if (dispatchStatus && hires.status === RESPONSE_STATUS.SUCCEEDED) {
      showNotification(
        "success",
        `Executive hire ${id ? "updated" : "added"} successfully`
      );
      if (userRole === USER_ROLE.ADMIN) {
        navigate(
          `/${adminPath}/${adminPathConstants.NEW_HIRES}?page=1&count=25&tab=live`
        );
      } else {
        navigate(
          `/${DATA_ENTRY}/${dataPathConstants.DATA_HIRES}?page=1&count=25`
        );
      }
    }
    if (hires.error && typeof hires.error === "string") {
      showNotification("error", hires.error);
      dispatch(
        userRole === USER_ROLE.ADMIN ? resetAdminErrors() : resetDataErrors()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hires, dispatchStatus, id, navigate, userRole]);

  const handleSubmit: SubmitHandler<newHiresFormValues> = async (formData) => {
    if (userRole === USER_ROLE.ADMIN) {
      if (id) {
        dispatch(editAdminHires({ id, newData: formData }));
      } else {
        dispatch(postAdminHires({ formData }));
      }
    } else {
      if (id) {
        dispatch(editEntry({ pageName, id, newData: formData }));
      } else {
        dispatch(postNewHires({ formData }));
      }
    }
    setDispatchStatus(true);
  };

  return (
    <FormProvider {...form}>
      <form
        className="form-box"
        onSubmit={form.handleSubmit(handleSubmit)}
        onChange={() =>
          dispatch(
            userRole === USER_ROLE.ADMIN
              ? resetAdminErrors()
              : resetDataErrors()
          )
        }
      >
        <div className="form-row">
          {hiresFields.map((hiresField, index) => {
            return (
              <div className="flex flex-col" key={index}>
                <MuiFormInput
                  {...hiresField}
                  errorMessage={
                    typeof hires.error !== "string"
                      ? hires.error[hiresField.name]
                      : ""
                  }
                />
              </div>
            );
          })}
        </div>
        <div className="form-button">
          <ButtonCustom
            type="submit"
            endIcon={
              hires.status === RESPONSE_STATUS.LOADING && (
                <CircularLoader size={20} />
              )
            }
            disabled={hires.status === RESPONSE_STATUS.LOADING}
          >
            Save Details
          </ButtonCustom>
          <CancelButton />
        </div>
      </form>
    </FormProvider>
  );
}
