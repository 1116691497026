import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RESPONSE_STATUS } from "../../enums/enums";
import { ProfileData, profileFormValues } from "../../interfaces/interfaces";
import { errorHandler } from "./helper/errorHandler";
import { axiosInstance } from "../../secutityUtils/axiosInstance";

const API_URL = `${import.meta.env.VITE_DEV_USER_URL}/api/v1/profile/`;

type InitialState = {
  data: ProfileData | null;
  status: RESPONSE_STATUS;
  error: string | Record<string, string>;
};

const initialState: InitialState = {
  data: null,
  status: RESPONSE_STATUS.IDLE,
  error: "",
};

// Async thunk to fetch profile
export const fetchProfile = createAsyncThunk(
  "profile/fetchProfile",

  async () => {
    const response = await axiosInstance.get(API_URL);
    return response.data;
  }
);

// Async thunk to update profile
export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (updatedData: profileFormValues, thunkAPI) => {
    try {
      const response = await axiosInstance.patch(API_URL, updatedData);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

// Async thunk to delete profile
export const deleteProfile = createAsyncThunk(
  "profile/deleteProfile",
  async () => {
    await axiosInstance.delete(API_URL);
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetErrors: (state) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })

      .addCase(updateProfile.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })

      .addCase(updateProfile.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.data = action.payload;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(deleteProfile.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })

      .addCase(deleteProfile.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(deleteProfile.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      });
    // Similar cases for updateProfile and deleteProfile can be added
  },
});
export const { resetErrors } = profileSlice.actions;

export default profileSlice.reducer;
