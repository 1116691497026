import Disclaimer from "../../components/Disclaimer/Disclaimer";
import LandingPage from "../../components/LandingPage/LandingPage";
import PrivacyPolicy from "../../components/PrivacyPolicy/PrivacyPolicy";
import ReturnPolicy from "../../components/ReturnPolicy/ReturnPolicy";
import TermsAndConditions from "../../components/TermsAndConditions/TermsAndConditions";
import AboutUs from "../../components/aboutUs/AboutUs";
import ContactUs from "../../components/contactUs/ContactUs";
import Pricing from "../../components/pricing/Pricing";

export const commonPathConstants = {
  PRICING: "pricing",
  ABOUT_US: "about-us",
  CONTACT_US: "contact-us",
  PRIVACY_POLICY: "privacy-policy",
  TERMS_AND_CONDITIONS: "terms-and-conditions",
  DISCLAIMER: "disclaimer",
  RETURN_POLICY: "return-policy",
};

export const commonRoutes = [
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: `/${commonPathConstants.PRICING}`,
    element: <Pricing />,
  },
  {
    path: `/${commonPathConstants.ABOUT_US}`,
    element: <AboutUs />,
  },
  {
    path: `/${commonPathConstants.CONTACT_US}`,
    element: <ContactUs />,
  },
  {
    path: `/${commonPathConstants.PRIVACY_POLICY}`,
    element: <PrivacyPolicy />,
  },
  {
    path: `/${commonPathConstants.TERMS_AND_CONDITIONS}`,
    element: <TermsAndConditions />,
  },
  {
    path: `/${commonPathConstants.DISCLAIMER}`,
    element: <Disclaimer />,
  },
  {
    path: `/${commonPathConstants.RETURN_POLICY}`,
    element: <ReturnPolicy />,
  },
];
