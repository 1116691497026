import * as Yup from "yup";
import { passwordValidationSchema } from "./password-validation-schema";

export const changePasswordValidationSchema = Yup.object()
  .shape({
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .trim(),
  })
  .concat(passwordValidationSchema);
