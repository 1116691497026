import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import "./AccordionComponent.scss";
import SavedSearchesDetails from "../SavedSearchesDetails/SavedSearchesDetails";
import { userFlowScreens } from "../../../../constants/userFlowConstants";
import { useEffect } from "react";
import { fetchSavedSearches } from "../../../../store/slice/filterSlice";
import { useAppDispatch } from "../../../../store";

function AccordionComponent() {
  const dispatch = useAppDispatch();

  const accordionSummaryStyles = {
    height: '100%',
    overflow: "scroll",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.4rem",
  };

  useEffect(() => {
    // fetchAll the SavedSearches data for the User
    dispatch(fetchSavedSearches());
  }, [dispatch]);

  const headings = [
    " New Hires",
    "Funding",
    "Job Postings",
    "Company Press Releases",
  ];
  return (
    <div className="grid 2xl:grid-cols-4 sm:grid-cols-2 mb-2 gap-2">
      {headings.map((heading, index) => {
        return (
          <Accordion key={index} expanded={true} className="mb-1 border border-slate-600">
            <AccordionSummary>{heading}</AccordionSummary>
            <AccordionDetails style={accordionSummaryStyles}>
              <SavedSearchesDetails screenName={userFlowScreens[index]} />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}

export default AccordionComponent;
