import { Tooltip } from "@mui/material";
import { GridEditInputCell, GridRenderEditCellParams } from "@mui/x-data-grid";

export default function URLEditCell({
  params,
}: {
  params: GridRenderEditCellParams;
}) {
  return (
    <Tooltip title={params.error ? "Please enter a valid URL!" : ""} arrow>
      <GridEditInputCell {...params} />
    </Tooltip>
  );
}
