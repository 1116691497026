import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RESPONSE_STATUS } from "../../enums/enums";
import ButtonCustom from "../../features/ButtonCustom/ButtonCustom";
import { NotificationContext } from "../../contexts/APIContext/Notification/NotificationContext";
import { profileFormValues } from "../../interfaces/interfaces";
import { profileFields } from "./helper/fieldsData";
import MuiFormInput from "../FormComponents/MuiFormInput";
import { adminPathConstants } from "../../Routes/helper/adminRoutes";
import { profileValidationSchema } from "../../validators/profile-validation-schema";
import {
  editDEOProfile,
  postNewProfiles,
  resetErrors,
} from "../../store/slice/adminSlice/createProfileSlice";
import CircularLoader from "../Loaders/CircularLoader";
import CancelButton from "../CancelButton";

export default function ProfileForm() {
  const adminEntries = useAppSelector((state) => state.adminProfile.entries);

  const profile = useAppSelector((state) => state.adminProfile);

  const { showNotification } = useContext(NotificationContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [dispatchStatus, setDispatchStatus] = useState(false);

  const form = useForm<profileFormValues>({
    resolver: yupResolver(profileValidationSchema),
  });

  useEffect(() => {
    let result;

    result = adminEntries?.find((entry) => entry.id === id);

    if (result) {
      form.reset(result);
    }
  }, [id, adminEntries, form]);

  useEffect(() => {
    if (id && !adminEntries.length) {
      navigate(`/admin/${adminPathConstants.PROFILES}`);
    }
  }, [id, adminEntries, navigate]);

  useEffect(() => {
    if (dispatchStatus && profile.status === RESPONSE_STATUS.SUCCEEDED) {
      showNotification(
        "success",
        `DEO profile ${id ? "updated" : "created"} successfully`
      );

      navigate(`/admin/${adminPathConstants.PROFILES}`);
    }
    if (profile.error && typeof profile.error === "string") {
      showNotification("error", profile.error);
      dispatch(resetErrors());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const handleSubmit: SubmitHandler<profileFormValues> = async (formData) => {
    if (id) {
      dispatch(editDEOProfile({ id, newData: formData }));
    } else {
      dispatch(postNewProfiles({ formData }));
    }

    setDispatchStatus(true);
  };

  const email = useWatch({ control: form.control, name: "email" });

  useEffect(() => {
    if (email) {
      form.setValue("email", email.toLowerCase());
    }
  }, [email, form]);

  return (
    <FormProvider {...form}>
      <form
        className="form-box"
        onSubmit={form.handleSubmit(handleSubmit)}
        onChange={() => dispatch(resetErrors())}
      >
        <div className="form-row">
          {profileFields.map((profileField, index) => {
            return (
              <MuiFormInput
                key={index}
                {...profileField}
                disabled={!!(profileField.label === 'Email' && id)}
                errorMessage={
                  typeof profile.error !== "string"
                    ? profile.error[profileField.name]
                    : ""
                }
              />
            );
          })}
        </div>
        <div className="form-button">
          <ButtonCustom
            type="submit"
            endIcon={
              profile.status === RESPONSE_STATUS.LOADING && (
                <CircularLoader size={20} />
              )
            }
            disabled={profile.status === RESPONSE_STATUS.LOADING}
          >
            Save Details
          </ButtonCustom>
          <CancelButton />
        </div>
      </form>
    </FormProvider>
  );
}
