import { Link } from "react-router-dom";
import ArrowRightIcon from "../../../../assets/icons/ArrowRightIcon";
import { FeaturesContext } from "../../../../contexts/APIContext/Features/FeaturesContext";
import { useContext } from "react";

const Changing = () => {
  const { setSigninTab } = useContext(FeaturesContext);
  return (
    <div className="changing_wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h1 className="text-capitalize">
              Changing the game around trigger event prospecting
            </h1>
            <p>
              Our objective is to change the status quo in prospecting. There
              are an abundance of prospecting tools on the market, just none
              with the individual sales professional in mind. We're here to help
              you separate yourself and stand out.
            </p>

            <Link to="/auth">
              <div className="changing_btn">
                <button onClick={() => setSigninTab("signup")}>
                  begin your free Trial <ArrowRightIcon />
                </button>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <img src="/assets/images/changing_logo.webp" alt="Changing Logo" />
    </div>
  );
};

export default Changing;
