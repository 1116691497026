import { useState } from "react";
import "./PricingTable.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "../../../store";
import { SUBSCRIPTION } from "../../../enums/enums";
import { CircularProgress } from "@mui/material";
import { axiosInstance } from "../../../secutityUtils/axiosInstance";
import EnterpriseFormModal from "../../EnterpriseFormModal/EnterpriseFormModal";

const PricingTable = () => {
  const [activeCard, setActiveCard] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [showEnterpriseModal, setShowEnterpriseModal] = useState(false);

  const user = useAppSelector((state) => state.signin.user);

  const priceId = import.meta.env.VITE_STRIPE_PRICE_ID;

  const dummyPricingData =
    user?.subscription === SUBSCRIPTION.TIER_I
      ? [
          {
            id: "price_1NG2stGfMPxc46JMR7wT2RKy",
            plan: "Enterprise",
            features: [
              "Unlimited Saved Searches",
              "Email Alerts",
              "Multiple Users",
            ],
            buttonLabel: "Contact Sales",
          },
        ]
      : [
          {
            id: priceId,
            plan: "Pro",
            price: "$49.99/month",
            features: ["8 Saved Searches", "Email alerts", "One User"],
            buttonLabel: "Select Pro",
          },
          {
            id: "price_1NG2stGfMPxc46JMR7wT2RKy",
            plan: "Enterprise",
            features: [
              "Unlimited Saved Searches",
              "Email Alerts",
              "Multiple Users",
            ],
            buttonLabel: "Contact Sales",
          },
        ];

  const handleSelectPlan = () => {
    setActiveCard(true);
  };

  const handleClick = (id: string, plan: string) => {
    if (plan === "Enterprise") {
      return setShowEnterpriseModal(true);
    }
    setSelectedPlan(plan);
    axiosInstance
      .post(`${import.meta.env.VITE_DEV_USER_URL}/create-checkout-session`, {
        priceId: id,
      })
      .then((response) => {
        setSelectedPlan("");
        window.location.href = response.data;
        // You can update the UI or take any other actions based on the server response
      })
      .catch((error) => {
        console.error("Error creating customer:", error);
        // Handle any errors that occurred during the API call
      });
  };

  return (
    <div className="pricing-table">
      {user?.showSubscription && (
        <div>
          <p className="freeTrial">
            Your free trial has expired. Please upgrade to the Pro tier
          </p>
        </div>
      )}
      {user?.subscription === SUBSCRIPTION.TIER_I && (
        <div>
          <p className="freeTrial">You can upgrade your plan to Enterprise.</p>
        </div>
      )}
      <div className="pricing-cards">
        {dummyPricingData.map((plan) => (
          <div
            key={plan.id}
            className={`pricing-card ${activeCard === plan.id ? "active" : ""}`}
            onClick={() => handleSelectPlan()}
          >
            <h3>{plan.plan}</h3>
            <p>{plan.price}</p>
            <ul>
              {plan.features.map((feature, index) => (
                <li key={index}>
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "green", marginRight: "5px" }}
                  />
                  {feature}
                </li>
              ))}
            </ul>

            <button
              className="text-white flex justify-center items-center m-auto"
              onClick={() => handleClick(plan.id, plan.plan)}
            >
              {plan.buttonLabel}
              {selectedPlan === plan.plan && (
                <CircularProgress color="inherit" className="ml-2" size={20} />
              )}
            </button>
          </div>
        ))}
        {showEnterpriseModal && (
          <EnterpriseFormModal onClose={() => setShowEnterpriseModal(false)} />
        )}
      </div>
    </div>
  );
};

export default PricingTable;
