import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  commonTitles,
  newHiresTitles,
  fundingTitles,
  jobPostingTitles,
  pressReleaseTitles,
  filterCommonTitles,
  commonColumns,
  newHiresColumns,
  fundingColumns,
  pressReleaseColumns,
  jobPostingsColumns,
  userFlowScreens,
  // mapToURL,
} from "../constants/userFlowConstants.js";
import { USER_ROLE } from "../enums/enums.js";

import { rowsInterface } from "../interfaces/interfaces.js";
import LinkCell from "../components/LinkCell/LinkCell.js";

// Create Columns
// { field: 'firstName', headerName: 'First name', width: 130 }

const generateHeaderColumns = (screenName: string) => {
  let columns: GridColDef<rowsInterface>[] = [];
  switch (screenName) {
    case userFlowScreens[0]:
      columns = createColumns(newHiresColumns, newHiresTitles);
      break;
    case userFlowScreens[1]:
      columns = createColumns(fundingColumns, fundingTitles);
      break;
    case userFlowScreens[2]:
      columns = createColumns(jobPostingsColumns, jobPostingTitles);
      break;
    case userFlowScreens[3]:
      columns = createColumns(pressReleaseColumns, pressReleaseTitles);
      break;
  }
  return columns;
};

const createColumns = (screenColumn: string[], titles: string[]) => {
  let obj: GridColDef<rowsInterface> = {
    field: "",
  };
  let formattedColumn: GridColDef<rowsInterface>[] = [];
  screenColumn.forEach((row, i) => {
    obj["field"] = row;
    obj["headerName"] = titles[i];
    obj["width"] = 180;

    obj["filterable"] = true;
    obj["sortable"] = true;
    obj["disableColumnMenu"] = false;
    obj["renderCell"] = (params: GridRenderCellParams<any, string>) => (
      <p>{params.value}</p>
    );

    if (["trigger"].includes(row)) {
      obj["width"] = 100;
    }

    if (["lastName", "firstName"].includes(row)) {
      obj["sortable"] = false;
      obj["disableColumnMenu"] = false;
      obj["filterable"] = true;
      obj["width"] = 100;
    }
    if (["jobLink", "triggerArticle"].includes(row)) {
      obj["width"] = 180;

      obj["renderCell"] = (params: GridRenderCellParams<any, string>) => (
        <LinkCell url={params.value || ""} />
      );

      obj["disableColumnMenu"] = true;
      obj["filterable"] = false;
      obj["sortable"] = false;
      obj["headerClassName"] = "wrap-text-header";
    }

    if (row === "fundingRaised") {
      obj["type"] = "number";
      obj["width"] = 100;
    }

    formattedColumn.push({ ...obj });
  });

  Object.keys(commonTitles).forEach((x, i) => {
    obj["field"] = commonColumns[i];
    obj["headerName"] = x;
    obj["width"] = commonTitles[x];
    obj["filterable"] = true;
    obj["sortable"] = true;
    obj["disableColumnMenu"] = false;
    obj["renderCell"] = (params: GridRenderCellParams<any, string>) => (
      <p>{params.value}</p>
    );
    obj["type"] = "string";
    // obj['align'] = 'center';
    if (commonColumns[i] === "companyName") {
      obj["sortable"] = false;
      obj["filterable"] = true;
    }
    if (commonColumns[i] === "companyEmployeeCount") {
      obj["type"] = "number";
    }
    if (
      commonColumns[i] === "companyWebsite" ||
      commonColumns[i] === "companyLinkedIn"
    ) {
      obj["width"] = 180;
      obj["disableColumnMenu"] = true;
      obj["filterable"] = false;
      obj["sortable"] = false;
      obj["renderCell"] = (params: GridRenderCellParams<any, string>) => {
        if (params.value !== 'N/A') {
          return <LinkCell url={params.value || ""} />
        } else {
          return 'N/A';
        }
      };
      obj["headerClassName"] = "wrap-text-header";
    } else if (commonColumns[i] === "companyHQ") {
      obj.valueGetter = (_, row) => {
        const hq = row.companyCity ? [row.companyCity] : [];
        if (row.companyHQ) {
          hq.push(row.companyHQ);
        }

        return hq.length > 0 ? hq.join(', ') : 'N/A';
      };
    } else {
      obj.valueGetter = value => value || 'N/A';
    }
    formattedColumn.push({ ...obj });
  });
  return formattedColumn;
};

// Create Rows

const processNewHireRows = (screenName: string) => {
  let columns: GridColDef<rowsInterface>[] = [];
  switch (screenName) {
    case userFlowScreens[0]:
      columns = createColumns(newHiresColumns, newHiresTitles);
      break;
    case userFlowScreens[1]:
      columns = createColumns(fundingColumns, fundingTitles);
      break;
    case userFlowScreens[2]:
      columns = createColumns(jobPostingsColumns, jobPostingTitles);
      break;
    case userFlowScreens[3]:
      columns = createColumns(pressReleaseColumns, pressReleaseTitles);
      break;
  }
  return columns;
};

/**
 *
 *  This is to generate Header text for header Component
 * @returns generated text
 */

const generateHeaderText = (key: string) => {
  let text: { primaryText: string; secondaryText: string };
  switch (key) {
    case userFlowScreens[0]:
      text = { primaryText: "New Hires", secondaryText: "View All New Hires" };
      break;
    case userFlowScreens[1]:
      text = { primaryText: "Funding", secondaryText: "View All Funding" };
      break;
    case userFlowScreens[2]:
      text = {
        primaryText: "Job Postings",
        secondaryText: "View All Job Postings",
      };
      break;
    case userFlowScreens[3]:
      text = {
        primaryText: "Company Press Releases",
        secondaryText: "View All Company Press Releases",
      };
      break;
    case userFlowScreens[4]:
      text = {
        primaryText: "My Leads",
        secondaryText: "View All My Leads and Saved Searches",
      };
      break;

    default:
      text = { primaryText: "New Hires", secondaryText: "View All New Hires" };
      break;
  }
  return text;
};

/**
 *
 *  This is to generate Path for Menu based on the role of the user
 * @returns generated path '/newHires'  or '/admin/newHires'
 */

const generatePathBasedOnUser = (role: USER_ROLE | string, key: string) => {
  let string = "";
  if (role === USER_ROLE.USER || role === USER_ROLE.FREE_USER) {
    string = `/${key}`;
  } else if (role === USER_ROLE.DATA_ENTRY_OPERATOR) {
    string = `/data/${key}`;
  } else if (role === USER_ROLE.ADMIN) {
    string = `/admin/${key}`;
  }

  return string;
};

/**
 *
 *  This is to generate filter Titles for dropdownMenu
 * @returns generated array for filters based on screenName
 */

const generateFilterTitles = (key: string) => {
  let filtersName: Array<string> = [];
  switch (key) {
    case userFlowScreens[0]:
      filtersName = ["Position", ...filterCommonTitles];
      break;
    case userFlowScreens[1]:
      filtersName = ["Funding Raised", ...filterCommonTitles];
      break;
    case userFlowScreens[2]:
      filtersName = ["Job Title", ...filterCommonTitles];
      break;
    case userFlowScreens[3]:
      filtersName = ["Trigger", ...filterCommonTitles];
      break;
    case userFlowScreens[4]:
      filtersName = [...filterCommonTitles];
      break;
  }
  return filtersName;
};

const processFiltersToMaterialUIFormat = (data: {
  filters: Array<{ field: string; values: string[] }>;
}) => {
  let formattedData: Record<string, string | string[]> = {};

  data.filters?.forEach((x) => {
    if (x.field && !formattedData[x.field]) {
      formattedData[x.field] =
        x.field !== "Employee Count" && x.field !== "Funding Raised" ? [] : "";
    }

    if (x.field) {
      formattedData[x.field] = x.values;
    }
  });

  return formattedData;
};

// const processSavedSearchesData = (data) => {
//   let formattedData = {};

//   return formattedData;
// };

export {
  processNewHireRows,
  generateHeaderText,
  generatePathBasedOnUser,
  generateHeaderColumns,
  generateFilterTitles,
  processFiltersToMaterialUIFormat as processFilterFormat,
};
