import * as Yup from "yup";
import { regex } from "../regex/regex";

export const nameValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name is required")
    .min(2, "First Name must have at least 2 characters")
    .max(30, "First Name must be under 30 characters")
    .matches(
      regex.stringFormat,
      "First Name should not contain special characters or numbers"
    )
    .trim(),
  lastName: Yup.string()
    .required("Last Name is required")
    .min(2, "Last Name must have at least 2 characters")
    .max(30, "Last Name must be under 30 characters")
    .matches(
      regex.stringFormat,
      "Last Name should not contain special characters or numbers"
    )
    .trim(),
});
