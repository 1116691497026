import { useContext, useEffect, useState } from "react";
import "./header.scss";
import { Link } from "react-router-dom";
import ResponsiveNavbar from "./ResponsiveNavbar";
import { useLocation } from "react-router-dom";
import ArrowRightIcon from "../../../assets/icons/ArrowRightIcon";
import { FeaturesContext } from "../../../contexts/APIContext/Features/FeaturesContext";
import PopOverMenu from "../../PopOver/PopOverMenu";
import { useAppSelector } from "../../../store";

const Header = () => {
  const path = useLocation();
  const [showNavbar, setShowNavbar] = useState(false);

  const { redirectURL } = useContext(FeaturesContext);

  const isAuthenticated = useAppSelector(
    (state) => state.signin.isAuthenticated
  );

  const { selectedTab, setSelectedTab, setSigninTab } =
    useContext(FeaturesContext);

  const handleLinkClick = (data: string) => {
    setSelectedTab(data);
  };

  useEffect(() => {
    if (selectedTab) {
      const yAxisPosition = document.getElementById('myleadHawk')?.offsetTop;
      window.scrollTo({
        top: yAxisPosition && yAxisPosition - 78,
        behavior: 'smooth'
      });
    }
  }, [selectedTab]);

  return (
    <PopOverMenu onOutsideClick={() => setShowNavbar(false)} className="sticky top-0 z-[99]">
      <div className="navbar_wrapper">
        <div className="navbar_menu_item">
          <Link to={isAuthenticated ? redirectURL : "/"}>
            <img src="/assets/images/logo.svg" alt="" className="navbar_logo" />
          </Link>
          <ResponsiveNavbar
            setShowNavbar={setShowNavbar}
            showNavbar={showNavbar}
            handleLinkClick={handleLinkClick}
          />
          <img
            src="/assets/images/menu_right_corner.svg"
            alt=""
            onClick={() => setShowNavbar((prev) => !prev)}
            className="humburger_bar"
          />

          <ul className="navbar_list">
            <li
              className="navbar_item"
              onClick={() => {
                if (selectedTab === "home") {
                  handleLinkClick("features");
                } else {
                  handleLinkClick("home");
                }
              }}
            >
              <Link to="/#myleadHawk" className="navbar_link">
                Features
              </Link>
            </li>
            <li
              className="navbar_item"
              onClick={() => handleLinkClick("pricing")}
            >
              <Link
                to="/pricing"
                className={`navbar_link ${
                  path?.pathname === "/pricing" && "active_link"
                }`}
              >
                Pricing
              </Link>
            </li>
            <li
              className="navbar_item"
              onClick={() => handleLinkClick("about-us")}
            >
              <Link
                to="/about-us"
                className={`navbar_link ${
                  path?.pathname === "/about-us" && "active_link"
                }`}
              >
                About Us
              </Link>
            </li>
            <li
              className="navbar_item"
              onClick={() => handleLinkClick("contact-us")}
            >
              <Link
                to="/contact-us"
                className={`navbar_link ${
                  path?.pathname === "/contact-us" && "active_link"
                }`}
              >
                Contact Us
              </Link>
            </li>
            {isAuthenticated ? (
              <Link
                to={redirectURL}
                className="login_btn responsive-login-btn"
                onClick={() => setShowNavbar(false)}
              >
                Go to Dashboard <ArrowRightIcon />
              </Link>
            ) : (
              <Link
                to="/auth"
                className="login_btn group "
                onClick={() => setSigninTab("signup")}
              >
                SIGN UP{" "}
                <ArrowRightIcon className="group-hover:text-[#45a29e]" />
              </Link>
            )}
          </ul>
        </div>
      </div>
    </PopOverMenu>
  );
};

export default Header;
