import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./videobanner.module.scss";

const ImageWithVideoBanner = ({
  setPopup,
}: {
  setPopup: React.Dispatch<React.SetStateAction<JSX.Element>>;
}) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setPopup(<></>);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [setPopup]);

  return (
    <div className={styles.bg_wrapper_popup_new}>
      <div
        className={`${styles.popup_box_bpn} ${styles.video_main_wrapper} ${styles.profile_nceqoi_popup} ${styles.pb}`}
      >
        <div className={styles.video_wrapper}>
          <video
            src="/assets/images/leadhawk.mp4"
            autoPlay
            controls
            loop
          ></video>
          <div className={styles.close_pp_btn} onClick={() => setPopup(<></>)}>
            <CloseIcon className={`${styles.close_icon} text-dark`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageWithVideoBanner;
