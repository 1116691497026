import { useParams, Navigate, useNavigate } from 'react-router-dom';
import Industries from './Industries';
import JobTitles from './JobTitles';
import { adminPathConstants } from '../../../Routes/helper/adminRoutes';
import { List, ListItemButton, ListItemText } from '@mui/material';

export default function Settings() {
  const { path } = useParams();
  const navigate = useNavigate();

  if (!(path === 'jobTitles' || path === 'industries')) {
    return <Navigate to="industries" replace={true} />
  }

  return (
    <div className="flex py-4 flex-column h-100">
      <div className="HeaderContainer mb-2">
        <div className="HeaderParent mt-1">
          <div className="left">
            <div className="primaryHeader my-0">Settings</div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow-lg rounded-lg w-3/4 flex h-[90%] flex-grow-[1]">
        <div className="w-1/4">
          <List sx={{marginTop: 2}}>
            <ListItemButton selected={path === 'industries'} onClick={ () => {
                  navigate(`/${adminPathConstants.SETTINGS}/industries`)
                }}>
              <ListItemText primary="Industries" />
            </ListItemButton>
            <ListItemButton selected={path === 'jobTitles'} onClick={ () => {
                  navigate(`/${adminPathConstants.SETTINGS}/jobTitles`)
                }}>
              <ListItemText primary="Job Titles" />
            </ListItemButton>
          </List>
        </div>

        <div className="h-100 w-3/4 p-4 pb-0 overflow-scroll">
          {path === 'jobTitles' ? <JobTitles /> : <></>}
          {path === 'industries' ? <Industries /> : <></>}
        </div>
      </div>
    </div>
  );
}
