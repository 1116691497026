import "./about.scss";
import Footer from "../LandingPage/Footer/Footer";
import Header from "../LandingPage/header/Header";

const AboutUs = () => {
  return (
    <>
      <Header />
      <div className="aboutus_wrapper">
        <div className="aboutus_first">
          <div className="container">
            <h1 className="aboutus_head">The why behind LeadHawk</h1>
            <p className="aboutus_desc">A letter from our founders</p>
          </div>
        </div>
        <div className="aboutus_second">
          <div className="container">
            <div className="aboutus_second_card">
              <div className="aboutus_info">
                <h6 className="info_head">
                  Hey there, this is Jake and Jack Peterson, Founders of
                  LeadHawk.
                </h6>
                <p className="info_desc_1">
                  Our journey began with a frustration familiar to many sales
                  professionals: relying on enterprise prospecting tools and
                  manually refreshing public sites to source leads. These tools
                  were built for companies, not individual contributors like us.
                  Spending hours scrolling through pages and refreshing sites
                  daily led us to a realization – there had to be a better way.
                </p>
                <p className="info_desc_2">
                  We searched for a solution but found that existing prospecting
                  tools lacked the competitive edge we needed, especially when
                  it came to trigger events.
                </p>
                <h6 className="info_head_2">That's why we started LeadHawk.</h6>
                <p className="info_desc_3">
                  LeadHawk is a straightforward tool designed to help tech sales
                  professionals source trigger events first. We cut out the
                  noise and included only the most valuable trigger events and
                  filters, the ones that truly matter.
                </p>
                <p className="info_desc_4">
                  This is the tool we wished existed when we were in your shoes,
                  looking for a prospecting tool that prioritizes the individual
                  contributor. Whether you're looking to gain an edge amongst
                  other reps, or you're fed up with mainstream prospecting
                  tools, we hope you'll give LeadHawk a try. We're confident
                  that you'll love it.
                </p>
                <p className="info_desc_5">
                  Give it a shot, and email us at{" "}
                  <strong>jack@leadhawk.io</strong> &{" "}
                  <strong>jake@leadhawk.io</strong> to let us know what you
                  think.
                </p>
                <h6 className="info_head_3">Jake and Jack Peterson</h6>
                <p className="info_desc_6">Founders, LeadHawk</p>
              </div>
            </div>
          </div>
        </div>
        <div className="aboutus_third">
          <div className="container">
            <div className="newhires_btn">Values</div>
            <h1 className="text-center text-capitalize mt-4">
              Our Core Values
            </h1>
            <div className="aboutus_card_list">
              <div className="aboutus_card_item">
                <img
                  src="/assets/images/card-image-1.png"
                  alt=""
                  className="card_image"
                />
                <img
                  src="/assets/images/arrow-top-right.png"
                  alt=""
                  className="arrow_icon"
                />
                <h6 className="card_head">Rep Focused</h6>
                <p className="card_desc">
                  We got sick of seeing all the mainstream sales tools being
                  built for companies, not reps.
                </p>
              </div>
              <div className="aboutus_card_item">
                <img
                  src="/assets/images/card-image-2.png"
                  alt=""
                  className="card_image"
                />
                <img
                  src="/assets/images/arrow-top-right.png"
                  alt=""
                  className="arrow_icon"
                />
                <h6 className="card_head">Empower Potential</h6>
                <p className="card_desc">
                  We encourage ambition and do our best to empower those with
                  it.
                </p>
              </div>
              <div className="aboutus_card_item">
                <img
                  src="/assets/images/card-image-3.png"
                  alt=""
                  className="card_image"
                />
                <img
                  src="/assets/images/arrow-top-right.png"
                  alt=""
                  className="arrow_icon"
                />
                <h6 className="card_head">Work Hard, Play Harder</h6>
                <p className="card_desc">
                  Sales is stressful. We want to help reps crush their quota,
                  but more importantly, enjoy their life outside of work.
                </p>
              </div>
              <div className="aboutus_card_item">
                <img
                  src="/assets/images/card-image-4.png"
                  alt=""
                  className="card_image"
                />
                <img
                  src="/assets/images/arrow-top-right.png"
                  alt=""
                  className="arrow_icon"
                />
                <h6 className="card_head">Value Driven</h6>
                <p className="card_desc">
                  No pointless fluff, we only work on stuff that adds
                  significant value to reps.
                </p>
              </div>
              <div className="aboutus_card_item">
                <img
                  src="/assets/images/card-image-5.png"
                  alt=""
                  className="card_image"
                />
                <img
                  src="/assets/images/arrow-top-right.png"
                  alt=""
                  className="arrow_icon"
                />
                <h6 className="card_head">Continuous Improvement</h6>
                <p className="card_desc">
                  By constantly speaking with successful reps, we aim to stay up
                  to date with leading prospecting strategies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
