export enum USER_ROLE {
  USER = "USER",
  DATA_ENTRY_OPERATOR = "DATA_ENTRY_OPERATOR",
  ADMIN = "ADMIN",
  FREE_USER = "FREE_USER",
}

export enum RESPONSE_STATUS {
  IDLE = "idle",
  LOADING = "loading",
  FAILED = "failed",
  SUCCEEDED = "succeeded",
}

export enum SUBSCRIPTION {
  TIER_I = "Tier1",
  PRO = "Pro",
  ENTERPRISE = "enterprise",
}

export enum SEARCHPARAMS_KEYS {
  SELECTED_PLAN = "selectedPlan",
}

export enum TOKEN {
  JWT_TOKEN = "jwtToken",
  REFRESH_TOKEN = "refreshToken",
}
