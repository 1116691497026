import { useState, useEffect, Fragment } from "react";
import "./SaveFilterScreen.scss";
import {
  clearFilters,
  resetErrors,
  resetStatus,
  saveSavedSearches,
  setEditAndSave,
  setFilterDrawerStatus,
  setFilterName,
} from "../../../../store/slice/filterSlice";
import { useLocation } from "react-router-dom";
import { removeNullValues } from "../../../../services/filterService";
import useNotification from "../../../../hooks/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SaveFiltersData } from "../../../../interfaces/interfaces";
import { saveFiltersValidationSchema } from "../../../../validators/saveFilters-validation-schema";
import FormInput from "../../../FormComponents/FormInput";
import Close from "@mui/icons-material/Close";
import ButtonCustom from "../../../../features/ButtonCustom/ButtonCustom";
import CircularLoader from "../../../Loaders/CircularLoader";
import { RESPONSE_STATUS } from "../../../../enums/enums";

function SaveFilterScreen({
  selectedFilters,
  filterName,
  onClose,
}: {
  selectedFilters: Record<string, string[]>;
  filterName?: string;
  onClose: () => void;
}) {
  const [selectFiltersWithoutNull, setSelectFiltersWithoutNull] = useState<
    Record<string, string[]>
  >({});
  const [loading, setLoading] = useState(false);
  const [dispatchStatus, setDispatchStatus] = useState(false);
  // const [editFilterFlag, setEditFilter] = useState(!(filterName?.length > 0));
  const [filterUpdateStatus, setFilterUpdateStatus] = useState(
    filterName ? "update" : "add"
  );

  const form = useForm<SaveFiltersData>({
    resolver: yupResolver(saveFiltersValidationSchema),
  });
  const dispatch = useAppDispatch();
  const currentFilterId = useAppSelector(
    (state) => state.filters.viewFilter?.currentFilterId
  );
  const status = useAppSelector((state) => state.filters.status);
  const error = useAppSelector((state) => state.filters.error);

  const { showNotification } = useNotification();
  const userId = useAppSelector((state) => state.signin.user?.id);

  useEffect(() => {
    const filters = removeNullValues(selectedFilters);

    setSelectFiltersWithoutNull(filters);

    if (filterUpdateStatus !== "add") {
      // setSaveFilterButtonFlag(true);
    }
  }, [filterUpdateStatus, selectedFilters]);

  useEffect(() => {
    if (filterName) {
      form.reset({ filterName });
    }
  }, [filterName, form]);

  useEffect(() => {
    // clear Filters if no value selected

    if (
      Object.values(selectedFilters).every(
        (value) =>
          value?.length === 0 || (Array.isArray(value) && value.length === 0)
      )
    ) {
      setSelectFiltersWithoutNull({});
      setFilterUpdateStatus("add");
    }
  }, [selectedFilters, filterUpdateStatus]);

  const location = useLocation();
  const screenName = location.pathname.split("/")[1];

  const handleSaveClick = (data: SaveFiltersData) => {
    let updatedFilters;

    updatedFilters = { ...selectedFilters };
    dispatch(setFilterName(data.filterName));
    // filterData, screenName, userId, token
    if (userId) {
      setLoading(true);
      dispatch(
        saveSavedSearches({
          ...data,
          filters: updatedFilters,
          screen: screenName,
          status: filterUpdateStatus,
          id: currentFilterId,
          userId,
        })
      ).then(() => {
        setLoading(false);
      });
    }
    dispatch(setEditAndSave(true));
    setDispatchStatus(true);
  };

  useEffect(() => {
    if (dispatchStatus && status === RESPONSE_STATUS.SUCCEEDED) {
      onClose();
      showNotification("success", "Filters Saved Successfully");
      dispatch(resetStatus());
      dispatch(setFilterDrawerStatus(false));
      dispatch(clearFilters());
    }
    if (dispatchStatus && error && status === RESPONSE_STATUS.FAILED) {
      showNotification("error", error);
      dispatch(resetErrors());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, error, dispatchStatus, dispatch]);

  return (
    <div className="modal-overlay">
      <div className="modal-content-box">
        <button className="absolute right-3 top-3" onClick={onClose}>
          <Close />
        </button>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSaveClick)}>
            <FormInput
              placeholder="Filter Name"
              label="Filter Name"
              name="filterName"
              disabled={!!filterName}
              className=" disabled:cursor-not-allowed"
            />
            <div className="form_group flex flex-col mt-3">
              <label htmlFor="filters">Selected Filters</label>
              <div className="max-h-[10rem] overflow-auto">
                {Object.entries(selectFiltersWithoutNull).map(
                  ([key, value]) => {
                    return (
                      <Fragment key={key}>
                        <p className="mb-0 mt-1 capitalize">
                          <strong>
                            {key === "companyHQ"
                              ? "Company Headquarters"
                              : key.split(/(?=[A-Z])/).join(" ")}
                          </strong>{" "}
                          : {value.join(", ")}
                        </p>
                      </Fragment>
                    );
                  }
                )}
              </div>
            </div>
            <div className="mt-3 flex justify-center">
              <ButtonCustom
                endIcon={loading && <CircularLoader size={20} />}
                type="submit"
                disabled={loading}
              >
                Submit
              </ButtonCustom>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

export default SaveFilterScreen;
