import { Link } from "react-router-dom";

export default function LinkCell({ url }: { url: string }) {
  return (
    <Link
      target="_blank"
      className="text-[#45A29E] no-underline truncate"
      to={url}
    >
      {url}
    </Link>
  );
}
