import { useContext, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { NotificationContext } from "../../contexts/APIContext/Notification/NotificationContext";
import PageLoader from "../Loaders/PageLoader";
import { useAppDispatch, useAppSelector } from "../../store";
import { RESPONSE_STATUS, SEARCHPARAMS_KEYS } from "../../enums/enums";
import { resetErrors, verifyUser } from "../../store/slice/signinSlice";

const VerifyAccount = () => {
  const { token } = useParams();
  const { showNotification } = useContext(NotificationContext);

  const auth = useAppSelector((state) => state.signin);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (auth.error && typeof auth.error === "string") {
      showNotification("error", auth.error);
      dispatch(resetErrors());
      navigate("/auth");
    }
    if (auth.status === RESPONSE_STATUS.SUCCEEDED) {
      showNotification("success", auth.verificationMessage.message);
      navigate("/auth");
    }
  }, [auth, navigate, showNotification, dispatch]);

  useEffect(() => {
    if (searchParams.get(SEARCHPARAMS_KEYS.SELECTED_PLAN)) {
      localStorage.setItem(SEARCHPARAMS_KEYS.SELECTED_PLAN, "pro");
      setSearchParams();
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (token) {
      dispatch(verifyUser(token));
    }
  }, [token, dispatch]);

  return (
    <div className="w-screen h-screen">
      <PageLoader message="Please wait for a while. Your account is verifying." />
    </div>
  );
};

export default VerifyAccount;
