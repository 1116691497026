import { useCallback, useEffect } from "react";
import {
  loginUser,
  reactivateUser,
  resendVerifyLink,
  resetErrors,
} from "../../store/slice/signinSlice";
import { Link } from "react-router-dom";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon.js";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { signInValidationSchema } from "../../validators/signin-validation-schema.js";
import FormInput from "../FormComponents/FormInput.js";
import { useAppDispatch, useAppSelector } from "../../store/index.js";
import { RESPONSE_STATUS } from "../../enums/enums.js";
import ButtonCustom from "../../features/ButtonCustom/ButtonCustom.js";
import useNotification from "../../hooks/useNotification.js";
import CircularLoader from "../Loaders/CircularLoader.js";
import FormPassword from "../FormComponents/FormPassword.js";

export type SignInFormValues = {
  username: string;
  password: string;
};

const inActiveError = "User not active";
const unVerifiedError = "User not verified";

const SignIn = () => {
  const authStatus = useAppSelector((state) => state.signin.status);
  const error = useAppSelector((state) => state.signin.error);
  const user = useAppSelector((state) => state.signin.user);

  const verificationMessage = useAppSelector(
    (state) => state.signin.verificationMessage
  );

  const dispatch = useAppDispatch();

  const { showNotification } = useNotification();

  const form = useForm<SignInFormValues>({
    resolver: yupResolver(signInValidationSchema),
  });

  const email = useWatch({ control: form.control, name: "username" });

  const handleVerifyLink = useCallback(() => {
    dispatch(resendVerifyLink({ email }));
  }, [email, dispatch]);

  useEffect(() => {
    if (
      authStatus === RESPONSE_STATUS.SUCCEEDED &&
      verificationMessage.message
    ) {
      showNotification("success", verificationMessage.message);
      dispatch(resetErrors());
      return;
    }
    if (
      authStatus === RESPONSE_STATUS.FAILED &&
      error &&
      typeof error === "string"
    ) {
      if (unVerifiedError.includes(error)) {
        return;
      }
      showNotification("error", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus, dispatch, error, verificationMessage, user]);

  const handleFormSubmit: SubmitHandler<SignInFormValues> = (formValues) => {
    dispatch(loginUser(formValues));
  };
  const handleReactivate: SubmitHandler<SignInFormValues> = (formValues) => {
    dispatch(reactivateUser({ ...formValues, email: formValues.username }));
  };

  useEffect(() => {
    if (email) {
      form.setValue("username", email.toLowerCase());
    }
  }, [email, form]);

  return (
    <FormProvider {...form}>
      <h2>Sign In</h2>
      <p className="content">Log into your account</p>
      <form
        onSubmit={form.handleSubmit(
          error === inActiveError ? handleReactivate : handleFormSubmit
        )}
        onChange={() => dispatch(resetErrors())}
      >
        <FormInput
          name="username"
          placeholder="Enter Email"
          label="Email"
          autoComplete="username"
          errorMessage={typeof error !== "string" ? error["username"] : ""}
        />
        <FormPassword
          name="password"
          placeholder="Enter Password"
          label="Password"
          autoComplete="current-password"
          errorMessage={typeof error !== "string" ? error["password"] : ""}
        />

        <div className="forgot_password">
          <Link to="/reset">Forgot Password?</Link>
        </div>

        <div className="bottom_buttons">
          <ButtonCustom
            className="button_submit"
            type="submit"
            endIcon={
              !error && authStatus === RESPONSE_STATUS.LOADING ? (
                <CircularLoader size={20} />
              ) : (
                <ArrowRightIcon />
              )
            }
            disabled={authStatus === RESPONSE_STATUS.LOADING}
          >
            {error === inActiveError ? "Reactivate" : "Sign in"}
          </ButtonCustom>
        </div>
        {error === unVerifiedError &&
          authStatus !== RESPONSE_STATUS.SUCCEEDED && (
            <div className=" my-3 text-center error-msg">
              Your account is not activated.{" "}
              <button
                type="button"
                className="m-auto underline resend-link "
                onClick={handleVerifyLink}
                disabled={authStatus === RESPONSE_STATUS.LOADING}
              >
                Resend verification link.
                {authStatus === RESPONSE_STATUS.LOADING && (
                  <span className="ml-2">
                    <CircularLoader size={15} />
                  </span>
                )}
              </button>
            </div>
          )}
      </form>
    </FormProvider>
  );
};

export default SignIn;
