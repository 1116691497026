import {
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import {
  deleteAdminJobs,
  resetErrors,
} from "../../../store/slice/adminSlice/adminJobSlice";
import { jobsDataInterface } from "../../../interfaces/interfaces";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  adminPath,
  adminPathConstants,
} from "../../../Routes/helper/adminRoutes";
import DeleteConfirmationModal from "../../DeleteConfirmationModal/DeleteConfirmationModal";
import { useContext, useEffect, useState } from "react";
import { RESPONSE_STATUS } from "../../../enums/enums";
import useNotification from "../../../hooks/useNotification";
import LinkCell from "../../LinkCell/LinkCell";
import LoadingContext from "../../../contexts/APIContext/Loading/LoadingContext";
import AdminTable from "./AdminTable";

function JobTable({
  rowsData,
  paginationModel,
  setPaginationModel,
  onFilterChange,
}: {
  rowsData: jobsDataInterface[];
  paginationModel: {
    page: number;
    pageSize: number;
  };
  onFilterChange: (filter: GridFilterModel) => void;
  setPaginationModel: (pageData: { page: number; pageSize: number }) => void;
}) {
  const adminJobs = useAppSelector((state) => state.adminJob);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showNotification } = useNotification();
  const { setIsLoading, isLoading } = useContext(LoadingContext);

  const [showModal, setShowModal] = useState(true);
  const [deleteId, setDeleteId] = useState("");

  const EditCell = ({ id }: { id: string }) => {
    const handleDeleteClick = () => {
      navigate(
        `/${adminPath}/${adminPathConstants.JOBS}/${adminPathConstants.EDIT_JOBS}/${id}`
      );
    };

    return (
      <div onClick={handleDeleteClick} style={{ cursor: "pointer" }}>
        <EditIcon style={{ color: "#45A29E" }} />
      </div>
    );
  };

  const DeleteCell = ({ id }: { id: string }) => {
    const handleEditClick = () => {
      setShowModal(true);
      setDeleteId(id);
    };

    return (
      <div onClick={handleEditClick} style={{ cursor: "pointer" }}>
        <DeleteIcon style={{ color: "#45A29E" }} />
      </div>
    );
  };

  const columns: GridColDef<jobsDataInterface>[] = [
    { field: "jobTitle", headerName: "Job Title", width: 130 },
    {
      field: "jobLink",
      headerName: "Job Link",
      width: 130,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <LinkCell url={params.value || ""} />
      ),
    },
    {
      field: "companyName",
      headerName: "Company Name",
      width: 200,
      sortable: false,
    },
    {
      field: "companyHQ",
      headerName: "Company HQ",
      width: 180,
      valueGetter: (_, row) => {
        const hq = row.companyCity ? [row.companyCity] : [];
        if (row.companyHQ) {
          hq.push(row.companyHQ);
        }

        return hq.length > 0 ? hq.join(', ') : 'N/A';
      }
    },
    { field: "industry", headerName: "Industry", width: 180 },
    {
      field: "companyEmployeeCount",
      headerName: "Employee Count",
      width: 150,
      type: "number",
    },
    {
      field: "companyLinkedIn",
      headerName: "Company LinkedIn Profile",
      width: 250,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <LinkCell url={params.value || ""} />
      ),
    },
    {
      field: "companyWebsite",
      headerName: "Company Website",
      width: 250,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <LinkCell url={params.value || ""} />
      ),
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 180,
      disableColumnMenu: true,
      filterable: true,
      sortable: false,
      renderCell: (params) => {
        return new Intl.DateTimeFormat('en-US', {
          dateStyle: 'short',
          timeStyle: 'short',
        }).format(new Date(params.value));
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <EditCell id={params.row.id} />
      ), // Pass the id to EditCell
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 100,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <DeleteCell id={params.row.id} />
      ),
    },
  ];

  useEffect(() => {
    setIsLoading(adminJobs.status === RESPONSE_STATUS.LOADING);

    if (
      deleteId &&
      isLoading &&
      adminJobs.status === RESPONSE_STATUS.SUCCEEDED
    ) {
      showNotification("success", "Job deleted successfully");
      setShowModal(false);
      setIsLoading(false);
      setDeleteId("");
    }

    if (adminJobs.error && typeof adminJobs.error === "string") {
      showNotification("error", adminJobs.error);
      dispatch(resetErrors());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminJobs, deleteId, isLoading, setIsLoading]);

  return (
    <div className="tableParent">
      <div className="min-h-[300px] h-[calc(100vh-320px)] py-[20px]">
        <AdminTable
          rowsData={rowsData}
          columns={columns}
          isLoading={isLoading}
          isRowSelectable={() => false}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          onFilterModelChange={onFilterChange}
          maxRecords={adminJobs.maxRecords}
        />
      </div>
      {showModal && deleteId && (
        <DeleteConfirmationModal
          deleteFn={() => dispatch(deleteAdminJobs({ id: deleteId }))}
          onClose={() => setShowModal(false)}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

export default JobTable;
