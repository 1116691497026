import { useParams } from "react-router-dom";
import HeaderComponent from "../CommonAppComponents/HeaderComponent/HeaderComponent";
import NewsForm from "./NewsForm";

export default function AddEditNews() {
  const { id } = useParams();
  const primaryText = id ? "Edit News" : "Add News";
  const secondaryText = id ? "Edit News" : "Add New News";
  return (
    <>
      <HeaderComponent
        primaryText={primaryText}
        secondaryText={secondaryText}
      />
      <NewsForm />
    </>
  );
}
