import { useContext, useEffect, useState } from "react";
import {
  fetchProfile,
  updateProfile,
  deleteProfile,
  resetErrors,
} from "../../store/slice/profileSlice.js";
import "./ProfileStyles.scss";
import { useNavigate, useSearchParams } from "react-router-dom";

import ButtonCustom from "../../features/ButtonCustom/ButtonCustom.js";
import { useAppDispatch, useAppSelector } from "../../store/index.js";
import { RESPONSE_STATUS, SUBSCRIPTION, USER_ROLE } from "../../enums/enums.js";
import useNotification from "../../hooks/useNotification.js";
import { logoutUser } from "../../store/slice/signinSlice.js";
import DeleteConfirmationModal from "../DeleteConfirmationModal/DeleteConfirmationModal.js";
import PaymentSuccessModal from "../PaymentSuccessModal/PaymentSuccessModal.js";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { profileFormValues } from "../../interfaces/interfaces.js";
import { profileValidationSchema } from "../../validators/profile-validation-schema.js";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "../FormComponents/FormInput.js";
import CircularLoader from "../Loaders/CircularLoader.js";
import CenterLoader from "../Loaders/CenterLoader.js";
import PageLayout from "../Layouts/PageLayout.js";
import LoadingContext from "../../contexts/APIContext/Loading/LoadingContext.js";

const Profile = () => {
  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state.profile?.data);
  const status = useAppSelector((state) =>
    state.profile ? state.profile.status : RESPONSE_STATUS.IDLE
  );
  const error = useAppSelector((state) => state.profile.error);
  const userRole = useAppSelector((state) => state.signin.role);

  const { showNotification } = useNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setIsLoading } = useContext(LoadingContext);

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);

  const form = useForm<profileFormValues>({
    resolver: yupResolver(profileValidationSchema),
  });

  useEffect(() => {
    if (searchParams.get("paymentSuccess")) {
      setShowPaymentSuccess(true);
      setSearchParams("");
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    dispatch(fetchProfile()).then(() => setIsLoading(false));
  }, [dispatch, setIsLoading]);

  useEffect(() => {
    if (updateSuccess && status === RESPONSE_STATUS.SUCCEEDED) {
      showNotification("success", `Profile is updated successfully`);
      dispatch(fetchProfile());
      setUpdateSuccess(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updateSuccess, status]);

  useEffect(() => {
    if (profile) {
      form.reset(profile);
    }
  }, [profile, form]);

  const handleUpdate: SubmitHandler<profileFormValues> = (data) => {
    try {
      setLoading(true);
      setUpdateSuccess(true);
      dispatch(updateProfile(data)).then(() => {
        setLoading(false);
      });
    } catch (error) {
      console.error("Failed to update profile:", error);
    }
  };

  const handleDelete = () => {
    setLoading(true);
    dispatch(deleteProfile()).then(() => {
      setLoading(false);
      dispatch(logoutUser());
      navigate("/auth");
    });
  };

  const navigateToPricingTable = () => {
    navigate("/pricingTable");
  };

  useEffect(() => {
    if (
      status === RESPONSE_STATUS.FAILED &&
      typeof error === "string" &&
      error
    ) {
      showNotification("error", error);
      dispatch(resetErrors());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, error, dispatch]);

  const subscription = useWatch({
    control: form.control,
    name: "subscription",
  });

  if (status === RESPONSE_STATUS.LOADING && !isLoading) {
    return (
      <div className="w-full h-5/6 flex justify-center items-center">
        <CenterLoader />
      </div>
    );
  }

  return (
    <PageLayout>
      <div className="w-full h-5/6 flex justify-center items-center">
        <FormProvider {...form}>
          <form
            className="profile-outer-div"
            onSubmit={form.handleSubmit(handleUpdate)}
            onChange={() => dispatch(resetErrors())}
          >
            <h1 className="primaryHeader">Profile Information</h1>
            <FormInput
              label="First Name"
              placeholder="First Name"
              name="firstName"
              errorMessage={typeof error !== "string" ? error["firstName"] : ""}
            />
            <FormInput
              label="Last Name"
              placeholder="Last Name"
              name="lastName"
              errorMessage={typeof error !== "string" ? error["lastName"] : ""}
            />
            <FormInput
              label="Email"
              placeholder="Email"
              name="email"
              disabled
              className="cursor-not-allowed"
            />
            <FormInput
              label="Phone Number"
              placeholder="Phone Number"
              name="phoneNumber"
              errorMessage={
                typeof error !== "string" ? error["phoneNumber"] : ""
              }
            />

            {userRole === USER_ROLE.USER && (
              <div className="form_group">
                <label>Plan</label>
                <input
                  placeholder="Plan"
                  name="subscription"
                  value={
                    subscription === SUBSCRIPTION.TIER_I
                      ? SUBSCRIPTION.PRO
                      : subscription
                  }
                  disabled
                  className="cursor-not-allowed"
                />
              </div>
            )}
            <div className="profile-buttons-div">
              <ButtonCustom
                type="submit"
                endIcon={
                  status === RESPONSE_STATUS.LOADING &&
                  !showModal && <CircularLoader size={20} />
                }
              >
                Save Changes
              </ButtonCustom>
              <br />
              {userRole !== USER_ROLE.ADMIN && (
                <ButtonCustom
                  type="button"
                  variant="contained"
                  onClick={() => setShowModal(true)}
                >
                  Deactivate User
                </ButtonCustom>
              )}{" "}
              <br />
              {(userRole === USER_ROLE.USER ||
                userRole === USER_ROLE.FREE_USER) && (
                <ButtonCustom
                  variant="contained"
                  onClick={navigateToPricingTable}
                >
                  Upgrade Plan
                </ButtonCustom>
              )}
            </div>
          </form>
        </FormProvider>
        {showModal && (
          <DeleteConfirmationModal
            deleteFn={handleDelete}
            onClose={() => setShowModal(false)}
            isLoading={isLoading}
          />
        )}
        {showPaymentSuccess && (
          <PaymentSuccessModal
            onClose={() => {
              setShowPaymentSuccess(false);
            }}
          />
        )}
      </div>
    </PageLayout>
  );
};

export default Profile;
