import { fetchDataEntriesForUserId } from "../../../store/slice/adminSlice/createProfileSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import { useState, useEffect, useContext } from "react";
import CustomTabPanel from "./TabComponent/CustomTabPanel";
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./DataEntryActivityLog.scss";
import { useAppDispatch } from "../../../store";
import LoadingContext from "../../../contexts/APIContext/Loading/LoadingContext";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function DataEntryActivityLog() {
  const dispatch = useAppDispatch();

  const { id } = useParams();
  const navigate = useNavigate();

  const { setIsLoading, isLoading } = useContext(LoadingContext);

  useEffect(() => {
    //fetch Data for User for all the screens
    if (id) {
      setIsLoading(true);
      dispatch(fetchDataEntriesForUserId({ id })).then(() =>
        setIsLoading(false)
      );
    }
  }, [id, dispatch, setIsLoading]);

  const [value, setValue] = useState(0);

  const handleChange = (
    _: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  return (
    <>
      <HeaderComponent
        primaryText={"Data Activity Log"}
        secondaryText={"View activity logs of data entry operator "}
      ></HeaderComponent>
      {/* Tab */}
      <div
        style={{
          padding: "20px 0",
          height: "calc(100vh - 300px)",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <button
            onClick={() => navigate("/admin/activityLog")}
            className="backToLeads mb-4"
          >
            <ArrowBackIcon />
            <span>Back to log table </span>
          </button>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="New Hires" {...a11yProps(0)} />
            <Tab label="Funding" {...a11yProps(1)} />
            <Tab label="Job Postings" {...a11yProps(2)} />
            <Tab label="Company Press Releases" {...a11yProps(3)} />
          </Tabs>
        </Box>

        {[...Array(4).keys()].map((_, index) => {
          return (
            <CustomTabPanel
              value={value}
              index={index}
              key={index}
              isLoading={isLoading}
            ></CustomTabPanel>
          );
        })}
      </div>
    </>
  );
}

export default DataEntryActivityLog;
