import { useEffect, useRef, useState } from "react";
import Header from "../LandingPage/header/Header";
import Footer from "../LandingPage/Footer/Footer";
import "./policy.scss";
import CenterLoader from "../Loaders/CenterLoader";

export default function PolicyPageLayout({ id }: { id: string }) {
  const divRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function loadScript() {
      const script = document.createElement("script");
      script.src = "https://app.termly.io/embed-policy.min.js";
      script.async = true;
      document.body.appendChild(script);

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }

    loadScript();

    if (divRef.current) {
      divRef.current.setAttribute("name", "termly-embed");
    }
  }, []);

  return (
    <>
      <Header />
      <div className="policy">
        <div className="container">
          <div className="policy_card">
            {loading && <CenterLoader />}
            <div ref={divRef} id={id} data-id={id}></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
