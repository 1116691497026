const RegisterLeft = () => {
  return (
    <div className="col-lg-6 left_image">
      <img
        src="/assets/images/icon_lap.svg"
        alt=""
        className="left_icon img-fluid"
      />
    </div>
  );
};

export default RegisterLeft;
