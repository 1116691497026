import ButtonCustom from "../../features/ButtonCustom/ButtonCustom";
import "./modal.scss";
import CircularLoader from "../Loaders/CircularLoader";
import Close from "@mui/icons-material/Close";
import { Modal } from '@mui/material';
export default function DeleteConfirmationModal({
  onClose,
  deleteFn,
  isLoading,
}: {
  onClose: () => void;
  deleteFn: () => void;
  isLoading: boolean;
}) {
  return (
    <Modal open={true} onClose={onClose}>
      <div className="modal-overlay">
        <div className="modal-content-box">
          <button className="absolute right-3 top-3" onClick={onClose}>
            <Close />
          </button>
          <div className="my-2 flex justify-center items-center flex-col gap-2">
            <h3 className=" text-lg">Are you sure ?</h3>
            <div className="flex gap-2">
              <ButtonCustom
                variant="outlined"
                className="delete_button"
                onClick={deleteFn}
                endIcon={isLoading && <CircularLoader size={20} />}
              >
                Confirm
              </ButtonCustom>
              <ButtonCustom
                variant="outlined"
                className="cancel_button"
                onClick={onClose}
              >
                Cancel
              </ButtonCustom>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
