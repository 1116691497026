import { Pagination } from "@mui/material";
import {
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";

function CustomPagination({
  page,
  onPageChange,
  className,
}: {
  page: number;
  onPageChange: (event: any, page: number) => void;
  className?: string;
}) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="secondary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

export default CustomPagination;
