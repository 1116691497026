export const hiresFields = [
  {
    label: "First Name",
    name: "firstName",
  },
  {
    label: "Last Name",
    name: "lastName",
  },
  {
    label: "Position",
    name: "position",
  },
  {
    label: "Company Name",
    name: "companyName",
  },
  {
    label: "City",
    name: "companyCity",
  },
  {
    label: "State",
    name: "companyHQ",
  },
  {
    label: "Industry",
    name: "industry",
  },
  {
    label: "Employee Count",
    name: "companyEmployeeCount",
    type: "number",
  },
  {
    label: "Company LinkedIn",
    name: "companyLinkedIn",
  },
  {
    label: "Company Website",
    name: "companyWebsite",
  },
];

export const pageName = "HIRES";
