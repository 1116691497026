import { filterPanel } from "../../../../constants/filterPanelConstant";
import { userFlowScreens } from "../../../../constants/userFlowConstants";
import CustomGridPagination from "../../../../features/CustomPagination/CustomGridPagination";
import { useAppSelector } from "../../../../store";
import { generateColumnsBasedOnIndex } from "../../../../utils/adminFlow";
import { DataGrid } from "@mui/x-data-grid";

function CustomTabPanel(props: {
  value: number;
  index: number;
  isLoading: boolean;
}) {
  const { value, index, isLoading, ...other } = props;

  const columns = generateColumnsBasedOnIndex(index);

  const dataEntries = useAppSelector((state) => state.adminProfile.dataEntries);
  const screen: string = userFlowScreens[index];

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="h-full"
      {...other}
    >
      {value === index && (
        <div className="py-[20px] min-h-[300px] h-[calc(100vh-300px)]">
          <DataGrid
            rows={dataEntries[screen]}
            columns={columns}
            loading={isLoading}
            disableVirtualization={true}
            filterDebounceMs={400}
            slots={{
              loadingOverlay: () => <></>,
              pagination: () => <CustomGridPagination />,
            }}
            slotProps={{
              filterPanel,
            }}
            isRowSelectable={() => false}
            disableRowSelectionOnClick
          />
        </div>
      )}
    </div>
  );
}

export default CustomTabPanel;
