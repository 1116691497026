import "./HeaderComponent.scss";
import ButtonCustom from "../../../features/ButtonCustom/ButtonCustom";
import Drawer from "@mui/material/Drawer";
import {
  addToMyLeads,
  deleteLeadsfromRow,
  fetchLeadsData,
  setDeletedRowsEmpty,
  setLeadsFlag,
  resetErrors,
} from "../../../store/slice/userFlowSlice";
import IconButton from "@mui/material/IconButton";
import { useState, useEffect, useCallback, useContext } from "react";
import { generateFilterTitles } from "../../../utils/userFlow";
import { useLocation, useSearchParams } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterComponent from "../../UserFlow/FilterComponent/FilterComponent";
import {
  clearFilters,
  setFilterDrawerStatus,
  setViewFlag,
} from "../../../store/slice/filterSlice";
import { useAppDispatch, useAppSelector } from "../../../store";
import { RESPONSE_STATUS, USER_ROLE } from "../../../enums/enums";
import useNotification from "../../../hooks/useNotification";
import CircularLoader from "../../Loaders/CircularLoader";
import LoadingContext from "../../../contexts/APIContext/Loading/LoadingContext";
import { queryTabsRecord } from "../../../constants/userFlowConstants";

const HeaderComponent = ({
  primaryText,
  secondaryText,
  selectedScreen = "default",
}: {
  primaryText: string;
  secondaryText: string;
  selectedScreen?: string;
}) => {
  const leadsFlag = useAppSelector((state) => state.userflow.leadsFlag);
  const updateStatus = useAppSelector((state) => state.userflow.updateStatus);
  const deleteStatus = useAppSelector((state) => state.userflow.deleteStatus);
  const error = useAppSelector((state) => state.userflow.error);

  const selectedRows: Record<string, string> = useAppSelector(
    (state) => state.userflow.selectedRows
  );
  const [addLeadsButton, setAddLeadsButton] = useState(true);
  const [filterFlag, setFilterFlag] = useState(false);
  const [leadLoading, setLeadLoading] = useState(false);

  const selectedFilters: Array<string[]> = useAppSelector(
    (state) => state.filters.selectedFilters
  );
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { showNotification } = useNotification();

  const fullPath = location.pathname.split("/")[1];
  const filterTitles = generateFilterTitles(fullPath);
  const deletedRows = useAppSelector((state) => state.userflow.deletedRows);
  const viewFilters = useAppSelector((state) => state.filters.viewFilter);
  const userRole = useAppSelector((state) => state.signin.role);

  const { setIsLoading } = useContext(LoadingContext);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!selectedRows[fullPath]?.length) {
      setAddLeadsButton(deletedRows?.[fullPath]?.length > 0);
    } else {
      setAddLeadsButton(
        selectedRows?.[fullPath]?.length > 0 ||
          deletedRows?.[fullPath]?.length > 0
      );
    }
  }, [selectedRows, deletedRows, fullPath]);

  useEffect(() => {
    if (selectedFilters) {
      setFilterFlag(
        Object.values(selectedFilters)?.some(
          (arr: Array<string>) => arr.length > 0
        )
      );
    }
  }, [selectedFilters]);

  const handleClearFilters = useCallback(() => {
    dispatch(clearFilters());
  }, [dispatch]);

  useEffect(() => {
    const query = searchParams.get("tab");
    if (query) {
      setIsLoading(true);

      //fetch Data for User for all the screens
      dispatch(fetchLeadsData(query)).then(() => {
        setIsLoading(false);
      });
    }
    if (Object.keys(queryTabsRecord).some((tab) => fullPath === tab)) {
      setIsLoading(true);

      //fetch Data for User for all the screens
      dispatch(fetchLeadsData(queryTabsRecord[fullPath])).then(() => {
        setIsLoading(false);
      });
    }
  }, [dispatch, setIsLoading, searchParams, fullPath]);

  useEffect(() => {
    if (!viewFilters.viewFlag && userRole === USER_ROLE.USER) {
      handleClearFilters();
    }
    if (viewFilters.viewFlag && viewFilters.screenName !== fullPath) {
      dispatch(setViewFlag(false));
    }
  }, [viewFilters, userRole, fullPath, handleClearFilters, dispatch]);

  const openFilterDrawer = useAppSelector(
    (state) => state.filters.filterDrawer
  );

  const handleDrawerOpen = () => {
    dispatch(setFilterDrawerStatus(true));
  };

  const handleSavedSearches = () => {
    dispatch(setLeadsFlag(!leadsFlag));
  };

  const leadRowsHandler = () => {
    if (
      (updateStatus === RESPONSE_STATUS.FAILED ||
        deleteStatus === RESPONSE_STATUS.FAILED) &&
      error
    ) {
      showNotification("error", error ?? "Something went wrong");
      dispatch(resetErrors());
    }

    setLeadLoading(false);

    setIsLoading(true);

    //fetch Data for User for all the screens
    dispatch(fetchLeadsData(queryTabsRecord[fullPath])).then(() => {
      setIsLoading(false);
    });
  };

  const handleAddtoLeads = () => {
    //  check if deletedRows Not theree
    if (deletedRows[fullPath]?.length > 0) {
      setLeadLoading(true);
      dispatch(
        deleteLeadsfromRow({
          deletedRowID: deletedRows[fullPath],
          screen: fullPath,
        })
      );
    }
    if (selectedRows[fullPath]?.length > 0) {
      setLeadLoading(true);
      dispatch(addToMyLeads({ data: selectedRows, screen: fullPath }));
    }
  };

  useEffect(() => {
    if (updateStatus && updateStatus !== RESPONSE_STATUS.LOADING) {
      if (updateStatus === RESPONSE_STATUS.SUCCEEDED) {
        showNotification("success", "Lead added successfully");
      }
      leadRowsHandler();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  useEffect(() => {
    if (deleteStatus && deleteStatus !== RESPONSE_STATUS.LOADING) {
      if (deleteStatus === RESPONSE_STATUS.SUCCEEDED) {
        showNotification("success", "Lead removed successfully");
      }
      dispatch(setDeletedRowsEmpty());
      leadRowsHandler();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);

  return (
    <div className="HeaderContainer">
      <div className="HeaderParent">
        <div className="left">
          <div className="primaryHeader">{primaryText}</div>
          <div className="secondaryHeader">{secondaryText}</div>
        </div>
        <div className="right">
          {selectedScreen === "myLeads" && leadsFlag && (
            <ButtonCustom variant="outlined" onClick={handleSavedSearches}>
              Saved Searches
            </ButtonCustom>
          )}
          {selectedScreen === "All" && (
            <>
              <ButtonCustom
                variant="outlined"
                onClick={handleAddtoLeads}
                disabled={!addLeadsButton}
                endIcon={leadLoading && <CircularLoader size={20} />}
              >
                Add to Leads
              </ButtonCustom>
              <IconButton onClick={handleDrawerOpen}>
                <div className="relative m-auto flex items-center">
                  <FilterAltIcon />
                  {filterFlag && <span className="filterFlag"></span>}
                </div>
              </IconButton>
            </>
          )}
        </div>
      </div>
      <Drawer
        sx={{
          width: 30,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 300,
            borderRadius: 5,
          },
        }}
        slotProps={{
          backdrop: { invisible: true },
        }}
        className="filter-drawer"
        variant="temporary"
        onClose={() => dispatch(setFilterDrawerStatus(false))}
        anchor="right"
        open={openFilterDrawer}
      >
        <FilterComponent
          filterTitles={filterTitles}
          handleClearFilters={handleClearFilters}
        />
      </Drawer>
    </div>
  );
};

export default HeaderComponent;
