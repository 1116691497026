import ButtonCustom from "../../../../features/ButtonCustom/ButtonCustom";
import "./SavedSearchesDetails.scss";
import { useNavigate } from "react-router-dom";
import {
  deleteSavedSearch,
  setStateForViewSaveSearch,
  setViewFilters,
  resetErrors,
} from "../../../../store/slice/filterSlice";
import { getMappedValue } from "../../../../services/userFlowService";
import { mapToURL } from "../../../../constants/userFlowConstants";
import { useAppDispatch, useAppSelector } from "../../../../store";
import useNotification from "../../../../hooks/useNotification";
import DeleteConfirmationModal from "../../../DeleteConfirmationModal/DeleteConfirmationModal";
import { useEffect, useState } from "react";
import { RESPONSE_STATUS } from "../../../../enums/enums";

interface FilterData {
  id: string;
  page: number;
  filterName: string;
}

const viewButtonStyle = { backgroundColor: "#E1EFEE", color: "#45A29E" };
const deleteButtonStyle = {
  backgroundColor: "#F4B0B0",
  color: "#DA1B1B",
  border: "#E16767",
};

function SavedSearchesDetails({ screenName }: { screenName: string }) {
  const savedSearch = useAppSelector((state) => state.filters);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [deleteData, setDeleteData] = useState<FilterData | null>(null);

  const userId = useAppSelector((state) => state.signin.user?.id);
  const token = useAppSelector((state) => state.signin?.token);

  const savedFilters: Array<{
    filterName: string;
    visited: boolean;
    page: number;
    id: string;
  }> = useAppSelector(
    (state) => state.filters.savedSearches?.[screenName] || []
  );

  const navigate = useNavigate();
  const { showNotification } = useNotification();

  const handleViewButton = (filterData: {
    visited: boolean;
    id: string;
    page: number;
  }) => {
    if (!filterData.visited) {
      dispatch(setStateForViewSaveSearch(filterData.id));
    }
    dispatch(
      setViewFilters({
        filterData,
        screenName: getMappedValue(filterData.page, mapToURL),
        userId,
        token,
      })
    );

    navigate(`/${screenName}?page=1&count=25`);
  };

  const handleDeleteButton = () => {
    if (deleteData) {
      dispatch(deleteSavedSearch(deleteData));
    }
  };

  useEffect(() => {
    setIsLoading(savedSearch.status === RESPONSE_STATUS.LOADING);

    if (
      deleteData &&
      isLoading &&
      savedSearch.status === RESPONSE_STATUS.SUCCEEDED
    ) {
      showNotification("success", "Filter deleted successfully");
      setShowModal(false);
      setIsLoading(false);
      setDeleteData(null);
    }
    if (savedSearch.error && typeof savedSearch.error === "string") {
      showNotification("error", savedSearch.error);
      dispatch(resetErrors());
    }
  }, [savedSearch, deleteData, isLoading, setIsLoading, showNotification, dispatch]);

  return (
    <div className="mainSavedSearchFlexBox">
      {savedFilters.length > 0 ? (
        savedFilters.map((x, i) => (
          <div key={`${i}-${x.filterName}`} className="savedFilterBox gap-3">
            <p className="filterName mb-0 truncate">{x.filterName}</p>
            <div className="editOptionBox">
              <ButtonCustom
                variant="contained"
                onClick={() => handleViewButton(x)}
                style={viewButtonStyle}
              >
                View
                {!x.visited ? (
                  <span
                    key={`${i}-${x.filterName}`}
                    className="notificationCircle"
                  ></span>
                ) : (
                  <span></span>
                )}
              </ButtonCustom>
              <ButtonCustom
                variant="contained"
                onClick={() => {
                  setShowModal(true);
                  setDeleteData(x);
                }}
                style={deleteButtonStyle}
              >
                Delete
              </ButtonCustom>
            </div>
          </div>
        ))
      ) : (
        <div className="w-full h-full justify-center flex items-center">
          No Saved Searches
        </div>
      )}
      {showModal && deleteData && (
        <DeleteConfirmationModal
          deleteFn={() => handleDeleteButton()}
          onClose={() => setShowModal(false)}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

export default SavedSearchesDetails;
