import {
  DATA_ENTRY,
  dataPathConstants,
} from "../Routes/helper/dataEntryRoutes";

const mapToTitleNames = {
  companyName: "Company Name",
  companyLinkedIn: "Company LinkedIn",
  companyHQ: "Company HQ",
  companyEmployeeCount: "Employee Count",
  fundingRaised: "Funding Raised",
  industry: "Industry",
  companyWebsite: "Company Website",
  jobTitle: "Job Title",
  jobLink: "Job Link",
  trigger: "Trigger",
  triggerArticle: "Trigger Article",
  categories: "Categories",
};

const generatePathBasedOnDataOpt = (role: string, key: string) => {
  let string = "";
  if (role === "data") {
    string = `/${key}`;
  } else if (role === "admin") {
    string = `/admin/${key}`;
  } else {
    string = `/data/${key}`;
  }

  return string;
};

const generateHeader = (key: string) => {
  let text: {
    primaryText: string;
    secondaryText: string;
    buttonName?: string;
    linkTo?: string;
  };

  switch (key) {
    case "newExecutiveHires":
      text = {
        primaryText: "New Executive Hires",
        secondaryText: "View All New Executive Hires",
        buttonName: "Add Executive Hire",
        linkTo: `${DATA_ENTRY}/${dataPathConstants.DATA_HIRES}/${dataPathConstants.ADD_HIRES}`,
      };
      break;
    case "postJobs":
      text = {
        primaryText: "Job Postings",
        secondaryText: "View All Job Postings",
        buttonName: "Add Job Post",
        linkTo: `${DATA_ENTRY}/${dataPathConstants.POST_JOBS}/${dataPathConstants.ADD_JOBS}`,
      };
      break;
    case "dataFunding":
      text = {
        primaryText: "Funding",
        secondaryText: "View All Funding",
        buttonName: "Add Funding",
        linkTo: `${DATA_ENTRY}/${dataPathConstants.DATA_FUNDING}/${dataPathConstants.ADD_FUNDS}`,
      };
      break;
    case "businessNews":
      text = {
        primaryText: "Company Press Releases",
        secondaryText: "View All Company Press Releases",
        buttonName: "Add Business News",
        linkTo: `${DATA_ENTRY}/${dataPathConstants.BUSINESS_NEWS}/${dataPathConstants.ADD_NEWS}`,
      };
      break;
    default:
      text = {
        primaryText: "New Hires",
        secondaryText: "View All New Hires",
        buttonName: " ",
      };
      break;
  }
  return text;
};

export { generatePathBasedOnDataOpt, generateHeader, mapToTitleNames };
