import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import RegisterLeft from "./RegisterLeft";
import Footer from "../LandingPage/Footer/Footer";
import Header from "../LandingPage/header/Header";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { changePasswordValidationSchema } from "../../validators/changePassword-validation-schema";
import FormPassword from "../FormComponents/FormPassword";
import ButtonCustom from "../../features/ButtonCustom/ButtonCustom";
import CircularLoader from "../Loaders/CircularLoader";
import { RESPONSE_STATUS } from "../../enums/enums";
import useNotification from "../../hooks/useNotification";
import { useAppDispatch, useAppSelector } from "../../store";
import { useEffect } from "react";
import { resetErrors, resetPassword } from "../../store/slice/signinSlice";
import { useNavigate, useParams } from "react-router-dom";

type ChangePasswordValues = {
  password: string;
};

const passwordFields = [
  {
    type: "password",
    name: "password",
    placeholder: "Enter Password",
    label: "New Password",
    autoComplete: "new-password",
  },
  {
    type: "password",
    name: "confirmPassword",
    placeholder: "Enter Password Again",
    label: "Confirm New Password",
    autoComplete: "new-password",
  },
];

const ChangePassword = () => {
  const authStatus = useAppSelector((state) => state.signin.status);
  const error = useAppSelector((state) => state.signin.error);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const { showNotification } = useNotification();

  const form = useForm<ChangePasswordValues>({
    resolver: yupResolver(changePasswordValidationSchema),
  });

  useEffect(() => {
    if (authStatus === RESPONSE_STATUS.SUCCEEDED) {
      showNotification("success", "Password changed successfully.");
      navigate("/auth");
    } else if (error && typeof error === "string") {
      showNotification("error", error);
      dispatch(resetErrors());
      navigate("/reset");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus, error]);

  const handleChangePassword: SubmitHandler<ChangePasswordValues> = (data) => {
    if (token) {
      dispatch(resetPassword({ ...data, token }));
    }
  };
  return (
    <>
      <Header />
      <div className="register_main change_password_wrapper">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <RegisterLeft />
            <div className="col-lg-6">
              <div className="form_card">
                <h2>Change Password</h2>
                <p>
                  You new password must be different from previously <br></br>
                  used Passwords
                </p>
                <FormProvider {...form}>
                  <form
                    onSubmit={form.handleSubmit(handleChangePassword)}
                    onChange={() => dispatch(resetErrors())}
                  >
                    {passwordFields.map((passwordField, index) => {
                      return (
                        <FormPassword
                          key={index}
                          {...passwordField}
                          errorMessage={
                            typeof error !== "string"
                              ? error[passwordField.name]
                              : ""
                          }
                        />
                      );
                    })}

                    <div className="bottom_buttons">
                      <ButtonCustom
                        className="button_submit"
                        type="submit"
                        endIcon={
                          authStatus === RESPONSE_STATUS.LOADING ? (
                            <CircularLoader size={20} />
                          ) : (
                            <ArrowRightIcon />
                          )
                        }
                      >
                        Update
                      </ButtonCustom>
                    </div>
                  </form>
                </FormProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ChangePassword;
