import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { ProfileData } from "../../../interfaces/interfaces";
import { filterPanel } from "../../../constants/filterPanelConstant";
import CustomGridPagination from "../../../features/CustomPagination/CustomGridPagination";

function ActivityLogTable({
  rowsData,
  isLoading,
}: {
  rowsData: ProfileData[];
  isLoading: boolean;
}) {
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: "First Name",
      width: 130,
      sortable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 130,
      sortable: false,
    },
    { field: "phoneNumber", headerName: "Phone Number", width: 200 },
    { field: "email", headerName: "E-mail", width: 180 },
    {
      field: "role",
      headerName: "Role",
      width: 250,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const handleRowClick = (params: { row: { id: string } }) => {
    navigate(`/admin/activityLog/${params.row.id}`);
  };

  return (
    <>
      <div className="tableParent">
        <div className="min-h-[300px] h-[calc(100vh-300px)] py-[20px]">
          <DataGrid
            sx={{
              ".MuiDataGrid-cell": {
                cursor: "pointer",
              },
            }}
            rows={rowsData ?? []}
            columns={columns}
            onRowClick={handleRowClick}
            loading={isLoading}
            disableVirtualization={true}
            slots={{
              loadingOverlay: () => <></>,
              pagination: () => <CustomGridPagination />,
            }}
            slotProps={{
              filterPanel,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default ActivityLogTable;
