import { useContext, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { fetchJobsCount } from "../../../store/slice/chartSlice/jobsCountSlice";
import "./chart.scss";
import { useAppDispatch, useAppSelector } from "../../../store";
import LoadingContext from "../../../contexts/APIContext/Loading/LoadingContext";

const JobsCountChart = () => {
  const dispatch = useAppDispatch();
  const entries = useAppSelector((state) => state.hiresCountChart.entries);

  const { isLoading } = useContext(LoadingContext);

  useEffect(() => {
    dispatch(fetchJobsCount()); // Your action creator
  }, [dispatch]);

  const data = entries
    ? entries.map((item) => ({
        name: item.name,
        Count: item.count,
      }))
    : [];

  return (
    <div>
      {!isLoading && (
        <>
          <h3 className="chart-heading-3">Jobs Count Chart</h3>
          {entries && (
            <BarChart
              width={500}
              height={250}
              data={data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Count" fill="#8A2BE2" />
            </BarChart>
          )}
        </>
      )}
    </div>
  );
};

export default JobsCountChart;
