import { GridColDef } from "@mui/x-data-grid";
import { rowsInterface } from "../interfaces/interfaces";
import LinkCell from "../components/LinkCell/LinkCell";
import { adminPath, adminPathConstants } from "../Routes/helper/adminRoutes";

const generatePathBasedOnAdmin = (role: string, key: string) => {
  let string = "";
  if (role === "data") {
    string = `/${key}`;
  } else if (role === "admin") {
    string = `/admin/${key}`;
  } else {
    string = `/data/${key}`;
  }

  return string;
};

const generateAdminHeader = (key: string) => {
  let text: {
    primaryText: string;
    secondaryText: string;
    buttonName?: string;
    linkTo?: string;
  };

  switch (key) {
    case "adminDashboard":
      text = {
        primaryText: "Admin Dashboard",
        secondaryText: "View Dashboard",
      };
      break;
    case "newHires":
      text = {
        primaryText: "New Executive Hires",
        secondaryText: "View All New Executive Hires",
        buttonName: "Add Executive Hire",
        linkTo: `${adminPath}/${adminPathConstants.NEW_HIRES}/${adminPathConstants.ADD_HIRES}`,
      };
      break;
    // case "activityLog":
    //   text = {
    //     primaryText: "Activity Log",
    //     secondaryText: "View All Activity Log",
    //   };
    //   break;
    case "funding":
      text = {
        primaryText: "Funding",
        secondaryText: "View All Funding",
        buttonName: "Add Funding",
        linkTo: `${adminPath}/${adminPathConstants.FUNDING}/${adminPathConstants.ADD_FUNDS}`,
      };
      break;
    case "news":
      text = {
        primaryText: "Company Press Releases",
        secondaryText: "View All Company Press Releases",
        buttonName: "Add Business News",
        linkTo: `${adminPath}/${adminPathConstants.NEWS}/${adminPathConstants.ADD_NEWS}`,
      };
      break;
    case "jobs":
      text = {
        primaryText: "Jobs",
        secondaryText: "View All Jobs",
        buttonName: "Add Jobs",
        linkTo: `${adminPath}/${adminPathConstants.JOBS}/${adminPathConstants.ADD_JOBS}`,
      };
      break;
    case "profiles":
      text = {
        primaryText: " User",
        secondaryText: "All Users",
        buttonName: "Create DEO Profile",
        linkTo: `${adminPath}/${adminPathConstants.PROFILES}/${adminPathConstants.ADD_PROFILE}`,
      };
      break;
    default:
      text = { primaryText: "New Hires", secondaryText: "View All New Hires" };
      break;
  }
  return text;
};

const generateColumnsBasedOnIndex = (index: number) => {
  let columns: GridColDef<rowsInterface>[] = [];
  switch (index) {
    case 0:
      columns = [
        {
          field: "firstName",
          headerName: "First name",
          width: 130,
          sortable: false,
        },
        {
          field: "lastName",
          headerName: "Last name",
          width: 130,
          sortable: false,
        },
        {
          field: "position",
          headerName: "Position",
          width: 200,
        },
        {
          field: "companyName",
          headerName: "Company Name",
          width: 200,
        },
        {
          field: "companyHQ",
          headerName: "Company HQ",
          width: 180,
        },
        {
          field: "industry",
          headerName: "Industry",
          width: 180,
        },
        {
          field: "companyEmployeeCount",
          headerName: "Employee Count",
          width: 250,
          type: "number",
        },
        {
          field: "companyLinkedIn",
          headerName: "Company LinkedIn Profile",
          width: 250,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => <LinkCell url={params.value || ""} />,
        },
        {
          field: "companyWebsite",
          headerName: "Company Website",
          width: 250,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => <LinkCell url={params.value || ""} />,
        },
      ];
      break;
    case 1:
      columns = [
        {
          field: "companyName",
          headerName: "Company Name",
          width: 130,
        },
        {
          field: "companyLinkedIn",
          headerName: "Company LinkedIn",
          width: 130,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => <LinkCell url={params.value || ""} />,
        },
        {
          field: "companyHQ",
          headerName: "Company HQ",
          width: 200,
        },
        {
          field: "companyEmployeeCount",
          headerName: "Employee Count",
          width: 200,
          type: "number",
        },
        {
          field: "fundingRaised",
          headerName: "Funding Raised",
          width: 180,
          type: "number",
        },
        {
          field: "industry",
          headerName: "Industry",
          width: 180,
        },
        {
          field: "companyWebsite",
          headerName: "Company Website",
          width: 250,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => <LinkCell url={params.value || ""} />,
        },
      ];
      break;
    case 2:
      columns = [
        {
          field: "jobTitle",
          headerName: "Job Title",
          width: 130,
        },
        {
          field: "jobLink",
          headerName: "Job Link",
          width: 130,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => <LinkCell url={params.value || ""} />,
        },
        {
          field: "companyName",
          headerName: "Company Name",
          width: 200,
        },
        {
          field: "companyHQ",
          headerName: "Company HQ",
          width: 180,
        },
        {
          field: "industry",
          headerName: "Industry",
          width: 180,
        },
        {
          field: "companyEmployeeCount",
          headerName: "Employee Count",
          width: 250,
          type: "number",
        },
        {
          field: "companyLinkedIn",
          headerName: "Company LinkedIn Profile",
          width: 250,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => <LinkCell url={params.value || ""} />,
        },
        {
          field: "companyWebsite",
          headerName: "Company Website",
          width: 250,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => <LinkCell url={params.value || ""} />,
        },
      ];
      break;
    case 3:
      columns = [
        {
          field: "trigger",
          headerName: "Trigger",
          width: 130,
        },
        {
          field: "triggerArticle",
          headerName: "Trigger Article",
          width: 130,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => <LinkCell url={params.value || ""} />,
        },
        {
          field: "categories",
          headerName: "Categories",
          width: 130,
        },
        {
          field: "companyName",
          headerName: "Company Name",
          width: 200,
        },
        {
          field: "companyHQ",
          headerName: "Company HQ",
          width: 180,
        },
        {
          field: "industry",
          headerName: "Industry",
          width: 180,
        },
        {
          field: "companyEmployeeCount",
          headerName: "Employee Count",
          width: 250,
          type: "number",
        },
        {
          field: "companyLinkedIn",
          headerName: "Company LinkedIn Profile",
          width: 250,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => <LinkCell url={params.value || ""} />,
        },
        {
          field: "companyWebsite",
          headerName: "Company Website",
          width: 250,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => <LinkCell url={params.value || ""} />,
        },
      ];
      break;
  }
  return columns;
};

export {
  generatePathBasedOnAdmin,
  generateAdminHeader,
  generateColumnsBasedOnIndex,
};
