const NewHires = () => {
  return (
    <div className="newhires_wrapper">
      <img
        src="/assets/images/new-hires-left.svg"
        alt=""
        className="new_hires_left"
      />
      <img
        src="/assets/images/new-hires-right.svg"
        alt=""
        className="new_hires_right"
      />
      <div className="container">
        <div className="row align-items-center newhires_main">
          <div className="col-md-6 funding_right">
            <button className="newhires_btn">New Hires</button>
            <h1 className="newhires_heading text-capitalize">
              Find new executive hires
            </h1>
            <p className="newhires_desc">
              LeadHawk's unique new hire data process pulls results prior to
              them being batch uploaded into Sales Navigator, allowing you to
              claim these leads first. You'll have your colleagues scratching
              their heads wondering what you're doing differently.
            </p>
          </div>
          <div className="col-md-6">
            <img
              src="/assets/images/new-hires-right_change.svg"
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewHires;
