import { useEffect } from "react";
import ProfileTable from "../ProfileTable/ProfileTable";
import { fetchProfiles } from "../../../store/slice/adminSlice/createProfileSlice";
import { useAppDispatch, useAppSelector } from "../../../store";

export default function CreateProfile() {
  const dispatch = useAppDispatch();
  const entries = useAppSelector((state) => state.adminProfile.entries);

  useEffect(() => {
    dispatch(fetchProfiles());
  }, [dispatch]);

  return <ProfileTable rowsData={entries} />;
}
