import { useContext, useState } from "react";
import "./homeBanner.scss";
import { Link } from "react-router-dom";
import ArrowRightIcon from "../../../../assets/icons/ArrowRightIcon";
import ImageWithVideoBanner from "../../../Popups/ImageWithVideoBanner";
import { FeaturesContext } from "../../../../contexts/APIContext/Features/FeaturesContext";

const HomeBanner = () => {
  const [popup, setPopup] = useState(<></>);

  const { setSigninTab } = useContext(FeaturesContext);

  return (
    <>
      <div className="home_banner_wrapper">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 banner_left">
              <h1 className="text-capitalize">Prospect with a purpose today</h1>
              <p>
                Claim the highest quality leads{" "}
                <b>
                  <u>before</u>
                </b>{" "}
                other reps at your company, and get into deal cycles{" "}
                <b>
                  <u>before</u>
                </b>{" "}
                your competition by seeing trigger events first.
              </p>
              <div className="free_trial_btn">
                <Link to="/auth">
                  <button
                    className="trial_btn"
                    onClick={() => setSigninTab("signup")}
                  >
                    Free Trial{" "}
                    <ArrowRightIcon className="group-hover:text-[#45a29e]" />
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 banner_right">
              <img
                src="/assets/images/banner-image.svg"
                alt=""
                className="img-fluid"
              />
              <img
                src="/assets/images/play-icon.svg"
                alt=""
                className="banner_play"
                onClick={() =>
                  setPopup(<ImageWithVideoBanner setPopup={setPopup} />)
                }
              />
            </div>
          </div>
        </div>
      </div>
      {popup}
    </>
  );
};

export default HomeBanner;
