import * as Yup from "yup";
import { regex } from "../regex/regex";
import { nameValidationSchema } from "./name-validation-schema";
import { isValidPhoneNumber } from "libphonenumber-js";

export const profileValidationSchema = Yup.object()
  .shape({
    email: Yup.string()
      .required("Email is required.")
      .matches(regex.mailFormat, "Invalid email format")
      .trim(),
    phoneNumber: Yup.string()
      .required("Phone Number is required.")
      .test("phoneNumber", "Invalid phone number", (value, schema) => {
        if (!value.startsWith("+")) {
          return schema.createError({
            message: "Enter Phone Number with country code (+1 2124567890)",
          });
        }

        if (!isValidPhoneNumber(value)) {
          return false;
        }

        return true;
      }),
  })
  .concat(nameValidationSchema);
