const ArrowRightIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5311 2.14273C4.54108 2.22226 4.56672 2.29903 4.60652 2.3686C4.64633 2.43818 4.69952 2.49919 4.76302 2.54811C4.82652 2.59702 4.89908 2.63288 4.97651 2.65362C5.05394 2.67435 5.13471 2.67955 5.21415 2.66891L9.13142 2.16073L0.40862 13.4841C0.310344 13.6117 0.266771 13.7731 0.28749 13.9328C0.308207 14.0925 0.391519 14.2374 0.519095 14.3357C0.646671 14.4339 0.808062 14.4775 0.967764 14.4568C1.12747 14.4361 1.2724 14.3528 1.37067 14.2252L10.0861 2.91145L10.5872 6.80798C10.6083 6.967 10.6915 7.1112 10.8186 7.2091C10.9457 7.30699 11.1063 7.35062 11.2655 7.33046C11.4245 7.30935 11.5687 7.22617 11.6666 7.09909C11.7645 6.97201 11.8081 6.81135 11.788 6.65221L11.0853 1.23617C11.0775 1.16456 11.0554 1.09526 11.0201 1.03245C10.9849 0.969627 10.9373 0.914585 10.8802 0.870626C10.8231 0.826668 10.7578 0.794702 10.688 0.776648C10.6183 0.758593 10.5457 0.754825 10.4744 0.765568L5.05728 1.45967C4.97775 1.46965 4.90098 1.49529 4.8314 1.5351C4.76183 1.5749 4.70082 1.62809 4.6519 1.69159C4.60298 1.75509 4.56713 1.82765 4.54639 1.90508C4.52566 1.98251 4.52046 2.06328 4.5311 2.14273Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowRightIcon;
