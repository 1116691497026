import { ReactNode } from "react";

function AdminTabPanel(props: {
  value: number;
  index: number;
  children: ReactNode;
}) {
  const { value, index, children } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && children}
    </div>
  );
}

export default AdminTabPanel;
