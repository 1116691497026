const PressReleases = () => {
  return (
    <div className="newhires_wrapper press_wrapper">
      <img src="/assets/images/dots-icon.svg" alt="" className="dots_icon" />
      <div className="container">
        <div className="row align-items-center newhires_main">
          <div className="col-md-6 funding_right">
            <button className="newhires_btn">Press Releases</button>
            <h1 className="newhires_heading text-capitalize">
              Find relevant press releases
            </h1>
            <p className="press_desc">
              LeadHawk pulls in press release data from the leading press
              release sites. Fill up your TAL with leads that recently completed
              an acquisition, merged with another company, won a large contract,
              opened up a new location, created a new product line, and more.
            </p>
          </div>
          <div className="col-md-6">
            <img
              src="/assets/images/press-image.svg"
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PressReleases;
