import { Link } from "react-router-dom";
import { commonPathConstants } from "../Routes/helper/commonRoutes";

export default function ProtectedFooter() {
  return (
    <section className="footer">
      <div className="privacy">
        <Link to={`/${commonPathConstants.PRIVACY_POLICY}`}>
          Privacy Policy
        </Link>
        <Link to={`/${commonPathConstants.TERMS_AND_CONDITIONS}`}>
          Terms and Conditions
        </Link>
        <Link to={`/${commonPathConstants.RETURN_POLICY}`}>Return Policy</Link>
        <Link to={`/${commonPathConstants.DISCLAIMER}`}>Disclaimer</Link>
      </div>
    </section>
  );
}
