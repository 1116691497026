const WhyLeadhawk = () => {
  return (
    <div className="whyleadhawk_wrapper" id="myleadHawk">
      <div className="container relative">
        <h1 className="text-capitalize">Why LeadHawk?</h1>
        <p>
          There are so many reasons to choose LeadHawk. Here are just a few....
        </p>
        <img src="/assets/images/why_leadhawk.webp" alt="Why Leadhawk" />
      </div>
    </div>
  );
};

export default WhyLeadhawk;
