import EditIcon from "@mui/icons-material/Edit";
import {
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import {
  deleteAdminFunds,
  resetErrors,
} from "../../../store/slice/adminSlice/adminFundingSlice";
import { useAppDispatch, useAppSelector } from "../../../store";
import { fundsDataInterface } from "../../../interfaces/interfaces";
import {
  adminPath,
  adminPathConstants,
} from "../../../Routes/helper/adminRoutes";
import DeleteConfirmationModal from "../../DeleteConfirmationModal/DeleteConfirmationModal";
import useNotification from "../../../hooks/useNotification";
import { useContext, useEffect, useState } from "react";
import { RESPONSE_STATUS } from "../../../enums/enums";
import LinkCell from "../../LinkCell/LinkCell";
import LoadingContext from "../../../contexts/APIContext/Loading/LoadingContext";
import AdminTable from "./AdminTable";

function FundTable({
  rowsData,
  paginationModel,
  setPaginationModel,
  onFilterChange,
}: {
  rowsData: fundsDataInterface[];
  paginationModel: {
    page: number;
    pageSize: number;
  };
  onFilterChange: (filter: GridFilterModel) => void;
  setPaginationModel: (pageData: { page: number; pageSize: number }) => void;
}) {
  const adminFunds = useAppSelector((state) => state.adminFunds);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showNotification } = useNotification();
  const { setIsLoading, isLoading } = useContext(LoadingContext);

  const [showModal, setShowModal] = useState(true);
  const [deleteId, setDeleteId] = useState("");

  const EditCell = ({ id }: { id: string }) => {
    const handleEditClick = () => {
      navigate(
        `/${adminPath}/${adminPathConstants.FUNDING}/${adminPathConstants.EDIT_FUNDS}/${id}`
      );
    };
    return (
      <div onClick={handleEditClick} style={{ cursor: "pointer" }}>
        <EditIcon style={{ color: "#45A29E" }} />
      </div>
    );
  };
  const DeleteCell = ({ id }: { id: string }) => {
    const handleEditClick = () => {
      setShowModal(true);
      setDeleteId(id);
    };

    return (
      <div onClick={handleEditClick} style={{ cursor: "pointer" }}>
        <DeleteIcon style={{ color: "#45A29E" }} />
      </div>
    );
  };

  const columns: GridColDef<fundsDataInterface>[] = [
    {
      field: "companyName",
      headerName: "Company Name",
      width: 200,
      sortable: false,
    },
    {
      field: "companyLinkedIn",
      headerName: "Company LinkedIn",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <LinkCell url={params.value || ""} />
      ),
    },
    {
      field: "companyHQ",
      headerName: "Company HQ",
      width: 200,
      valueGetter: (_, row) => {
        const hq = row.companyCity ? [row.companyCity] : [];
        if (row.companyHQ) {
          hq.push(row.companyHQ);
        }

        return hq.length > 0 ? hq.join(', ') : 'N/A';
      }
    },
    {
      field: "companyEmployeeCount",
      headerName: "Employee Count",
      width: 150,
      type: "number",
    },
    {
      field: "fundingRaised",
      headerName: "Funding Raised",
      width: 180,
      type: "number",
    },
    { field: "industry", headerName: "Industry", width: 180 },
    {
      field: "companyWebsite",
      headerName: "Company Website",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <LinkCell url={params.value || ""} />
      ),
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 180,
      disableColumnMenu: true,
      filterable: true,
      sortable: false,
      renderCell: (params) => {
        return new Intl.DateTimeFormat('en-US', {
          dateStyle: 'short',
          timeStyle: 'short',
        }).format(new Date(params.value));
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <EditCell id={params.row.id} />
      ), // Pass the id to EditCell
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <DeleteCell id={params.row.id} />
      ),
    },
  ];

  useEffect(() => {
    setIsLoading(adminFunds.status === RESPONSE_STATUS.LOADING);

    if (
      deleteId &&
      isLoading &&
      adminFunds.status === RESPONSE_STATUS.SUCCEEDED
    ) {
      showNotification("success", "Fund deleted successfully");
      setShowModal(false);
      setIsLoading(false);
      setDeleteId("");
    }
    if (adminFunds.error && typeof adminFunds.error === "string") {
      showNotification("error", adminFunds.error);
      dispatch(resetErrors());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminFunds, deleteId, isLoading, setIsLoading]);

  return (
    <div className="tableParent">
      <div className="min-h-[300px] h-[calc(100vh-320px)] py-[20px]">
        <AdminTable
          rowsData={rowsData}
          columns={columns}
          isLoading={isLoading}
          isRowSelectable={() => false}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          onFilterModelChange={onFilterChange}
          maxRecords={adminFunds.maxRecords}
        />
      </div>
      {showModal && deleteId && (
        <DeleteConfirmationModal
          deleteFn={() => dispatch(deleteAdminFunds({ id: deleteId }))}
          onClose={() => setShowModal(false)}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

export default FundTable;
