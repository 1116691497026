import "./HeaderComponent.scss";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useEffect, useState } from "react";
import PopOverMenu from "../../PopOver/PopOverMenu";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  fetchDraftAdminFunds,
  publishDraftFunds,
} from "../../../store/slice/adminSlice/adminFundingSlice";
import { RESPONSE_STATUS } from "../../../enums/enums";
import useNotification from "../../../hooks/useNotification";
import { AsyncThunk } from "@reduxjs/toolkit";
import {
  fetchDraftAdminJobs,
  publishDraftJobs,
} from "../../../store/slice/adminSlice/adminJobSlice";
import {
  fetchDraftAdminHires,
  InitialState,
  publishDraftHires,
} from "../../../store/slice/adminSlice/adminHireSlice";
import {
  fetchDraftAdminNews,
  publishDraftNews,
} from "../../../store/slice/adminSlice/adminNewsSlice";
import {toHumanString} from 'human-readable-numbers';

const HeaderComponent = ({
  primaryText,
  secondaryText,
  selectedTab,
  buttonName = "default",
  linkTo,
  draftMetrics
}: {
  primaryText: string;
  secondaryText?: string;
  selectedTab?: string;
  buttonName?: string;
  linkTo?: string;
  draftMetrics?: InitialState['metrics']
}) => {
  const to = `/${linkTo}`;
  const adminfunds = useAppSelector((state) => state.adminFunds);
  const adminJob = useAppSelector((state) => state.adminJob);
  const adminHires = useAppSelector((state) => state.adminHires);
  const adminNews = useAppSelector((state) => state.adminNews);

  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const [showDropwdown, setShowDropdown] = useState(false);
  const [dispatchStatus, setDispatchStatus] = useState(false);

  const dispatch = useAppDispatch();
  const { showNotification } = useNotification();

  const getAdminState = () => {
    if (pathname.includes("funding")) return adminfunds;
    if (pathname.includes("jobs")) return adminJob;
    if (pathname.includes("newHires")) return adminHires;
    if (pathname.includes("news")) return adminNews;
    return null;
  };

  const publishActions: Record<
    string,
    AsyncThunk<any, { ids: string[]; all?: boolean | undefined }, any>
  > = {
    funding: publishDraftFunds,
    jobs: publishDraftJobs,
    newHires: publishDraftHires,
    news: publishDraftNews,
  };

  const fetchActions: Record<
    string,
    AsyncThunk<
      any,
      {
        page: number;
      },
      any
    >
  > = {
    funding: fetchDraftAdminFunds,
    jobs: fetchDraftAdminJobs,
    newHires: fetchDraftAdminHires,
    news: fetchDraftAdminNews,
  };

  const publishHandler = (all = false) => {
    const state = getAdminState();

    console.log({state });
    const selectedType = Object.keys(publishActions).find((type) =>
      pathname.includes(type)
    );

    console.log({selectedType})

    if (state && selectedType) {
      const sendIds = all ? [] : state.selectedIds;
      dispatch(
        publishActions[selectedType]({
          ids: sendIds ?? [],
          all,
        })
      );
      setDispatchStatus(true);
    }
  };

  const showNotificationAndFetch = (
    message: string,
    fetchAction: AsyncThunk<
      any,
      {
        page: number;
      },
      any
    >
  ) => {
    showNotification("success", message);
    setDispatchStatus(false);
    setShowDropdown(false);
    dispatch(
      fetchAction({
        page: Number(searchParams.get("page")) - 1,
      })
    );
  };

  useEffect(() => {
    const state = getAdminState();
    const selectedType = Object.keys(fetchActions).find((type) =>
      pathname.includes(type)
    );

    if (
      dispatchStatus &&
      state?.status === RESPONSE_STATUS.SUCCEEDED &&
      selectedType
    ) {
      const publishResponse = getAdminState()?.publishResponse;
      if (publishResponse) {
        const messages: Record<string, string> = {
          funding: `${publishResponse.validDrafts} funds published. ${publishResponse.invalidDrafts} invalid funds not published.`,
          jobs: `${publishResponse.validDrafts} jobs published. ${publishResponse.invalidDrafts} invalid jobs not published.`,
          hires: `${publishResponse.validDrafts} executive hires published. ${publishResponse.invalidDrafts} invalid executive hires not published.`,
          news: `${publishResponse.validDrafts} news published. ${publishResponse.invalidDrafts} invalid news not published.`,
        };
        showNotificationAndFetch(
          messages[selectedType],
          fetchActions[selectedType]
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminfunds, adminJob, adminHires, adminNews, dispatchStatus, pathname]);

  return (
    <div className="HeaderContainer">
      <div className="HeaderParent">
        <div className="left">
          <div className="primaryHeader">{primaryText}</div>
          <div className="secondaryHeader">{secondaryText}</div>
        </div>
        <div className="right">
          {draftMetrics && (
            <div className="flex metrics">
              <div className="flex items-center border border-gray-300 rounded-md bg-white mr-2">
                <span className="text-gray-800 font-medium p-2">Lifetime Scrapped</span>
                <span className="border-l border-gray-300 text-gray-500 p-2">
                  {toHumanString(draftMetrics?.lifetimeCount || 0)}
                </span>
              </div>
              <div className="flex items-center border border-gray-300 rounded-md bg-white mr-2">
                <span className="text-gray-800 font-medium p-2">Last 7 Days</span>
                <span className="border-l border-gray-300 text-gray-500 p-2">
                  {toHumanString(draftMetrics?.past7DaysCount || 0)}
                </span>
              </div>
              <div className="flex items-center border border-gray-300 rounded-md bg-white">
                <span className="text-gray-800 font-medium p-2">
                  High Confident
                  <span className="text-small">(Unapproved)</span>
                </span>
                <span className="border-l border-gray-300 text-gray-500 p-2">
                  {toHumanString(draftMetrics?.remainingConfidenceCount || 0)}
                </span>
              </div>
            </div>
          )}

          {selectedTab && selectedTab === "draft" && (
            <PopOverMenu
              onOutsideClick={() => setShowDropdown(false)}
              className="relative flex"
            >
              <button
                className="header-publish-button rounded-l-md"
                disabled={
                  !getAdminState()?.draftEntries.length ||
                  !getAdminState()?.selectedIds?.length
                }
                onClick={() => {
                  publishHandler();
                  setShowDropdown(false);
                }}
              >
                Publish
              </button>
              <div className="w-0.5 h-10 bg-slate-200"></div>
              <button
                className="header-publish-button rounded-r-md text-2xl"
                onClick={() => setShowDropdown((prev) => !prev)}
              >
                <ArrowDropDownIcon />
              </button>
              {showDropwdown && (
                <div className="absolute top-10 bg-white text-black rounded-md w-full shadow-lg py-2 text-center">
                  <button
                    disabled={!getAdminState()?.draftEntries.length}
                    onClick={() => publishHandler(true)}
                    className="hover:bg-slate-300 w-full py-1"
                  >
                    Publish All
                  </button>
                </div>
              )}
            </PopOverMenu>
          )}
          {buttonName !== "default" &&
            ((selectedTab && selectedTab === "live") || !selectedTab) && (
              <>
                <Link to={to}>{buttonName}</Link>
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;
