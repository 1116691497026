import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export default function SettingsInput({
  value,
  willClear = false,
  onChange,
  existingValues,
  label
}: {
  value: string;
  willClear?: boolean;
  onChange: (value: string) => void;
  existingValues: string[];
  label?: string;
}) {
  const [oldValue, setOldValue] = useState(value);
  const { register, getValues, setValue, reset } = useForm<{
    value: string
  }>();

  useEffect(() => {
    setValue('value', value);
  }, [setValue, value])

  function saveItem() {
    const newValue = getValues('value');
    if (
      !newValue ||
      oldValue === newValue ||
      existingValues.includes(newValue)
    ) {
      setValue('value', oldValue);
    } else {
      onChange(newValue);
      setOldValue(newValue);
    }

    if(willClear) {
      reset({value: ''});
    }
  }

  return (
    <TextField
      sx={{width: 1}}
      placeholder="Add New (Enter to add)"
      variant="outlined"
      {...register('value')}
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          saveItem();
        }
      }}
      onBlur={() => {
        if (!willClear) {
          saveItem();
        }
      }}
      label={label}
      size="small" />
  );
}
