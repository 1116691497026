const Testimonials = () => {
  return (
    <div className="testimonial_wrapper">
      <div className="container">
        <div className="text-center trusted_btn">Testimonials</div>
        <h1 className="text-center py-3 text-capitalize">
          Sales Professionals do more with LeadHawk
        </h1>
        <p className="text-center">
          Read what our incredible users are saying about LeadHawk.
        </p>
        <div className="testimonial_list">
          <div className="testimonial_card">
            <div className="testimonial_user">
              <div className="testimonial_image">
                <img src="/assets/images/user-1.png" alt="" />
              </div>
              <div className="testimonial_info">
                <h6>Mark Stewart</h6>
                <p className="testimonial_user_desc">
                  Sales Development Representative, Tropic
                </p>
              </div>
            </div>
            <p className="testimonial_desc">
              “I have been able to set up multiple meetings from leads I found
              on Leadhawk. Almost all of them have turned into opportunities”
            </p>
          </div>
          <div className="testimonial_card">
            <div className="testimonial_user">
              <div className="testimonial_image">
                <img src="/assets/images/user-2.png" alt="" />
              </div>
              <div className="testimonial_info">
                <h6>Emily Vorce</h6>
                <p className="testimonial_user_desc">
                  Account Executive, Coupa
                </p>
              </div>
            </div>
            <p className="testimonial_desc">
              "With LeadHawk I've been able to cut my daily time sourcing leads
              from 2 hours to 20 minutes and I am finding better leads than I
              ever have"
            </p>
          </div>
          <div className="testimonial_card">
            <div className="testimonial_user">
              <div className="testimonial_image">
                <img src="/assets/images/user-3.png" alt="" />
              </div>
              <div className="testimonial_info">
                <h6>Christian Garza</h6>
                <p className="testimonial_user_desc">
                  Account Executive, NetSuite
                </p>
              </div>
            </div>
            <p className="testimonial_desc">
              "I have opened up multiple quality opportunities in the past month
              from LeadHawk. My outbound productivity has skyrocketed"
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
