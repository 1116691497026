// LoadingContext.js
import React from "react";
import { LoadingContextType } from "./LoadingContext.interface";

const LoadingContext = React.createContext<LoadingContextType>({
  isLoading: false,
  setIsLoading: function (): void {
    throw new Error("Function not implemented.");
  },
});

export default LoadingContext;
