import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sendToFilterServicePercolate } from "../../services/filterService";
import {
  jobsDataInterface,
  jobsFormValuesInterface,
} from "../../interfaces/interfaces";
import { RESPONSE_STATUS } from "../../enums/enums";
import { errorHandler } from "./helper/errorHandler";
import { axiosInstance } from "../../secutityUtils/axiosInstance";

const fetchUrl = `${
  import.meta.env.VITE_DEV_DATA_OPERATOR_URL
}/api/v1/dataEntry`;

type InitialState = {
  entries: jobsDataInterface[];
  status: RESPONSE_STATUS;
  error: string | Record<string, string>;
  maxRecords?: number;
};

// Define an initial state
const initialState: InitialState = {
  entries: [],
  status: RESPONSE_STATUS.IDLE, // Can be 'idle', 'loading', 'succeeded', or 'failed'
  error: "",
};

// Create an asynchronous thunk action to fetch data
export const fetchMyJobs = createAsyncThunk(
  "jobPosting/fetchMyJobs",
  async (
    {
      page,
      count = 25,
      filters,
    }: {
      page: number;
      count: number;
      filters?: { key: string; value: string }[];
    },
    { rejectWithValue }
  ) => {
    let filterQueries = "";

    filters?.forEach((item) => {
      filterQueries += `&${item.key}=${item.value}`;
    });
    // Make the GET request with headers
    const response = await axiosInstance
      .get(
        `${fetchUrl}/myEntries/jobs?page=${page}&count=${count}${filterQueries}`
      )
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

// Create an asynchronous thunk action to post new hires
export const postNewJobs = createAsyncThunk(
  "jobPosting/postNewJobs",
  async (
    {
      formData,
    }: {
      formData: jobsFormValuesInterface;
    },
    { rejectWithValue }
  ) => {
    // Make the POST request with headers and new hires data
    const response = await axiosInstance
      .post(`${fetchUrl}/JOBS`, formData)
      .then(async (res) => {
        const { id, ...restOfData } = res.data;
        await sendToFilterServicePercolate({
          ...restOfData,
          page: "JobPostings",
        });
        return res.data;
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

// Create an asynchronous thunk action to edit existing data
export const editJobs = createAsyncThunk(
  "jobPosting/editJobs",
  async (
    {
      pageName,
      id,
      newData,
    }: {
      pageName: string;
      id: string;
      newData: jobsFormValuesInterface;
    },
    { rejectWithValue }
  ) => {
    const data = { ...newData };
    delete data.createdDate;
    delete data.lastModifiedDate;

    // Make the PUT request with headers and updated data
    const response = await axiosInstance
      .put(`${fetchUrl}/${pageName}/${id}`, data)
      .then(async (res) => {
        const { id, ...restOfData } = res.data;
        await sendToFilterServicePercolate({
          ...restOfData,
          page: "JobPostings",
        });
        return res.data;
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

// Create a slice
export const jobPostingSlice = createSlice({
  name: "jobPosting",
  initialState,
  reducers: {
    fetchJobs: (state) => {
      state.entries = [];
      state.status = RESPONSE_STATUS.IDLE;
      state.error = "";
    },
    resetErrors: (state) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyJobs.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
        state.error = "";
      })
      .addCase(fetchMyJobs.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.entries = action.payload.data;
        state.maxRecords = action.payload.totalElements;
      })
      .addCase(fetchMyJobs.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(postNewJobs.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
        state.error = "";
      })
      .addCase(postNewJobs.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        // Optionally, you can update the state with the newly created hire data
      })
      .addCase(postNewJobs.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(editJobs.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
        state.error = "";
      })
      .addCase(editJobs.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;

        // Optionally, you can update the state with the edited data
      })
      .addCase(editJobs.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      });
  },
});

// Export actions and reducer
export const { fetchJobs, resetErrors } = jobPostingSlice.actions;
export default jobPostingSlice.reducer;
