import "./DataHeader.scss";
import ButtonCustom from "../../../features/ButtonCustom/ButtonCustom";
import { Link } from "react-router-dom";

const DataHeader = ({
  primaryText,
  secondaryText,
  selectedScreen = "default",
  buttonName = "default",
  linkTo,
}: {
  primaryText: string;
  secondaryText: string;
  selectedScreen?: string;
  buttonName?: string;
  linkTo?: string;
}) => {
  const to = `/${linkTo}`;
  const handleSavedSearches = () => {};

  return (
    <div className="HeaderContainer">
      <div className="HeaderParent">
        <div className="left">
          <div className="primaryHeader">{primaryText}</div>
          <div className="secondaryHeader">{secondaryText}</div>
        </div>
        <div className="right">
          {selectedScreen === "myLeads" && (
            <ButtonCustom variant="outlined" onClick={handleSavedSearches}>
              Saved Searches
            </ButtonCustom>
          )}
          {/* {
                        selectedScreen === 'default' &&
                        <span class="material-symbols-outlined">filter_list</span>
                    } */}
          {selectedScreen === "All" && (
            <>
              <Link className="link-button" to={to}>
                {buttonName}
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataHeader;
