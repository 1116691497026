import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { jobsValidationSchema } from "../../validators/jobs-validation-schema";
import { RESPONSE_STATUS, USER_ROLE } from "../../enums/enums";
import ButtonCustom from "../../features/ButtonCustom/ButtonCustom";
import { jobsFormValuesInterface } from "../../interfaces/interfaces";
import { jobsFields, pageName } from "./helper/fieldsData";
import {
  editJobs,
  postNewJobs,
  resetErrors as resetDataErrors,
} from "../../store/slice/jobPostingSlice";
import {
  editAdminJobs,
  postAdminJobs,
  resetErrors as resetAdminErrors,
} from "../../store/slice/adminSlice/adminJobSlice";
import MuiFormInput from "../FormComponents/MuiFormInput";
import useNotification from "../../hooks/useNotification";
import CircularLoader from "../Loaders/CircularLoader";
import { adminPath, adminPathConstants } from "../../Routes/helper/adminRoutes";
import {
  DATA_ENTRY,
  dataPathConstants,
} from "../../Routes/helper/dataEntryRoutes";
import CancelButton from "../CancelButton";

export default function JobForm() {
  const adminEntries = useAppSelector((state) => state.adminJob.entries);
  const jobEntries = useAppSelector((state) => state.jobPosting.entries);
  const userRole = useAppSelector((state) => state.signin.role);
  const jobs = useAppSelector((state) =>
    userRole === USER_ROLE.ADMIN ? state.adminJob : state.jobPosting
  );

  const { showNotification } = useNotification();

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [dispatchStatus, setDispatchStatus] = useState(false);

  const form = useForm<jobsFormValuesInterface>({
    resolver: yupResolver(jobsValidationSchema),
  });

  useEffect(() => {
    let result;
    if (userRole === USER_ROLE.ADMIN) {
      result = adminEntries?.find((entry) => entry.id === id);
    } else {
      result = jobEntries?.find((entry) => entry.id === id);
    }
    if (result) {
      form.reset(result);
    }
  }, [id, adminEntries, jobEntries, userRole, form]);

  useEffect(() => {
    if (id) {
      if (userRole === USER_ROLE.ADMIN && !adminEntries.length) {
        navigate(
          `/${adminPath}/${adminPathConstants.JOBS}?page=1&count=25&tab=live`
        );
      } else if (
        userRole === USER_ROLE.DATA_ENTRY_OPERATOR &&
        !jobEntries.length
      ) {
        navigate(
          `/${DATA_ENTRY}/${dataPathConstants.POST_JOBS}?page=1&count=25`
        );
      }
    }
  }, [id, jobEntries, userRole, navigate, adminEntries]);

  useEffect(() => {
    if (dispatchStatus && jobs.status === RESPONSE_STATUS.SUCCEEDED) {
      showNotification(
        "success",
        `Job post ${id ? "updated" : "added"} successfully`
      );
      if (userRole === USER_ROLE.ADMIN) {
        navigate(
          `/${adminPath}/${adminPathConstants.JOBS}?page=1&count=25&tab=live`
        );
      } else {
        navigate(
          `/${DATA_ENTRY}/${dataPathConstants.POST_JOBS}?page=1&count=25`
        );
      }
    }
    if (jobs.error && typeof jobs.error === "string") {
      showNotification("error", jobs.error);
      dispatch(
        userRole === USER_ROLE.ADMIN ? resetAdminErrors() : resetDataErrors()
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs]);

  const handleSubmit: SubmitHandler<jobsFormValuesInterface> = async (
    formData
  ) => {
    if (userRole === USER_ROLE.ADMIN) {
      if (id) {
        dispatch(editAdminJobs({ id, newData: formData }));
      } else {
        dispatch(postAdminJobs({ formData }));
      }
    } else {
      if (id) {
        dispatch(editJobs({ pageName, id, newData: formData }));
      } else {
        dispatch(postNewJobs({ formData }));
      }
    }
    setDispatchStatus(true);
  };

  return (
    <FormProvider {...form}>
      <form
        className="form-box"
        onSubmit={form.handleSubmit(handleSubmit)}
        onChange={() =>
          dispatch(
            userRole === USER_ROLE.ADMIN
              ? resetAdminErrors()
              : resetDataErrors()
          )
        }
      >
        <div className="form-row">
          {jobsFields.map((jobsField, index) => {
            return (
              <MuiFormInput
                key={index}
                {...jobsField}
                errorMessage={
                  typeof jobs.error !== "string"
                    ? jobs.error[jobsField.name]
                    : ""
                }
              />
            );
          })}
        </div>
        <div className="form-button">
          <ButtonCustom
            type="submit"
            endIcon={
              jobs.status === RESPONSE_STATUS.LOADING && (
                <CircularLoader size={20} />
              )
            }
            disabled={jobs.status === RESPONSE_STATUS.LOADING}
          >
            Save Details
          </ButtonCustom>
          <CancelButton />
        </div>
      </form>
    </FormProvider>
  );
}
