import {
  DataGrid,
  GridColDef,
  GridFeatureMode,
  GridFilterModel,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import "./Table.scss";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { rowsInterface } from "../../interfaces/interfaces";
import { filterPanel } from "../../constants/filterPanelConstant";
import CustomGridPagination from "../CustomPagination/CustomGridPagination";

function Table({
  rows,
  columns,
  onRowSelectionModelChange,
  checkboxSelection,
  rowCount,
  isLoading,
  apiRef,
  paginationModel,
  selectionModel,
  onFilterChange,
  onPaginationModelChange,
  paginationMode,
}: {
  rows: rowsInterface[];
  columns: GridColDef<rowsInterface>[];
  onRowSelectionModelChange: (newSelection: GridRowSelectionModel) => void;
  checkboxSelection: boolean;
  onPaginationModelChange: (pageData: {
    page: number;
    pageSize: number;
  }) => void;
  rowCount: number;
  isLoading: boolean;
  apiRef: React.MutableRefObject<GridApiCommunity>;
  paginationModel: {
    page: number;
    pageSize: number;
  };
  selectionModel: GridRowSelectionModel;
  onFilterChange?: (filterModel: GridFilterModel) => void;
  paginationMode: GridFeatureMode;
}) {
  return (
    <DataGrid
      rows={rows}
      sx={{
        "& .MuiDataGrid-cell > p": {
          marginBottom: "0",
        },
      }}
      disableVirtualization={true}
      paginationMode={paginationMode}
      rowCount={paginationMode === 'client' ? undefined : Math.max(rowCount || 0, 0)}
      filterMode="server"
      pagination
      columns={columns}
      checkboxSelection={checkboxSelection}
      onRowSelectionModelChange={onRowSelectionModelChange}
      getRowId={(row) => row.id} // Set the key using the id field
      slots={{
        loadingOverlay: () => <></>,
        pagination: () => <CustomGridPagination />,
      }}
      loading={isLoading}
      pageSizeOptions={[25]}
      paginationModel={paginationModel}
      apiRef={apiRef}
      onPaginationModelChange={onPaginationModelChange}
      rowSelectionModel={selectionModel}
      rowHeight={75}
      onFilterModelChange={onFilterChange}
      filterDebounceMs={400}
      slotProps={{
        filterPanel,
      }}
      disableRowSelectionOnClick
    />
  );
}

export default Table;
