import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sendToFilterServicePercolate } from "../../../services/filterService";
import { RESPONSE_STATUS } from "../../../enums/enums";
import { errorHandler } from "../helper/errorHandler";
import {
  newHiresData,
  newHiresFormValues,
} from "../../../interfaces/interfaces";
import { axiosInstance } from "../../../secutityUtils/axiosInstance";
import { GridRowId } from "@mui/x-data-grid";

const fetchUrl = `${import.meta.env.VITE_DEV_ADMIN_URL}/api/admin/dataentry`;
const draftURL = `${import.meta.env.VITE_DEV_ADMIN_URL}/api/admin/draft`;

export type InitialState = {
  entries: newHiresData[];
  draftEntries: newHiresData[];
  status: RESPONSE_STATUS;
  error: string | Record<string, string>;
  maxRecords?: number;
  selectedIds?: string[];
  publishResponse?: {
    invalidDraftIds: Array<GridRowId>;
    validDraftIds: Array<GridRowId>;
    validDrafts: number;
    invalidDrafts: number;
  };
  metrics: {
    lifetimeCount: number;
    past7DaysCount: number;
    remainingConfidenceCount: number;
    page: string;
  } | null;
};

// Define an initial state
const initialState: InitialState = {
  entries: [],
  draftEntries: [],
  status: RESPONSE_STATUS.IDLE,
  error: "",
  metrics: null
};

const pageName = "HIRES";

export const fetchAdminHires = createAsyncThunk(
  "adminHires/fetchAdminHires",
  async (
    {
      page,
      count = 25,
      filters,
    }: {
      page: number;
      count: number;
      filters?: { key: string; value: string }[];
    },
    { rejectWithValue }
  ) => {
    try {
      let filtersQueries = "";
      filters?.forEach((item) => {
        filtersQueries += `&${item.key}=${item.value}`;
      });
      // Make the GET request with headers
      const response = await axiosInstance
        .get(
          `${fetchUrl}/myEntries/hires?page=${page}&count=${count}${filtersQueries}`
        )
        .catch((err) => {
          throw rejectWithValue(err.response.data);
        });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchDraftAdminHires = createAsyncThunk(
  "adminHires/fetchDraftAdminHires",
  async (
    {
      page,
      count = 25,
    }: {
      page: number;
      count?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      // Make the GET request with headers
      const response = await axiosInstance
        .get(`${draftURL}/hires?page=${page}&count=${count}`)
        .catch((err) => {
          throw rejectWithValue(err.response.data);
        });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const postAdminHires = createAsyncThunk(
  "adminHires/postAdminHires",
  async (
    {
      formData,
    }: {
      formData: newHiresFormValues;
    },
    { rejectWithValue }
  ) => {
    const formattedData = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [
        key,
        typeof value === "number" ? value : String(value).toLowerCase(),
      ])
    );

    // Make the POST request with headers and new hires data
    const response = await axiosInstance
      .post(`${fetchUrl}/${pageName}`, formattedData)
      .then(async (res) => {
        const { id, ...restOfData } = res.data;
        await sendToFilterServicePercolate({
          ...restOfData,
          page: "NewExecutiveHires",
        });
        return res.data;
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

export const publishDraftHires = createAsyncThunk(
  "adminHires/publishDraftHires",
  async (
    {
      ids,
      all = false,
    }: {
      ids: string[];
      all?: boolean;
    },
    { rejectWithValue }
  ) => {
    // Make the POST request with headers and new hires data
    const response = await axiosInstance
      .post(`${draftURL}/draftToLive/hires?all=${all}`, ids)
      .then(async (res) => {
        return res.data;
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const deleteAdminHires = createAsyncThunk(
  "adminHires/deleteAdminHires",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    // Make the DELETE request with headers
    await axiosInstance.delete(`${fetchUrl}/${pageName}/${id}`).catch((err) => {
      throw rejectWithValue(err.response.data);
    });

    return id; // return deleted jobId to remove it from the state
  }
);

export const deleteDraftHires = createAsyncThunk(
  "adminHires/deleteDraftHires",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    // Make the DELETE request with headers
    await axiosInstance.delete(`${draftURL}/hires/${id}`).catch((err) => {
      throw rejectWithValue(err.response.data);
    });

    return id;
  }
);

// Create an asynchronous thunk action to edit existing data
export const editAdminHires = createAsyncThunk(
  "adminHires/editAdminHires",
  async (
    {
      id,
      newData,
    }: {
      id: string;
      newData: newHiresFormValues;
    },
    { rejectWithValue }
  ) => {
    const data = { ...newData };
    delete data.createdDate;
    delete data.lastModifiedDate;

    const response = await axiosInstance
      .put(`${fetchUrl}/${pageName}/${id}`, data)
      .then(async (res) => {
        const { id, ...restOfData } = res.data;
        await sendToFilterServicePercolate({
          ...restOfData,
          page: "NewExecutiveHires",
        });
        return res.data;
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

export const editDraftHires = createAsyncThunk(
  "adminHires/editDraftHires",
  async (
    {
      id,
      formData,
    }: {
      id: string;
      formData: newHiresData;
    },
    { rejectWithValue }
  ) => {
    const response = await axiosInstance
      .put(`${draftURL}/hires/${id}`, formData)
      .then(async (res) => {
        return res.data;
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

// Draft ANalytics
export const fetchDraftAnalytics = createAsyncThunk(
  "draftAnalytics/fetchMetrics",
  async (
    page: string,
    { rejectWithValue }
  ) => {
    try {
      // Make the GET request with headers
      const response = await axiosInstance
        .get(
          `${draftURL}/metrics?page=${page}`
        )
        .catch((err) => {
          throw rejectWithValue(err.response.data);
        });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const adminHireSlice = createSlice({
  name: "adminHires",
  initialState,
  reducers: {
    fetchHires: (state) => {
      state.entries = [];
      state.status = RESPONSE_STATUS.IDLE;
      state.error = "";
    },
    resetErrors: (state) => {
      state.error = "";
    },
    setSelectedIds: (state, action) => {
      state.selectedIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminHires.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchAdminHires.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.entries = action.payload.data;
        state.maxRecords = action.payload.totalElements;
      })
      .addCase(fetchAdminHires.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(fetchDraftAdminHires.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchDraftAdminHires.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.draftEntries = action.payload.data;
        state.maxRecords = action.payload.totalElements;
      })
      .addCase(fetchDraftAdminHires.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(postAdminHires.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(postAdminHires.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        // Optionally, you can update the state with the newly created hire data
      })
      .addCase(postAdminHires.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(publishDraftHires.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(publishDraftHires.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.publishResponse = action.payload;
      })
      .addCase(publishDraftHires.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(deleteAdminHires.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(deleteAdminHires.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.entries = state.entries.filter(
          (job) => job.id !== action.payload
        );
      })
      .addCase(deleteAdminHires.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(deleteDraftHires.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(deleteDraftHires.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.entries = state.entries.filter(
          (job) => job.id !== action.payload
        );
      })
      .addCase(deleteDraftHires.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(editAdminHires.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(editAdminHires.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(editAdminHires.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(editDraftHires.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(editDraftHires.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(editDraftHires.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })

      .addCase(fetchDraftAnalytics.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
        state.metrics = null;
      })
      .addCase(fetchDraftAnalytics.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.metrics = action.payload
      })
      .addCase(fetchDraftAnalytics.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      });
  },
});

// Export actions and reducer
export const { fetchHires, resetErrors, setSelectedIds } =
  adminHireSlice.actions;
export default adminHireSlice.reducer;
