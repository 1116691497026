import { useLayoutEffect, useState } from "react";
import "./MainContent.scss";
// import imageSrc from "../../../assets/images/Vector_7.png";
import Footer from "../Footer/Footer";
import HomeBanner from "./HomeComponents/HomeBanner";
import TrustedBy from "./HomeComponents/TrustedBy";
import OurMisson from "./HomeComponents/OurMisson";
import WhyLeadhawk from "./HomeComponents/WhyLeadhawk";
import NewHires from "./HomeComponents/NewHires";
import Funding from "./HomeComponents/Funding";
import PressReleases from "./HomeComponents/PressReleases";
import Testimonials from "./HomeComponents/Testimonials";
import JobPostings from "./HomeComponents/JobPostings";
import Changing from "./HomeComponents/Changing";
import Header from "../header/Header";

declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void;
    YT: {
      PlayerState: { ENDED: any };
      Player: any;
    };
  }
}

function MainContent() {
  // const myRef = useRef(null);
  // const gridItemRef = useRef(null);
  // const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // const [bgImage, setBgImage] = useState(imageSrc);

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  // const videoRef = useRef(null);

  // const handlePlayClick = () => {
  //   setIsVideoPlaying(true);
  // };

  const onPlayerReady = (event: { target: { playVideo: () => void } }) => {
    // You could also start the video playback here if needed
    event.target.playVideo();
  };

  const onPlayerStateChange = (event: { data: any }) => {
    if (event.data === window.YT.PlayerState.ENDED) {
      setIsVideoPlaying(false);
    }
  };

  useLayoutEffect(() => {
    // This code loads the IFrame Player API code asynchronously.
    var tag = document.createElement("script");
    tag.src = "https://www.youtube.com/embed";
    var firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

    // This function gets called when the API code downloads and is ready
    // to create the YouTube player.
    window.onYouTubeIframeAPIReady = function () {
      new window.YT.Player("player", {
        height: "315",
        width: "560",
        videoId: "kc7Ho48-Ivs",
        playerVars: { autoplay: 1, start: 1 },
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
    };
  }, [isVideoPlaying]);

  // useEffect(() => {
  //   if (isVideoPlaying) {
  // videoRef.current.play()
  //   }
  // }, [isVideoPlaying]);

  // useEffect(() => {
  // const handleResize = () => {
  //   setIsMobile(window.innerWidth <= 768);
  // };

  // Add event listener for window resize
  //   window.addEventListener("resize", handleResize);
  //   // Cleanup the event listener when the component is unmounted
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, [window.innerWidth]);

  // useEffect(() => {
  //   if (isMobile) {
  //     setBgImage("");
  //   } else {
  //     setBgImage(imageSrc);
  //   }
  // }, [isMobile]);

  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     if (entries[0].isIntersecting) {
  //       entries[0].target.classList.add('fadeUpShow');
  //     } else {
  //       entries[0].target.classList.remove('fadeUpShow');
  //     }
  //   })
  //   observer.observe(myRef.current);
  // }, [])

  return (
    <>
      {/* <NavBar /> */}
      <Header />
      <HomeBanner />
      <TrustedBy />
      <OurMisson />
      <WhyLeadhawk />
      <NewHires />
      <Funding />
      <PressReleases />
      <JobPostings />
      <Testimonials />
      <Changing />
      <Footer />
    </>
  );
}

export default MainContent;
