import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sendToFilterServicePercolate } from "../../services/filterService";
import { RESPONSE_STATUS } from "../../enums/enums";
import { errorHandler } from "./helper/errorHandler";
import { newsData, newsFormValues } from "../../interfaces/interfaces";
import { axiosInstance } from "../../secutityUtils/axiosInstance";

const fetchUrl = `${import.meta.env.VITE_DEV_DATA_OPERATOR_URL}/api/v1`;

type InitialState = {
  entries: newsData[];
  status: RESPONSE_STATUS;
  error: string | Record<string, string>;
  maxRecords?: number;
};

// Define an initial state
const initialState: InitialState = {
  entries: [],
  status: RESPONSE_STATUS.IDLE, // Can be 'idle', 'loading', 'succeeded', or 'failed'
  error: "",
};

// Create an asynchronous thunk action to fetch data
export const fetchMyNews = createAsyncThunk(
  "news/fetchMyNews",
  async (
    {
      page,
      count = 25,
      filters,
    }: {
      page: number;
      count: number;
      filters?: { key: string; value: string }[];
    },
    { rejectWithValue }
  ) => {
    let filterQueries = "";

    filters?.forEach((item) => {
      filterQueries += `&${item.key}=${item.value}`;
    });
    // Make the GET request with headers
    const response = await axiosInstance
      .get(
        `${fetchUrl}/dataEntry/myEntries/news?page=${page}&count=${count}${filterQueries}`
      )
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

// Create an asynchronous thunk action to post new hires
export const postNews = createAsyncThunk(
  "news/postNews",
  async ({ formData }: { formData: newsFormValues }, { rejectWithValue }) => {
    // Make the POST request with headers and new hires data
    const response = await axiosInstance
      .post(`${fetchUrl}/dataEntry/NEWS`, formData)
      .then(async (res) => {
        const { id, ...restOfData } = res.data;
        await sendToFilterServicePercolate({
          ...restOfData,
          page: "GeneralBusinessNews",
        });
        return res.data;
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

// Create an asynchronous thunk action to edit existing data
export const editNews = createAsyncThunk(
  "news/editJobs",
  async (
    {
      pageName,
      id,
      newData,
    }: { newData: newsFormValues; pageName: string; id: string },
    { rejectWithValue }
  ) => {
    const data = { ...newData };
    delete data.createdDate;
    delete data.lastModifiedDate;

    // Make the PUT request with headers and updated data
    const response = await axiosInstance
      .put(`${fetchUrl}/dataEntry/${pageName}/${id}`, data)
      .then(async (res) => {
        const { id, ...restOfData } = res.data;
        await sendToFilterServicePercolate({
          ...restOfData,
          page: "GeneralBusinessNews",
        });
        return res.data;
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

// Create a slice
export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    fetchJobs: (state) => {
      state.entries = [];
      state.status = RESPONSE_STATUS.IDLE;
      state.error = "";
    },
    resetErrors: (state) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyNews.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchMyNews.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.entries = action.payload.data;
        state.maxRecords = action.payload.totalElements;
      })
      .addCase(fetchMyNews.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(postNews.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(postNews.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        // Optionally, you can update the state with the newly created hire data
      })
      .addCase(postNews.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(editNews.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(editNews.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;

        // Optionally, you can update the state with the edited data
      })
      .addCase(editNews.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      });
  },
});

// Export actions and reducer
export const { fetchJobs, resetErrors } = newsSlice.actions;
export default newsSlice.reducer;
