import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sendToFilterServicePercolate } from "../../../services/filterService";
import { newsData, newsFormValues } from "../../../interfaces/interfaces";
import { RESPONSE_STATUS } from "../../../enums/enums";
import { errorHandler } from "../helper/errorHandler";
import { axiosInstance } from "../../../secutityUtils/axiosInstance";
import { GridRowId } from "@mui/x-data-grid";

const fetchUrl = `${import.meta.env.VITE_DEV_ADMIN_URL}/api/admin/dataentry`;
const draftURL = `${import.meta.env.VITE_DEV_ADMIN_URL}/api/admin/draft`;

type InitialState = {
  entries: newsData[];
  draftEntries: newsData[];
  status: RESPONSE_STATUS;
  error: string | Record<string, string>;
  maxRecords?: number;
  selectedIds?: string[];
  publishResponse?: {
    invalidDraftIds: Array<GridRowId>;
    validDraftIds: Array<GridRowId>;
    validDrafts: number;
    invalidDrafts: number;
  };
};

// Define an initial state
const initialState: InitialState = {
  entries: [],
  draftEntries: [],
  status: RESPONSE_STATUS.IDLE, // Can be 'idle', 'loading', 'succeeded', or 'failed'
  error: "",
};

const pageName = "NEWS";

export const fetchAdminNews = createAsyncThunk(
  "adminNews/fetchAdminNews",
  async (
    {
      page,
      count = 25,
      filters,
    }: {
      page: number;
      count: number;
      filters?: { key: string; value: string }[];
    },
    { rejectWithValue }
  ) => {
    try {
      let filtersQueries = "";
      filters?.forEach((item) => {
        filtersQueries += `&${item.key}=${item.value}`;
      });
      // Make the GET request with headers
      const response = await axiosInstance
        .get(
          `${fetchUrl}/myEntries/news?page=${page}&count=${count}${filtersQueries}`
        )
        .catch((err) => {
          throw rejectWithValue(err.response.data);
        });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchDraftAdminNews = createAsyncThunk(
  "adminNews/fetchDraftAdminNews",
  async (
    {
      page,
      count = 25,
    }: {
      page: number;
      count?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      // Make the GET request with headers
      const response = await axiosInstance
        .get(`${draftURL}/news?page=${page}&count=${count}`)
        .catch((err) => {
          throw rejectWithValue(err.response.data);
        });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const postAdminNews = createAsyncThunk(
  "adminNews/postAdminNews",
  async ({ formData }: { formData: newsFormValues }, { rejectWithValue }) => {
    const formattedData = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [
        key,
        typeof value === "number" ? value : String(value).toLowerCase(),
      ])
    );

    // Make the POST request with headers and new hires data
    const response = await axiosInstance
      .post(`${fetchUrl}/${pageName}`, formattedData)
      .then(async (res) => {
        const { id, ...restOfData } = res.data;
        await sendToFilterServicePercolate({
          ...restOfData,
          page: "GeneralBusinessNews",
        });
        return res.data;
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

export const publishDraftNews = createAsyncThunk(
  "adminNews/publishDraftNews",
  async (
    {
      ids,
      all = false,
    }: {
      ids: string[];
      all?: boolean;
    },
    { rejectWithValue }
  ) => {
    // Make the POST request with headers and new hires data
    const response = await axiosInstance
      .post(`${draftURL}/draftToLive/news?all=${all}`, ids)
      .then(async (res) => {
        return res.data;
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const deleteAdminNews = createAsyncThunk(
  "adminNews/deleteAdminNews",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    // assuming you are identifying the job to be deleted by "jobId"

    // Make the DELETE request with headers
    await axiosInstance.delete(`${fetchUrl}/${pageName}/${id}`).catch((err) => {
      throw rejectWithValue(err.response.data);
    });

    return id; // return deleted jobId to remove it from the state
  }
);

export const deleteDraftNews = createAsyncThunk(
  "adminNews/deleteDraftNews",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    // Make the DELETE request with headers
    await axiosInstance.delete(`${draftURL}/news/${id}`).catch((err) => {
      throw rejectWithValue(err.response.data);
    });

    return id;
  }
);

// Create an asynchronous thunk action to edit existing data
export const editAdminNews = createAsyncThunk(
  "adminNews/editAdminNews",
  async (
    {
      id,
      newData,
    }: {
      id: string;
      newData: newsFormValues;
    },
    { rejectWithValue }
  ) => {
    const data = { ...newData };
    delete data.createdDate;
    delete data.lastModifiedDate;

    const response = await axiosInstance
      .put(`${fetchUrl}/${pageName}/${id}`, data)
      .then(async (res) => {
        const { id, ...restOfData } = res.data;
        await sendToFilterServicePercolate({
          ...restOfData,
          page: "GeneralBusinessNews",
        });
        return res.data;
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

export const editDraftNews = createAsyncThunk(
  "adminNews/editDraftNews",
  async (
    {
      id,
      formData,
    }: {
      id: string;
      formData: newsData;
    },
    { rejectWithValue }
  ) => {
    const response = await axiosInstance
      .put(`${draftURL}/news/${id}`, formData)
      .then(async (res) => {
        return res.data;
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

export const adminNewsSlice = createSlice({
  name: "adminNews",
  initialState,
  reducers: {
    fetchNews: (state) => {
      state.entries = [];
      state.status = RESPONSE_STATUS.IDLE;
      state.error = "";
    },
    resetErrors: (state) => {
      state.error = "";
    },
    setSelectedIds: (state, action) => {
      state.selectedIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminNews.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchAdminNews.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.entries = action.payload.data;
        state.maxRecords = action.payload.totalElements;
      })
      .addCase(fetchAdminNews.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(fetchDraftAdminNews.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchDraftAdminNews.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.draftEntries = action.payload.data;
        state.maxRecords = action.payload.totalElements;
      })
      .addCase(fetchDraftAdminNews.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(postAdminNews.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(postAdminNews.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(postAdminNews.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(publishDraftNews.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(publishDraftNews.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.publishResponse = action.payload;
      })
      .addCase(publishDraftNews.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(deleteAdminNews.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(deleteAdminNews.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.entries = state.entries.filter(
          (job) => job.id !== action.payload
        );
      })
      .addCase(deleteAdminNews.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(deleteDraftNews.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(deleteDraftNews.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.entries = state.entries.filter(
          (job) => job.id !== action.payload
        );
      })
      .addCase(deleteDraftNews.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(editAdminNews.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(editAdminNews.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(editAdminNews.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(editDraftNews.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(editDraftNews.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(editDraftNews.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      });
  },
});

// Export actions and reducer
export const { fetchNews, resetErrors, setSelectedIds } =
  adminNewsSlice.actions;
export default adminNewsSlice.reducer;
