import * as yup from "yup";
import { regex } from "../regex/regex";
import { isValidPhoneNumber } from "libphonenumber-js";

export const contactUsValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("First Name is required")
    .matches(
      regex.stringFormat,
      "First Name should not contain special characters or numbers"
    )
    .min(2, "First Name must have at least 2 characters")
    .max(30, "First Name must be under 30 characters")
    .trim(),
  lastName: yup
    .string()
    .test(
      "lastName",
      "Last Name should not contain special characters or numbers",
      (value) => {
        if (!value) return true;
        if (!regex.stringFormat.test(value)) {
          return false;
        }
        return true;
      }
    )
    .max(30, "Last Name must be under 30 characters")
    .trim(),
  phoneNumber: yup
    .string()
    .test("phoneNumber", "Invalid phone number", (value) => {
      if (!value) return true;
      if (!isValidPhoneNumber(value, "IN")) {
        return false;
      }
      if (value.startsWith("+") && !value.startsWith("+91")) {
        return false;
      }
      return true;
    }),
  email: yup
    .string()
    .required("Email is required.")
    .matches(regex.mailFormat, "Invalid email format")
    .trim(),
  subject: yup
    .string()
    .required("Subject is required")
    .min(2, "Subject must have at least 2 characters")
    .max(60, "Subject must be under 60 characters")
    .trim(),
  message: yup
    .string()
    .required("Message is required")
    .min(2, "Message must have at least 2 characters")
    .trim(),
});
