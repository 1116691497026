import PaidIcon from "@mui/icons-material/Paid";
import PendingIcon from "@mui/icons-material/Pending";
import ScheduleIcon from "@mui/icons-material/Schedule";
import PaymentsIcon from "@mui/icons-material/Payments";
import TodayIcon from "@mui/icons-material/Today";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";

export default function InvoiceCard({
  duration,
  paymentDate,
  status,
  amount,
  invoiceUrl,
  invoiceNumber,
}: {
  duration: string;
  paymentDate: string;
  status: string;
  amount: number;
  invoiceUrl: string;
  invoiceNumber: string;
}) {
  const formattedAmount = useMemo(
    () =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(amount),
    [amount]
  );

  return (
    <Card className="w-full md:max-w-sm">
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: status !== "paid" ? red[500] : green[800] }}
            aria-label="recipe"
          >
            {status !== "paid" ? <PendingIcon /> : <PaymentsIcon />}
          </Avatar>
        }
        title={
          <div className="flex justify-content-between w-100">
            <div className="flex flex-column">
              <div className="secondaryHeader">Invoice</div>
              <div className="secondaryHeader">{invoiceNumber}</div>
            </div>

            <Link href={invoiceUrl} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faExternalLink} />
              <span className="px-2 d-none d-sm-inline">View Invoice</span>
            </Link>
          </div>
        }
      />

      <CardContent className="px-0">
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <ScheduleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Subscription Period" secondary={duration} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <PaidIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Amount" secondary={formattedAmount} />
          </ListItem>

          {paymentDate && (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <TodayIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Amount paid on" secondary={paymentDate} />
            </ListItem>
          )}
        </List>
      </CardContent>
    </Card>
  );
}
