export const fundsFields = [
  {
    label: "Company Name",
    name: "companyName",
  },
  {
    label: "City",
    name: "companyCity",
  },
  {
    label: "State",
    name: "companyHQ",
  },
  {
    label: "Industry",
    name: "industry",
  },
  {
    label: "Funding Raised",
    name: "fundingRaised",
    type: "number",
  },
  {
    label: "Employee Count",
    name: "companyEmployeeCount",
    type: "number",
  },
  {
    label: "Company LinkedIn",
    name: "companyLinkedIn",
  },
  {
    label: "Company Website",
    name: "companyWebsite",
  },
];

export const pageName = "FUNDING";
