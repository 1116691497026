import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RESPONSE_STATUS } from "../../../enums/enums";
import { axiosInstance } from "../../../secutityUtils/axiosInstance";

interface JobPostings {
  id: string;
  dataEntryOperatorId: string;
  jobTitle: string;
  jobLink: string;
  companyName: string;
  companyHQ: string;
  companyCity: string;
  industry: string;
  companyEmployeeCount: number;
  companyLinkedIn: string;
  companyWebsite: string;
  createdDate: string;
  lastModifiedDate: string;
}

interface Funding {
  id: string;
  dataEntryOperatorId: string;
  companyName: string;
  companyHQ: string;
  companyCity: string;
  industry: string;
  fundingRaised: number;
  companyEmployeeCount: number;
  companyLinkedIn: string;
  companyWebsite: string;
  createdDate: string;
  lastModifiedDate: string;
}

interface LinkedIn {
  id: string;
  googleSearchCompany: string;
  companyName: string;
  __v: number;
  companyCity: string;
  companyEmployeeCount: number;
  companyHQ: string;
  companyLinkedIn: string;
  companySize: string;
  companyWebsite: string;
  country: string;
  createdDate: string;
  industry: string;
  lastModifiedDate: string;
  scrappedCompanyHQ: string;
  specialties: string[];
}

interface BusinessNews {
  id: string;
  dataEntryOperatorId: string;
  trigger: string;
  triggerArticle: string;
  companyName: string;
  companyHQ: string;
  companyCity: string;
  industry: string;
  companyEmployeeCount: number;
  companyLinkedIn: string;
  companyWebsite: string;
  createdDate: string;
  lastModifiedDate: string;
}

interface ExecutiveHires {
  id: string;
  dataEntryOperatorId: string;
  firstName: string;
  lastName: string;
  position: string;
  companyName: string;
  companyHQ: string;
  companyCity: string;
  industry: string;
  companyEmployeeCount: number;
  companyLinkedIn: string;
  companyWebsite: string;
  createdDate: string;
  lastModifiedDate: string;
}

interface RecentDataEntriesDTO {
  jobPostings: JobPostings[];
  funding: Funding[];
  linkedInData: LinkedIn[];
  businessNews: BusinessNews[];
  executiveHires: ExecutiveHires[];
}

// Define an initial state
const initialState: {
  entries: RecentDataEntriesDTO | null;
  status: RESPONSE_STATUS;
  error: string;
} = {
  entries: null,
  status: RESPONSE_STATUS.IDLE,
  error: "",
};

export const fetchRecentEntries = createAsyncThunk(
  "recentEntries/fetchRecentEntries",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `${import.meta.env.VITE_DEV_ADMIN_URL}/api/admin/analysis/recentEntries`
      );
      console.log("API Response:", response.data); // Add this line
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const recentEntriesSlice = createSlice({
  name: "recentEntries",
  initialState,
  reducers: {
    resetRecentEntries: (state) => {
      state.entries = null;
      state.status = RESPONSE_STATUS.IDLE;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecentEntries.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchRecentEntries.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.entries = action.payload;
      })
      .addCase(fetchRecentEntries.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        state.error = action.payload?.message || "An error occurred";
      });
  },
});

// Export actions and reducer
export const { resetRecentEntries } = recentEntriesSlice.actions;
export default recentEntriesSlice.reducer;