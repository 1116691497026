import { TextareaHTMLAttributes, useId } from "react";
import { useController, useFormContext } from "react-hook-form";
import { FormErrorMessage } from "../../features/FormErrorMessage/FormErrorMessage";

interface TextAreaInputProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  placeholder: string;
  label: string;
  errorMessage?: string;
}

export default function TextAreaInput({
  errorMessage,
  ...textAreaProps
}: TextAreaInputProps) {
  const { register, control } = useFormContext();
  const {
    fieldState: { error },
  } = useController({
    control,
    name: textAreaProps.name,
  });

  const id = useId();

  return (
    <div className="form_group">
      <label htmlFor="message">How can we help you?</label>
      <textarea
        className="resize-none"
        {...textAreaProps}
        {...register(textAreaProps.name)}
        rows={4}
        cols={3}
        id={id}
      ></textarea>
      {error ? (
        <FormErrorMessage error={error} />
      ) : (
        errorMessage && <p className="error-msg">{errorMessage}</p>
      )}
    </div>
  );
}
