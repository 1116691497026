const SubsBaseUrl = "/api/v1/";
// const REACT_APP_LOCALAPP =
//   import.meta.env.REACT_APP_LOCALAPP || "https://54.235.32.140:8080/api/v1/";
// const REACT_APP_DEV_URL = process.env.REACT_APP_DEV_URL;
const REACT_APP_DEV_URL = import.meta.env.VITE_DEV_USER_URL;

const landingPagesUrl: Record<string, string> = {
  newHiresURL: "landing/hires",
  fundingsURL: "landing/funds",
  jobPostingURL: "landing/jobs",
  pressReleaseURL: "landing/news",
};

// Just use this constant as eg : useFlowScreens[0]
const userFlowScreens = [
  "newHires",
  "fundings",
  "jobPostings",
  "pressReleases",
  "myLeads",
];

const myLeadsPostURL: Record<string, string> = {
  newHires: "userService/myLeads/HIRES",
  fundings: "userService/myLeads/FUNDING",
  jobPostings: "userService/myLeads/JOBS",
  pressReleases: "userService/myLeads/NEWS",
};

const myLeadsFetchURL: Record<string, string> = {
  newHires: "userService/myLeads/hires",
  fundings: "userService/myLeads/funding",
  jobPostings: "userService/myLeads/jobs",
  pressReleases: "userService/myLeads/news",
};

const filterApplyURL: Record<string, string> = {
  newHires: "userService/filter/NewExecutiveHires",
  fundings: "userService/filter/NewFunding",
  jobPostings: "userService/filter/JobPosting",
  pressReleases: "userService/filter/GeneralBusinessNews",
};

const getSavedSearchesURL = "userService/savedSearches/getSavedSearches";

const filterCommonTitles = ["Company HQ", "Industry", "Employee Count"];

const mapTofilterCommonTitles: Record<string, string> = {
  "Company HQ": "companyHQ",
  Industry: "industry",
  "Employee Count": "companyEmployeeCount",
  Position: "position",
  "Funding Raised": "fundingRaised",
  "Job Title": "jobTitle",
  Trigger: "trigger",
};

const mapToURL: Record<string, string> = {
  HIRES: userFlowScreens[0],
  FUNDING: userFlowScreens[1],
  JOBS: userFlowScreens[2],
  NEWS: userFlowScreens[3],
};

const mapToFilterURL: Record<string, string> = {
  NewExecutiveHires: userFlowScreens[0],
  NewFunding: userFlowScreens[1],
  JobPostings: userFlowScreens[2],
  GeneralBusinessNews: userFlowScreens[3],
};

const mapToDeleteFilterURL: Record<string, string> = {
  NewExecutiveHires: "HIRES",
  NewFunding: "FUNDING",
  JobPostings: "JOBS",
  GeneralBusinessNews: "NEWS",
};

const commonTitles: Record<string, number> = {
  "Company Name": 200,
  "Company Website": 125,
  "Company HQ": 125,
  Industry: 125,
  "Employee Count": 100,
  "Company LinkedIn": 125,
};

const newHiresTitles = ["First Name", "Last Name", "Position"];
const fundingTitles = ["Funding Raised"];
const jobPostingTitles = ["Job Title", "Job Link"];
const pressReleaseTitles = ["Trigger", "Source Article"];
const commonColumns = [
  "companyName",
  "companyWebsite",
  "companyHQ",
  "industry",
  "companyEmployeeCount",
  "companyLinkedIn",
];

const newHiresColumns = ["firstName", "lastName", "position"];

const fundingColumns = ["fundingRaised"];

const pressReleaseColumns = ["trigger", "triggerArticle"];

const jobPostingsColumns = ["jobTitle", "jobLink"];

//    Constants for Filters

const saveFilterURL: Record<string, string> = {
  newHires: "userService/savedSearches/HIRES",
  fundings: "userService/savedSearches/FUNDING",
  jobPostings: "userService/savedSearches/JOBS",
  pressReleases: "userService/savedSearches/NEWS",
};

const applyFiltersURL: Record<string, string> = {
  newHires: "userService/filter/NewExecutiveHires",
  fundings: "userService/filter/NewFunding",
  jobPostings: "userService/filter/JobPosting",
  pressReleases: "userService/filter/pressRelease",
};

const queryTabs = ["hires", "funding", "jobs", "news"];

const queryTabsRecord: Record<string, string> = {
  newHires: "hires",
  fundings: "funding",
  jobPostings: "jobs",
  pressReleases: "news",
};

export {
  userFlowScreens,
  commonTitles,
  newHiresTitles,
  fundingTitles,
  jobPostingTitles,
  pressReleaseTitles,
  commonColumns,
  newHiresColumns,
  fundingColumns,
  pressReleaseColumns,
  jobPostingsColumns,
  SubsBaseUrl as baseUrl,
  REACT_APP_DEV_URL as devURL,
  landingPagesUrl as userFlowURL,
  myLeadsPostURL,
  myLeadsFetchURL,
  saveFilterURL,
  filterCommonTitles,
  mapTofilterCommonTitles,
  applyFiltersURL,
  getSavedSearchesURL,
  mapToURL,
  filterApplyURL,
  mapToFilterURL,
  mapToDeleteFilterURL,
  queryTabs,
  queryTabsRecord,
};
