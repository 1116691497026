import UserFlow from "../../components/UserFlow/UserFlow";
import PricingTable from "../../components/Payment/PricingTable/PricingTable";
import PaymentGateway from "../../components/PaymentGateway/PaymentGateway";
import Invoice from "../../components/Invoice/Invoice";

export const userPathConstants = {
  NEW_HIRES: "newHires",
  FUNDINGS: "fundings",
  JOB_POSTINGS: "jobPostings",
  PRESS_RELEASES: "pressReleases",
  MY_LEADS: "myLeads",
  PRICING_TABLE: "pricingTable",
  PAYMENT: "payment",
  INVOICES: "invoices",
};

export const userFlowScreens = [
  {
    path: userPathConstants.NEW_HIRES,
    element: <UserFlow selectedItem={userPathConstants.NEW_HIRES} />,
  },
  {
    path: userPathConstants.FUNDINGS,
    element: <UserFlow selectedItem={userPathConstants.FUNDINGS} />,
  },
  {
    path: userPathConstants.JOB_POSTINGS,
    element: <UserFlow selectedItem={userPathConstants.JOB_POSTINGS} />,
  },
  {
    path: userPathConstants.PRESS_RELEASES,
    element: <UserFlow selectedItem={userPathConstants.PRESS_RELEASES} />,
  },
  {
    path: userPathConstants.MY_LEADS,
    element: <UserFlow selectedItem={userPathConstants.MY_LEADS} />,
  },
  {
    path: userPathConstants.PRICING_TABLE,
    element: <PricingTable />,
  },
  {
    path: userPathConstants.PAYMENT,
    element: <PaymentGateway />,
  },
  {
    path: userPathConstants.INVOICES,
    element: <Invoice />,
  },
];
