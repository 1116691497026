import AddEditHires from "../../components/AddEditHires/AddEditHires";
import AddEditNews from "../../components/AddEditNews/AddEditNews";
import AddEditProfile from "../../components/AddEditProfile/AddEditProfile";
import AddEditFunds from "../../components/AddEditFunds/AddEditFunds";
import AdminFlow from "../../components/AdminFlow/AdminFlow";
import DataEntryActivityLog from "../../components/AdminFlow/DataEntryActivityLog/DataEntryActivityLog";
import UserDataInfo from "../../components/AdminFlow/UserDataInfo/UserDataInfo";
import AddEditJobs from "../../components/AddEditJobs/AddEditJobs";
import Settings from '../../components/AdminFlow/Settings/Settings';

export const adminPath = "admin";

export const adminPathConstants = {
  NEW_HIRES: "newHires",
  ACTIVITY_LOG: "activityLog",
  PROFILES: "profiles",
  FUNDING: "funding",
  JOBS: "jobs",
  DASHBOARD: "dashboard",
  NEWS: "news",
  USER: "user",
  ADD_HIRES: "add-hires",
  EDIT_HIRES: "edit-hires",
  ADD_FUNDS: "add-funds",
  EDIT_FUNDS: "edit-funds",
  ADD_JOBS: "add-jobs",
  EDIT_JOBS: "edit-jobs",
  ADD_NEWS: "add-news",
  EDIT_NEWS: "edit-news",
  ADD_PROFILE: "add-profile",
  EDIT_PROFILE: "edit-profile",
  SETTINGS: "admin/settings"
};

export const adminRoutes = [
  {
    path: `/${adminPath}/${adminPathConstants.NEW_HIRES}`,
    element: <AdminFlow selectedItem={adminPathConstants.NEW_HIRES} />,
  },
  {
    path: `/${adminPath}/${adminPathConstants.ACTIVITY_LOG}`,
    element: <AdminFlow selectedItem={adminPathConstants.ACTIVITY_LOG} />,
  },
  {
    path: `/${adminPath}/${adminPathConstants.ACTIVITY_LOG}/:id`,
    element: <DataEntryActivityLog />,
  },
  {
    path: `/${adminPath}/${adminPathConstants.PROFILES}`,
    element: <AdminFlow selectedItem={adminPathConstants.PROFILES} />,
  },
  {
    path: `/${adminPath}/${adminPathConstants.FUNDING}`,
    element: <AdminFlow selectedItem={adminPathConstants.FUNDING} />,
  },
  {
    path: `/${adminPath}/${adminPathConstants.JOBS}`,
    element: <AdminFlow selectedItem={adminPathConstants.JOBS} />,
  },
  {
    path: `/${adminPath}/${adminPathConstants.DASHBOARD}`,
    element: <AdminFlow selectedItem="adminDashboard" />,
  },
  {
    path: `/${adminPath}/${adminPathConstants.NEWS}`,
    element: <AdminFlow selectedItem={adminPathConstants.NEWS} />,
  },
  {
    path: `${adminPathConstants.USER}`,
    element: <UserDataInfo />,
  },
  {
    path: `/${adminPath}/${adminPathConstants.NEW_HIRES}/${adminPathConstants.ADD_HIRES}`,
    element: <AddEditHires />,
  },
  {
    path: `/${adminPath}/${adminPathConstants.PROFILES}/${adminPathConstants.ADD_PROFILE}`,
    element: <AddEditProfile />,
  },
  {
    path: `/${adminPath}/${adminPathConstants.PROFILES}/${adminPathConstants.EDIT_PROFILE}/:id`,
    element: <AddEditProfile />,
  },
  {
    path: `/${adminPath}/${adminPathConstants.JOBS}/${adminPathConstants.ADD_JOBS}`,
    element: <AddEditJobs />,
  },
  {
    path: `/${adminPath}/${adminPathConstants.FUNDING}/${adminPathConstants.ADD_FUNDS}`,
    element: <AddEditFunds />,
  },
  {
    path: `/${adminPath}/${adminPathConstants.NEWS}/${adminPathConstants.ADD_NEWS}`,
    element: <AddEditNews />,
  },
  {
    path: `/${adminPath}/${adminPathConstants.JOBS}/${adminPathConstants.EDIT_JOBS}/:id`,
    element: <AddEditJobs />,
  },
  {
    path: `/${adminPath}/${adminPathConstants.NEW_HIRES}/${adminPathConstants.EDIT_HIRES}/:id`,
    element: <AddEditHires />,
  },
  {
    path: `/${adminPath}/${adminPathConstants.NEWS}/${adminPathConstants.EDIT_NEWS}/:id`,
    element: <AddEditNews />,
  },
  {
    path: `/${adminPath}/${adminPathConstants.FUNDING}/${adminPathConstants.EDIT_FUNDS}/:id`,
    element: <AddEditFunds />,
  },
  {
    path: `/${adminPathConstants.SETTINGS}`,
    element: <Settings />,
  },
  {
    path: `/${adminPathConstants.SETTINGS}/:path`,
    element: <Settings />,
  },
];
