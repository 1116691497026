import { Asset } from '../../../../store/slice/adminSlice/industrySlice';
import SettingsDatum, { SettingsOnChange } from './SettingsDatum';

interface SettingsAddItem extends SettingsOnChange {
  asset: Asset
}

export default function SettingsData({
  data = [],
  addItem,
}: {
  data?: Asset[],
  addItem: (asset: Asset) => void
}) {
  function onChange({check, value, change, oldValue, asset}: SettingsAddItem) {
    if (check === 'key') {
      addItem({
        ...asset,
        key: value,
        hasChanges: true,
      });
    } else if (check === 'group') {
      addItem({
        ...asset,
        group: value,
        hasChanges: true,
      });
    } else {
      if (change === 'add') {
        addItem({
          ...asset,
          value: [...asset.value, value],
          hasChanges: true,
        });
      } else if (change === 'update') {
        addItem({
          ...asset,
          value: asset.value.map(v => {
            if (v === oldValue) {
              return value;
            }
            return v;
          }),
          hasChanges: true,
        });
      } else if (change === 'delete') {
        addItem({
          ...asset,
          value: asset.value.filter(v => {
            if (v === value) {
              return false;
            }
            return true;
          }),
          hasChanges: true,
        });
      }
    }
  }

  return (
    <>
      {data.map((asset) => <SettingsDatum key={asset.id} datum={asset} onChange={props => onChange({asset, ...props})} />)}
    </>
  );
}
