import * as Yup from "yup";
import { companyValidationSchema } from "./company-validation-schema";
import { nameValidationSchema } from "./name-validation-schema";

export const newHiresValidationSchema = Yup.object()
  .shape({
    position: Yup.string()
      .required("Position is required")
      .min(2, "Position must have at least 2 characters")
      .max(40, "Position must be under 40 characters")
      .trim(),
  })
  .concat(companyValidationSchema)
  .concat(nameValidationSchema);
