import { ReactNode, useContext } from "react";
import Menu from "../CommonAppComponents/Menu/Menu";
import { useAppSelector } from "../../store";
import { Outlet } from "react-router-dom";
import CenterLoader from "../Loaders/CenterLoader";
import LoadingContext from "../../contexts/APIContext/Loading/LoadingContext";

export default function PageLayout({ children }: { children?: ReactNode }) {
  const isAuthenticated = useAppSelector(
    (state) => state.signin.isAuthenticated
  );

  const { isLoading } = useContext(LoadingContext);

  return (
    <div className="flex h-[calc(100vh-105px)] overflow-auto">
      {isAuthenticated && <Menu />}
      <div className="w-full h-full overflow-auto px-[20px]">
        {isLoading && <CenterLoader />}
        {children ?? <Outlet />}
      </div>
    </div>
  );
}
