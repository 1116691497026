import { useParams } from "react-router-dom";
import HeaderComponent from "../CommonAppComponents/HeaderComponent/HeaderComponent";
import HireForm from "./HireForm";

export default function AddEditHires() {
  const { id } = useParams();
  const primaryText = id ? "Edit Executive Hires" : "Add Executive Hires";
  const secondaryText = id ? "Edit Hires" : "Add New Hires";
  return (
    <>
      <HeaderComponent
        primaryText={primaryText}
        secondaryText={secondaryText}
      />
      <HireForm />
    </>
  );
}
