// authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SignInFormValues } from "../../components/AuthComponent/Signin";
import { RESPONSE_STATUS, TOKEN } from "../../enums/enums";
import { ContactUsValues, userResponseInterface } from "../../interfaces/interfaces";
import { SignUpInterface } from "../../components/AuthComponent/helper/SignUp.interface";
import { errorHandler } from "./helper/errorHandler";
import { ForgotPasswordValues } from "../../components/AuthComponent/ForgotPassword";
import { axiosInstance } from "../../secutityUtils/axiosInstance";

const fetchUrl = import.meta.env.VITE_DEV_USER_URL;

const initialState: userResponseInterface = {
  user: null,
  role: "",
  status: RESPONSE_STATUS.IDLE,
  signstatus: RESPONSE_STATUS.IDLE,
  token: localStorage.getItem("jwtToken") || "",
  created: false,
  isAuthenticated: !!localStorage.getItem("jwtToken"),
  verificationMessage: {
    flag: false,
    message: "",
  },
  error: "",
};

export const signupUser = createAsyncThunk(
  "signin/signupUser",
  async (
    { user, selectedPlan }: { user: SignUpInterface; selectedPlan: string },
    { rejectWithValue }
  ) => {
    const response = await axiosInstance
      .post(
        `${fetchUrl}/api/users/createAccount?selectedPlan=${selectedPlan}`,
        user
      )
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });
    return response.data;
  }
);

export const loginUser = createAsyncThunk(
  "signin/loginUser",
  async (credentials: SignInFormValues, { rejectWithValue }) => {
    const response = await axiosInstance
      .post(`${fetchUrl}/api/users/login`, credentials)
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });
    return response.data;
  }
);

export const resendVerifyLink = createAsyncThunk(
  "signin/resendVerifyLink",
  async (email: { email: string }, { rejectWithValue }) => {
    const response = await axiosInstance
      .post(`${fetchUrl}/api/users/verify-link`, email)
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });
    return response.data;
  }
);

export const reactivateUser = createAsyncThunk(
  "signin/reactivateUser",
  async (
    credentials: { email: string; password: string },
    { rejectWithValue }
  ) => {
    const response = await axiosInstance
      .post(`${fetchUrl}/api/users/reactivate-user`, credentials)
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });
    return response.data;
  }
);

export const resetPassword = createAsyncThunk(
  "signin/resetPassword",
  async (
    credentials: { password: string; token: string },
    { rejectWithValue }
  ) => {
    const response = await axiosInstance
      .post(`${fetchUrl}/api/users/reset-password`, credentials)
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });
    return response.data;
  }
);
export const forgotPassword = createAsyncThunk(
  "signin/forgotPassword",
  async (credentials: ForgotPasswordValues, { rejectWithValue }) => {
    const response = await axiosInstance
      .post(`${fetchUrl}/api/users/forgot-password`, credentials)
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });
    return response.data;
  }
);

export const OAuth = createAsyncThunk("signin/OAuth", async (email: string) => {
  let Email = { email: decodeURIComponent(email) };
  const response = await axiosInstance.post(
    `${import.meta.env.REACT_APP_DEV_URL}/api/users/oauth`,
    Email
  );
  return response.data;
});

export const fetchUserDetails = createAsyncThunk(
  "signin/FetchUser",
  async (tokenVal: string, { rejectWithValue }) => {
    const response = await axiosInstance
      .post(`${fetchUrl}/api/users/getdata`, {
        token: tokenVal,
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

export const verifyUser = createAsyncThunk(
  "signin/verifyUser",
  async (tokenVal: string, { rejectWithValue }) => {
    const response = await axiosInstance
      .post(`${fetchUrl}/api/users/verify-user`, {
        token: tokenVal,
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);
export const createCheckoutSession = createAsyncThunk(
  "signin/payment",
  async (_, { rejectWithValue }) => {
    const priceId = import.meta.env.VITE_STRIPE_PRICE_ID;
    const response = await axiosInstance
      .post(`${fetchUrl}/create-checkout-session`, {
        priceId,
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

export const contactForm = createAsyncThunk(
  "signin/contactForm",
  async (
    { data, type }: { data: ContactUsValues; type: string },
    { rejectWithValue }
  ) => {
    const response = await axiosInstance
      .post(`${fetchUrl}/api/users/contact-us?type=${type}`, data)
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

function resetState(state: userResponseInterface) {
  state.user = null;
  state.token = "";
  state.isAuthenticated = false;
  state.status = RESPONSE_STATUS.IDLE;
  state.signstatus = RESPONSE_STATUS.IDLE;
  localStorage.clear();
  state.error = "";
}

export const signinSlice = createSlice({
  name: "signin",
  initialState,
  reducers: {
    logoutUser: resetState,
    resetErrors: (state) => {
      state.error = "";
    },
    setVerificationMessage: (state, action) => {
      state.verificationMessage = { ...action.payload };
    },
    setStatusIdle: (state, action) => {
      state.status = action.payload;
      const payload = "";
      errorHandler<userResponseInterface>(state, payload);
    },
    setSignInStatusIdle: (state, action) => {
      state.signstatus = action.payload;
      const payload = "";
      errorHandler<userResponseInterface>(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signupUser.pending, (state) => {
        state.signstatus = RESPONSE_STATUS.LOADING;
      })
      .addCase(signupUser.fulfilled, (state, action) => {
        state.signstatus = RESPONSE_STATUS.SUCCEEDED;
        state.created = action.payload.success;
      })
      .addCase(signupUser.rejected, (state, action) => {
        state.signstatus = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;

        errorHandler<userResponseInterface>(state, payload);
      })
      .addCase(OAuth.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(OAuth.fulfilled, (state, action) => {
        state.verificationMessage = {
          message: "",
          flag: false,
        };
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.user = action.payload;
        // state.userId = action.payload.user.id;
        state.token = action.payload.token;
        localStorage.removeItem(TOKEN.JWT_TOKEN);
        localStorage.setItem(TOKEN.JWT_TOKEN, action.payload.token);

        state.role = action.payload?.user.role;
        state.isAuthenticated = true;
      })
      .addCase(loginUser.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.verificationMessage = {
          message: "",
          flag: false,
        };
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.user = action.payload.user;
        state.token = action.payload.token;
        localStorage.setItem(TOKEN.JWT_TOKEN, action.payload.token);
        localStorage.setItem(TOKEN.REFRESH_TOKEN, action.payload.refreshToken);
        state.isAuthenticated = true;
        state.role = action.payload?.user?.role;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<userResponseInterface>(state, payload);
      })
      .addCase(createCheckoutSession.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(createCheckoutSession.fulfilled, (state, action) => {
        state.checkoutURL = action.payload;
      })
      .addCase(createCheckoutSession.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<userResponseInterface>(state, payload);
      })
      .addCase(resendVerifyLink.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(resendVerifyLink.fulfilled, (state, action) => {
        state.verificationMessage = {
          message: action.payload.message,
          flag: true,
        };

        state.status = RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(resendVerifyLink.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<userResponseInterface>(state, payload);
      })
      .addCase(reactivateUser.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(reactivateUser.fulfilled, (state, action) => {
        state.verificationMessage = {
          message: action.payload,
          flag: true,
        };

        state.status = RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(reactivateUser.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<userResponseInterface>(state, payload);
      })
      .addCase(verifyUser.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(verifyUser.fulfilled, (state, action) => {
        state.verificationMessage = {
          message: action.payload.message,
          flag: true,
        };

        state.status = RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(verifyUser.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<userResponseInterface>(state, payload);
      })
      .addCase(forgotPassword.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;

        errorHandler<userResponseInterface>(state, payload);
      })
      .addCase(resetPassword.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;

        errorHandler<userResponseInterface>(state, payload);
      })
      .addCase(fetchUserDetails.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.user = action.payload;
        state.role = action.payload.role;
        state.isAuthenticated = true;
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        const payload = action.payload as { message: string } | string;

        errorHandler<userResponseInterface>(state, payload);
      })
      .addCase(contactForm.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(contactForm.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(contactForm.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        const payload = action.payload as { message: string } | string;

        errorHandler<userResponseInterface>(state, payload);
      });
  },
});

export const {
  logoutUser,
  setVerificationMessage,
  setStatusIdle,
  setSignInStatusIdle,
  resetErrors,
} = signinSlice.actions;
export default signinSlice.reducer;
