import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, IconButton, List, ListItem, ListItemButton } from '@mui/material';
import { useState } from 'react';
import DeleteIcon from "@mui/icons-material/Delete";
import { Asset } from '../../../../store/slice/adminSlice/industrySlice';
import SettingsInput from './SettingsInput';

export interface SettingsOnChange {check: 'key' | 'value' | 'group'; value: string; change?: 'update' | 'add' | 'delete'; oldValue?: string;}

export default function SettingsDatum({
  datum,
  onChange
}: {
  datum: Asset,
  onChange: (props: SettingsOnChange) => void
}) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List>
      <ListItem
        dense
        sx={{
          position: 'sticky',
          top: 0,
          bgcolor: '#fff',
          zIndex: 10
        }}>
        <div className='flex w-100 gap-1'>
          <SettingsInput existingValues={[]} value={datum.key} onChange={value => onChange({check: 'key', value})} />
          {datum.group && (
            <SettingsInput existingValues={[]} value={datum.group} label="Group" onChange={value => onChange({check: 'group', value})} />
          )}
          <ListItemButton onClick={handleClick}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </div>
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {datum.value.map((s) => (
            <ListItem
              key={s}
              sx={{ pl: 4 }}
              secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => onChange({check: 'value', value: s, change: 'delete'})}>
                  <DeleteIcon />
                </IconButton>
              }>
              <SettingsInput existingValues={datum.value} value={s} onChange={value => onChange({check: 'value', value, change: 'update', oldValue: s})} />
            </ListItem>
          ))}
          <ListItem sx={{ pl: 4 }}>
            <SettingsInput existingValues={datum.value} willClear value="" onChange={value => onChange({check: 'value', value, change: 'add'})} />
          </ListItem>
        </List>
      </Collapse>
    </List>
  );
}
