import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RESPONSE_STATUS, USER_ROLE } from "../../enums/enums";
import ButtonCustom from "../../features/ButtonCustom/ButtonCustom";
import { NotificationContext } from "../../contexts/APIContext/Notification/NotificationContext";
import { newsFormValues } from "../../interfaces/interfaces";
import { newsFields, pageName } from "./helper/fieldsData";
import {
  editAdminNews,
  postAdminNews,
  resetErrors as resetAdminErrors,
} from "../../store/slice/adminSlice/adminNewsSlice";
import {
  editNews,
  postNews,
  resetErrors as resetDataErrors,
} from "../../store/slice/newsSlice";
import { newsValidationSchema } from "../../validators/news-validation-schema";
import MuiFormInput from "../FormComponents/MuiFormInput";
import { adminPath, adminPathConstants } from "../../Routes/helper/adminRoutes";
import {
  DATA_ENTRY,
  dataPathConstants,
} from "../../Routes/helper/dataEntryRoutes";
import CircularLoader from "../Loaders/CircularLoader";
import CancelButton from "../CancelButton";

export default function NewsForm() {
  const adminEntries = useAppSelector((state) => state.adminNews.entries);
  const dataNewsEntries = useAppSelector((state) => state.news.entries);
  const userRole = useAppSelector((state) => state.signin.role);
  const news = useAppSelector((state) =>
    userRole === USER_ROLE.ADMIN ? state.adminNews : state.news
  );

  const { showNotification } = useContext(NotificationContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [dispatchStatus, setDispatchStatus] = useState(false);

  const schema = newsValidationSchema(userRole === USER_ROLE.ADMIN);
  const form = useForm<newsFormValues>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    let result;
    if (userRole === USER_ROLE.ADMIN) {
      result = adminEntries?.find((entry) => entry.id === id);
    } else {
      result = dataNewsEntries?.find((entry) => entry.id === id);
    }
    if (result) {
      form.reset(result);
    }
  }, [id, adminEntries, dataNewsEntries, form, userRole]);

  useEffect(() => {
    if (id) {
      if (userRole === USER_ROLE.ADMIN && !adminEntries.length) {
        navigate(
          `/${adminPath}/${adminPathConstants.NEWS}?page=1&count=25&tab=live`
        );
      } else if (
        userRole === USER_ROLE.DATA_ENTRY_OPERATOR &&
        !dataNewsEntries.length
      ) {
        navigate(
          `/${DATA_ENTRY}/${dataPathConstants.BUSINESS_NEWS}?page=1&count=25`
        );
      }
    }
  }, [id, dataNewsEntries, adminEntries, userRole, navigate]);

  useEffect(() => {
    if (dispatchStatus && news.status === RESPONSE_STATUS.SUCCEEDED) {
      showNotification(
        "success",
        `Business news ${id ? "updated" : "added"} successfully`
      );
      if (userRole === USER_ROLE.ADMIN) {
        navigate(
          `/${adminPath}/${adminPathConstants.NEWS}?page=1&count=25&tab=live`
        );
      } else {
        navigate(
          `/${DATA_ENTRY}/${dataPathConstants.BUSINESS_NEWS}?page=1&count=25`
        );
      }
    }
    if (news.error && typeof news.error === "string") {
      showNotification("error", news.error);
      dispatch(
        userRole === USER_ROLE.ADMIN ? resetAdminErrors() : resetDataErrors()
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [news, dispatchStatus, id, navigate, userRole]);

  const handleSubmit: SubmitHandler<newsFormValues> = async (formData) => {
    if (userRole === USER_ROLE.ADMIN) {
      if (id) {
        dispatch(editAdminNews({ id, newData: formData }));
      } else {
        dispatch(postAdminNews({ formData }));
      }
    } else {
      if (id) {
        dispatch(editNews({ pageName, id, newData: formData }));
      } else {
        dispatch(postNews({ formData }));
      }
    }
    setDispatchStatus(true);
  };

  return (
    <FormProvider {...form}>
      <form
        className="form-box"
        onSubmit={form.handleSubmit(handleSubmit)}
        onChange={() =>
          dispatch(
            userRole === USER_ROLE.ADMIN
              ? resetAdminErrors()
              : resetDataErrors()
          )
        }
      >
        <div className="form-row">
          {newsFields.map((newsField, index) => {
            if (
              newsField.name === "categories" &&
              userRole === USER_ROLE.DATA_ENTRY_OPERATOR
            ) {
              return <></>;
            }
            return (
              <MuiFormInput
                key={index}
                {...newsField}
                errorMessage={
                  typeof news.error !== "string"
                    ? news.error[newsField.name]
                    : ""
                }
              />
            );
          })}
        </div>
        <div className="form-button">
          <ButtonCustom
            type="submit"
            endIcon={
              news.status === RESPONSE_STATUS.LOADING && (
                <CircularLoader size={20} />
              )
            }
            disabled={news.status === RESPONSE_STATUS.LOADING }
          >
            Save Details
          </ButtonCustom>
          <CancelButton />
        </div>
      </form>
    </FormProvider>
  );
}
