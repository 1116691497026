import { useParams } from "react-router-dom";
import HeaderComponent from "../CommonAppComponents/HeaderComponent/HeaderComponent";
import JobForm from "./JobForm";

export default function AddEditJobs() {
  const { id } = useParams();
  const primaryText = id ? "Edit Jobs" : "Add Jobs";
  const secondaryText = id ? "Edit Jobs" : "Add New Jobs";
  return (
    <>
      <HeaderComponent
        primaryText={primaryText}
        secondaryText={secondaryText}
      />
      <JobForm />
    </>
  );
}
