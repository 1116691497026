import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { Asset } from '../../../store/slice/adminSlice/industrySlice';
import SettingsData from './SettingsData/SettingsData';
import { fetchJobTitles, updateJobTitles, updateJobTitlesLocal } from '../../../store/slice/adminSlice/jobTitlesSlice';
import { RESPONSE_STATUS } from '../../../enums/enums';
import CircularLoader from '../../Loaders/CircularLoader';
import { Button } from '@mui/material';
import NotificationImportant from '@mui/icons-material/NotificationImportant';

export default function JobTitles() {
  const dispatch = useAppDispatch();
  const jobTitles = useAppSelector((state) => state.jobTitles.entries);
  const hasChanges = useAppSelector((state) => state.jobTitles.hasChanges);
  const isSaving = useAppSelector((state) => state.jobTitles.addStatus === RESPONSE_STATUS.LOADING);

  useEffect(() => {
    dispatch(fetchJobTitles());
  }, [dispatch]);

  async function addItem(industry: Asset) {
    dispatch(updateJobTitlesLocal(industry));
  }

  async function saveItems() {
    const updates: Asset[] = [];
    jobTitles.forEach(entries => {
      const {hasChanges, ...entry} = entries;
      if (hasChanges) {
        updates.push(entry)
      }
    })
    dispatch(updateJobTitles(updates));
  }

  return (
    <div className="w-50 mb-4">
      <SettingsData data={jobTitles} addItem={addItem} />

      {hasChanges &&
        <div className='flex align-items-center gap-4 w-100 z-[99] bg-body-secondary position-sticky bottom-[0] p-4'>
          <Button variant="contained" onClick={saveItems}>
            Save
          </Button>
          <p className='d-flex m-0'>
            {isSaving ? <CircularLoader size={24} /> : <NotificationImportant />}
            {isSaving ? "Saving your changes" : "Please save your changes"}
          </p>
        </div>
      }
    </div>
  );
}
