import { createContext } from "react";
import { NotificationContextData } from "../../../interfaces/interfaces";

export const NotificationContext = createContext<NotificationContextData>({
  showNotification: function (): void {
    throw new Error("Function not implemented.");
  },
  hideNotification: function (): void {
    throw new Error("Function not implemented.");
  },
});
