import "./MyLeadsComponent.scss";
import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import CustomTabPanel from "./TabComponent/CustomTabPanel";
import { setLeadsFlag } from "../../../store/slice/userFlowSlice";
import AccordionComponent from "./AccordionComponent/AccordionComponent";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useSearchParams } from "react-router-dom";
import { queryTabs } from "../../../constants/userFlowConstants";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabs = ["New Hires", "Funding", "Job Postings", "Company Press Releases"];

function MyLeadsComponent() {
  const leadsFlag = useAppSelector((state) => state.userflow.leadsFlag);
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const backToLeadsPage = () => {
    dispatch(setLeadsFlag(!leadsFlag));
  };
  const index = queryTabs.findIndex((tab) => searchParams.get("tab") === tab);
  const [value, setValue] = useState(index);

  const handleChange = (
    _: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setValue(newValue);
    setSearchParams({ tab: queryTabs[newValue] });
  };

  useEffect(() => {
    setValue(index);
  }, [index]);

  return (
    <div className="myLeads-container md:p-3">
      {!leadsFlag ? (
        <div className="p-1 md:p-4 w-full">
          <button
            onClick={backToLeadsPage}
            className="backToLeads mb-2 shadow-md"
          >
            <ArrowBackIosIcon />
            Back
          </button>
          <AccordionComponent />
        </div>
      ) : (
        <>
          <div>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={false}
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                overflow: "scroll",
              }}
            >
              {tabs.map((tab, index) => {
                return <Tab label={tab} {...a11yProps(value)} key={index} />;
              })}
            </Tabs>
            <CustomTabPanel value={value} key={index} index={index} />;
            {/* {[...Array(4).keys()].map((_, index) => {
              return <CustomTabPanel value={value} key={index} index={index} />;
            })} */}
          </div>
        </>
      )}
    </div>
  );
}

export default MyLeadsComponent;
