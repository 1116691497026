import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sendToFilterServicePercolate } from "../../../services/filterService";
import {
  jobsDataInterface,
  jobsFormValuesInterface,
} from "../../../interfaces/interfaces";
import { RESPONSE_STATUS } from "../../../enums/enums";
import { errorHandler } from "../helper/errorHandler";
import { axiosInstance } from "../../../secutityUtils/axiosInstance";
import { GridRowId } from "@mui/x-data-grid";

const fetchUrl = `${import.meta.env.VITE_DEV_ADMIN_URL}/api/admin/dataentry`;
const draftURL = `${import.meta.env.VITE_DEV_ADMIN_URL}/api/admin/draft`;

type InitialState = {
  entries: jobsDataInterface[];
  draftEntries: jobsDataInterface[];
  status: RESPONSE_STATUS;
  error: string | Record<string, string>;
  maxRecords?: number;
  selectedIds?: string[];
  publishResponse?: {
    invalidDraftIds: Array<GridRowId>;
    validDraftIds: Array<GridRowId>;
    validDrafts: number;
    invalidDrafts: number;
  };
};

// Define an initial state
const initialState: InitialState = {
  entries: [],
  draftEntries: [],
  status: RESPONSE_STATUS.IDLE, // Can be 'idle', 'loading', 'succeeded', or 'failed'
  error: "",
};

const pageName = "JOBS";

export const fetchAdminJobs = createAsyncThunk(
  "adminJob/fetchAdminJobs",
  async (
    {
      page,
      count = 25,
      filters,
    }: {
      page: number;
      count: number;
      filters?: { key: string; value: string }[];
    },
    { rejectWithValue }
  ) => {
    try {
      let filtersQueries = "";
      filters?.forEach((item) => {
        filtersQueries += `&${item.key}=${item.value}`;
      });
      // Make the GET request with headers
      const response = await axiosInstance
        .get(
          `${fetchUrl}/myEntries/jobs?page=${page}&count=${count}${filtersQueries}`
        )
        .catch((err) => {
          throw rejectWithValue(err.response.data);
        });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const postAdminJobs = createAsyncThunk(
  "adminJob/postAdminJobs",
  async (
    {
      formData,
    }: {
      formData: jobsFormValuesInterface;
    },
    { rejectWithValue }
  ) => {
    // Make the POST request with headers and new hires data
    const response = await axiosInstance
      .post(`${fetchUrl}/${pageName}`, formData)
      .then(async (res) => {
        const { id, ...restOfData } = res.data;
        await sendToFilterServicePercolate({
          ...restOfData,
          page: "JobPostings",
        });
        return res.data;
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

export const fetchDraftAdminJobs = createAsyncThunk(
  "adminJob/fetchDraftAdminFunds",
  async (
    {
      page,
      count = 25,
    }: {
      page: number;
      count?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      // Make the GET request with headers
      const response = await axiosInstance
        .get(`${draftURL}/jobs?page=${page}&count=${count}`)
        .catch((err) => {
          throw rejectWithValue(err.response.data);
        });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const publishDraftJobs = createAsyncThunk(
  "adminJob/publishDraftFunds",
  async (
    {
      ids,
      all = false,
    }: {
      ids: string[];
      all?: boolean;
    },
    { rejectWithValue }
  ) => {
    // Make the POST request with headers and new hires data
    const response = await axiosInstance
      .post(`${draftURL}/draftToLive/jobs?all=${all}`, ids)
      .then(async (res) => {
        return res.data;
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const deleteAdminJobs = createAsyncThunk(
  "adminJob/deleteAdminJobs",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    // assuming you are identifying the job to be deleted by "jobId"

    // Make the DELETE request with headers
    await axiosInstance.delete(`${fetchUrl}/${pageName}/${id}`).catch((err) => {
      throw rejectWithValue(err.response.data);
    });

    return id; // return deleted jobId to remove it from the state
  }
);

export const deleteDraftJobs = createAsyncThunk(
  "adminJob/deleteDraftJobs",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    // assuming you are identifying the job to be deleted by "jobId"

    // Make the DELETE request with headers
    await axiosInstance.delete(`${draftURL}/jobs/${id}`).catch((err) => {
      throw rejectWithValue(err.response.data);
    });

    return id; // return deleted jobId to remove it from the state
  }
);

// Create an asynchronous thunk action to edit existing data
export const editAdminJobs = createAsyncThunk(
  "adminJob/editAdminJobs",
  async (
    {
      id,
      newData,
    }: {
      id: string;
      newData: jobsFormValuesInterface;
    },
    { rejectWithValue }
  ) => {
    const data = { ...newData };
    delete data.createdDate;
    delete data.lastModifiedDate;

    const response = await axiosInstance
      .put(`${fetchUrl}/${pageName}/${id}`, data)
      .then(async (res) => {
        const { id, ...restOfData } = res.data;
        await sendToFilterServicePercolate({
          ...restOfData,
          page: "JobPostings",
        });
        return res.data;
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

export const editDraftJobs = createAsyncThunk(
  "adminJob/editDraftJobs",
  async (
    {
      id,
      formData,
    }: {
      id: string;
      formData: jobsDataInterface;
    },
    { rejectWithValue }
  ) => {
    const response = await axiosInstance
      .put(`${draftURL}/jobs/${id}`, formData)
      .then(async (res) => {
        return res.data;
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

export const adminJobSlice = createSlice({
  name: "adminJob",
  initialState,
  reducers: {
    fetchJobEntries: (state) => {
      state.entries = [];
      state.status = RESPONSE_STATUS.IDLE;
      state.error = "";
    },
    resetErrors: (state) => {
      state.error = "";
    },
    setSelectedIds: (state, action) => {
      state.selectedIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminJobs.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchAdminJobs.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.entries = action.payload.data;
        state.maxRecords = action.payload.totalElements;
      })
      .addCase(fetchAdminJobs.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(fetchDraftAdminJobs.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchDraftAdminJobs.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.draftEntries = action.payload.data;
        state.maxRecords = action.payload.totalElements;
      })
      .addCase(fetchDraftAdminJobs.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(postAdminJobs.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(postAdminJobs.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(postAdminJobs.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(publishDraftJobs.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(publishDraftJobs.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.publishResponse = action.payload;
      })
      .addCase(publishDraftJobs.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(deleteAdminJobs.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(deleteAdminJobs.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.entries = state.entries.filter(
          (job) => job.id !== action.payload
        );
      })
      .addCase(deleteAdminJobs.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(deleteDraftJobs.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(deleteDraftJobs.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.entries = state.entries.filter(
          (job) => job.id !== action.payload
        );
      })
      .addCase(deleteDraftJobs.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(editAdminJobs.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(editAdminJobs.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(editAdminJobs.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(editDraftJobs.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(editDraftJobs.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(editDraftJobs.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      });
  },
});

// Export actions and reducer
export const { fetchJobEntries, resetErrors, setSelectedIds } =
  adminJobSlice.actions;
export default adminJobSlice.reducer;
