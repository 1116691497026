import * as Yup from "yup";
import { regex } from "../regex/regex";
import { transformToNull } from "./helper/transformToNull";

export const companyValidationSchema = Yup.object().shape({
  companyName: Yup.string()
    .required("Company Name is required")
    .min(2, "Company Name must have at least 2 characters")
    .max(40, "Company Name must be under 40 characters")
    .trim(),
  companyCity: Yup.string()
    .required("Company City is required")
    .min(2, "Company City must have at least 2 characters")
    .max(40, "Company City must be under 40 characters")
    .trim(),
  companyHQ: Yup.string()
    .required("Company Headquarter is required")
    .min(2, "Company Headquarter must have at least 2 characters")
    .max(40, "Company Headquarter must be under 40 characters")
    .trim(),
  industry: Yup.string()
    .required("Industry is required")
    .min(2, "Industry must have at least 2 characters")
    .max(40, "Industry must be under 40 characters")
    .trim(),
  companyEmployeeCount: Yup.number()
    .transform(transformToNull)
    .min(1, "Employee Count must be greater than 0")
    .max(
      Number.MAX_SAFE_INTEGER,
      `Employee count must be less than ${Number.MAX_SAFE_INTEGER}`
    )
    .required("Employee Count  is required"),
  companyLinkedIn: Yup.string()
    .required("Company LinkedIn is required")
    .matches(regex.linkedinUrlFormat, "Please enter a valid LinkedIn URL!")
    .trim(),
  companyWebsite: Yup.string()
    .required("Company Website is required")
    .matches(regex.urlFormat, "Please enter a valid URL!")
    .trim(),
});
