import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import FormInput from "../FormComponents/FormInput";
import TextAreaInput from "../FormComponents/TextAreaInput";
import ButtonCustom from "../../features/ButtonCustom/ButtonCustom";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import { contactUsValidationSchema } from "../../validators/contactUs-validation-schema";
import { yupResolver } from "@hookform/resolvers/yup";
import Close from "@mui/icons-material/Close";
import "./modal.scss";
import { useAppDispatch, useAppSelector } from "../../store";
import useNotification from "../../hooks/useNotification";
import { useEffect, useState } from "react";
import { RESPONSE_STATUS } from "../../enums/enums";
import { contactForm, resetErrors } from "../../store/slice/signinSlice";
import CircularLoader from "../Loaders/CircularLoader";

type ContactUsValues = {
  firstName: string;
  email: string;
  subject: string;
  message: string;
};

export default function EnterpriseFormModal({
  onClose,
}: {
  onClose: () => void;
}) {
  const user = useAppSelector((state) => state.signin.user);
  const enterpriseValues = useAppSelector((state) => state.signin);
  const form = useForm<ContactUsValues>({
    resolver: yupResolver(contactUsValidationSchema),
  });
  const dispatch = useAppDispatch();
  const { showNotification } = useNotification();

  const [dispatchStatus, setDispatchStatus] = useState(false);

  useEffect(() => {
    if (
      dispatchStatus &&
      enterpriseValues.status === RESPONSE_STATUS.SUCCEEDED
    ) {
      form.reset();
      showNotification("success", `Mail sent successfully`);
      onClose();
    }
    if (enterpriseValues.error && typeof enterpriseValues.error === "string") {
      showNotification("error", enterpriseValues.error);
      dispatch(resetErrors());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterpriseValues, dispatchStatus, form]);

  const handleEnterprise: SubmitHandler<ContactUsValues> = (data) => {
    dispatch(contactForm({ data, type: "enterprise" }));
    setDispatchStatus(true);
  };
  return (
    <div className="modal-overlay">
      <div className="modal-content-box content-modal">
        <div className="text-center">
          <h3 className="mb-6">Enterprise Subscription Form</h3>
        </div>
        <button className="absolute right-3 top-3" onClick={onClose}>
          <Close />
        </button>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(handleEnterprise)}
            onChange={() => dispatch(resetErrors())}
          >
            <div className="grid md:grid-cols-2 gap-3 mb-3">
              <FormInput
                name="firstName"
                placeholder="Enter First Name"
                label="First Name"
                autoComplete="firstName"
                defaultValue={user?.firstName}
                errorMessage={
                  typeof enterpriseValues.error !== "string"
                    ? enterpriseValues.error["firstName"]
                    : ""
                }
              />
              <FormInput
                name="lastName"
                placeholder="Enter Last Name"
                label="Last Name"
                autoComplete="lastName"
                defaultValue={user?.lastName}
                errorMessage={
                  typeof enterpriseValues.error !== "string"
                    ? enterpriseValues.error["lastName"]
                    : ""
                }
              />

              <FormInput
                name="email"
                placeholder="Enter Email"
                label="Email"
                autoComplete="email"
                defaultValue={user?.email}
                errorMessage={
                  typeof enterpriseValues.error !== "string"
                    ? enterpriseValues.error["email"]
                    : ""
                }
              />
              <FormInput
                name="phoneNumber"
                placeholder="Enter Phone Number"
                label="Phone Number"
                autoComplete="phoneNumber"
                defaultValue={user?.phoneNumber}
                errorMessage={
                  typeof enterpriseValues.error !== "string"
                    ? enterpriseValues.error["phoneNumber"]
                    : ""
                }
              />
            </div>

            <FormInput
              name="subject"
              placeholder="Enter Subject"
              label="Subject"
              autoComplete="subject"
              defaultValue={"Enterprise Subscription Inquiry"}
              errorMessage={
                typeof enterpriseValues.error !== "string"
                  ? enterpriseValues.error["subject"]
                  : ""
              }
            />
            <div className="mt-3">
              <TextAreaInput
                placeholder="Type your message..."
                label="How can we help you?"
                name="message"
                errorMessage={
                  typeof enterpriseValues.error !== "string"
                    ? enterpriseValues.error["message"]
                    : ""
                }
              />
            </div>
            <div className="bottom_buttons">
              <ButtonCustom
                type="submit"
                className="button_submit"
                endIcon={
                  !enterpriseValues.error &&
                  enterpriseValues.status === RESPONSE_STATUS.LOADING ? (
                    <CircularLoader size={20} />
                  ) : (
                    <ArrowRightIcon />
                  )
                }
                disabled={enterpriseValues.status === RESPONSE_STATUS.LOADING}
              >
                Submit
              </ButtonCustom>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
