import { Link } from "react-router-dom";
import ButtonCustom from "../../features/ButtonCustom/ButtonCustom";
import { userPathConstants } from "../../Routes/helper/userRoutes";
import "./PaymentSuccessModal.scss";

export default function PaymentSuccessModal({
  onClose,
}: {
  onClose: () => void;
}) {
  return (
    <div className="modal-overlay">
      <div className="modal-content-box shadow-2xl">
        <button className="modal-close" onClick={onClose}>
          X
        </button>
        <div className="my-2 flex justify-center items-center flex-col gap-2 text-center">
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
          <h3 className="text-teal-500">Payment Successful</h3>
          <p>You can now access more features.</p>
          <ButtonCustom>
            <Link
              className="nav-link no-underline"
              to={`/${userPathConstants.MY_LEADS}?tab=hires`}
            >
              Go to Dashboard
            </Link>
          </ButtonCustom>
        </div>
      </div>
    </div>
  );
}
