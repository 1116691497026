import { ReactNode, useState } from "react";
import Notification from "../../../components/CommonAppComponents/NotificationComponent/NotificationComponent"; // path to your Notification component
import { NotificationContext } from "./NotificationContext";
import { NotificationContextData } from "../../../interfaces/interfaces";
import { AlertColor } from "@mui/material";

function NotificationProvider({ children }: { children: ReactNode }) {
  const [notificationState, setNotificationState] = useState<{
    open: boolean;
    severity: AlertColor;
    message: string;
    duration: number;
    action?: ReactNode;
  }>({
    open: false,
    severity: "info",
    message: "",
    duration: 6000,
  });

  const showNotification = (
    severity: AlertColor,
    message: string,
    duration?: number,
    action?: ReactNode
  ) => {
    setNotificationState({
      open: true,
      severity,
      message,
      duration: duration || 6000,
      action,
    });
  };

  const hideNotification = () => {
    setNotificationState((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const valueObj: NotificationContextData = {
    showNotification,
    hideNotification,
  };

  return (
    <NotificationContext.Provider value={valueObj}>
      {children}
      <Notification handleClose={hideNotification} {...notificationState} />
    </NotificationContext.Provider>
  );
}

export default NotificationProvider;
