import "./App.scss";
import "./assets/styles/themes.scss";
import "./assets/styles/common.scss";
import Routes from "./Routes/Routes";
import { sentryInit } from "./utils/sentry.init";
import { BrowserRouter } from "react-router-dom";

export default function App() {
  sentryInit();
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
}
