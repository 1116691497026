import MainContent from "./MainContent/MainContent";

function LandingPage() {
  return (
    <div>
      <MainContent />
    </div>
  );
}

export default LandingPage;
