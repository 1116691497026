import { useEffect, useState } from "react";
import {
  editAdminFunds,
  postAdminFunds,
  resetErrors as resetAdminErrors,
} from "../../store/slice/adminSlice/adminFundingSlice";
import {
  editFunds,
  postNewFunds,
  resetErrors as resetDataErrors,
} from "../../store/slice/fundingSlice";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { RESPONSE_STATUS, USER_ROLE } from "../../enums/enums";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import ButtonCustom from "../../features/ButtonCustom/ButtonCustom";
import MuiFormInput from "../FormComponents/MuiFormInput";
import { fundsFormValuesInterface } from "../../interfaces/interfaces";
import { fundsValidationSchema } from "../../validators/funds-validation-schema";
import { fundsFields, pageName } from "./helper/fundsFields";
import { adminPath, adminPathConstants } from "../../Routes/helper/adminRoutes";
import {
  DATA_ENTRY,
  dataPathConstants,
} from "../../Routes/helper/dataEntryRoutes";
import useNotification from "../../hooks/useNotification";
import CircularLoader from "../Loaders/CircularLoader";
import CancelButton from "../CancelButton";

export default function FundsForm() {
  const adminEntries = useAppSelector((state) => state.adminFunds.entries);
  const dataEntryFunds = useAppSelector((state) => state.dataFunds.entries);
  const userRole = useAppSelector((state) => state.signin.role);
  const funds = useAppSelector((state) =>
    userRole === USER_ROLE.ADMIN ? state.adminFunds : state.dataFunds
  );

  const { showNotification } = useNotification();

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [dispatchStatus, setDispatchStatus] = useState(false);

  const form = useForm<fundsFormValuesInterface>({
    resolver: yupResolver(fundsValidationSchema),
  });

  useEffect(() => {
    let result;
    if (id) {
      if (userRole === USER_ROLE.ADMIN) {
        result = adminEntries?.find((entry) => entry.id === id);
      } else {
        result = dataEntryFunds?.find((entry) => entry.id === id);
      }
    }
    if (result) {
      form.reset(result);
    }
  }, [id, adminEntries, dataEntryFunds, form, userRole]);

  useEffect(() => {
    if (id) {
      if (userRole === USER_ROLE.ADMIN && !adminEntries.length) {
        navigate(
          `/${adminPath}/${adminPathConstants.FUNDING}?page=1&count=25&tab=live`
        );
      } else if (
        userRole === USER_ROLE.DATA_ENTRY_OPERATOR &&
        !dataEntryFunds.length
      ) {
        navigate(
          `/${DATA_ENTRY}/${dataPathConstants.DATA_FUNDING}?page=1&count=25`
        );
      }
    }
  }, [id, dataEntryFunds, adminEntries, userRole, navigate]);

  useEffect(() => {
    if (dispatchStatus && funds.status === RESPONSE_STATUS.SUCCEEDED) {
      showNotification(
        "success",
        `Funds ${id ? "updated" : "added"} successfully`
      );
      if (userRole === USER_ROLE.ADMIN) {
        navigate(
          `/${adminPath}/${adminPathConstants.FUNDING}?page=1&count=25&tab=live`
        );
      } else {
        navigate(
          `/${DATA_ENTRY}/${dataPathConstants.DATA_FUNDING}?page=1&count=25`
        );
      }
    }
    if (funds.error && typeof funds.error === "string") {
      showNotification("error", funds.error);
      dispatch(
        userRole === USER_ROLE.ADMIN ? resetAdminErrors() : resetDataErrors()
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funds, dispatchStatus, id, navigate, userRole]);

  const handleSubmit: SubmitHandler<fundsFormValuesInterface> = async (
    formData
  ) => {
    if (userRole === USER_ROLE.ADMIN) {
      if (id) {
        dispatch(editAdminFunds({ id, formData }));
      } else {
        dispatch(postAdminFunds({ formData }));
      }
    } else {
      if (id) {
        dispatch(editFunds({ pageName, id, newData: formData }));
      } else {
        dispatch(postNewFunds({ formData }));
      }
    }
    setDispatchStatus(true);
  };

  return (
    <FormProvider {...form}>
      <form
        className="form-box"
        onSubmit={form.handleSubmit(handleSubmit)}
        onChange={() =>
          dispatch(
            userRole === USER_ROLE.ADMIN
              ? resetAdminErrors()
              : resetDataErrors()
          )
        }
      >
        <div className="form-row">
          {fundsFields.map((fundsField, index) => {
            return (
              <MuiFormInput
                key={index}
                {...fundsField}
                errorMessage={
                  typeof funds.error !== "string"
                    ? funds.error[fundsField.name]
                    : ""
                }
              />
            );
          })}
        </div>
        <div className="form-button">
          <ButtonCustom
            type="submit"
            endIcon={
              funds.status === RESPONSE_STATUS.LOADING && (
                <CircularLoader size={20} />
              )
            }
            disabled={funds.status === RESPONSE_STATUS.LOADING}
          >
            Save Details
          </ButtonCustom>
          <CancelButton />
        </div>
      </form>
    </FormProvider>
  );
}
