import { Tooltip } from "@mui/material";
import { GridEditInputCell, GridRenderEditCellParams } from "@mui/x-data-grid";

export default function LinkedInEditCell({
  params,
}: {
  params: GridRenderEditCellParams;
}) {
  return (
    <Tooltip
      title={params.error ? "Please enter a valid LinkedIn URL!" : ""}
      arrow
    >
      <GridEditInputCell {...params} />
    </Tooltip>
  );
}
