import { useContext, useEffect, useState } from "react";
import NewsTable from "../NewsTable/NewsTable";
import { fetchMyNews } from "../../../store/slice/newsSlice";
import { useAppDispatch, useAppSelector } from "../../../store";
import LoadingContext from "../../../contexts/APIContext/Loading/LoadingContext";
import usePagination from "../../../hooks/usePagination";
import { handleColumnFilters } from "../../../utils/handleColumnFilters";

function PressRelease() {
  const dispatch = useAppDispatch();
  const entries = useAppSelector((state) => state.news.entries);

  const { setIsLoading, isLoading } = useContext(LoadingContext);

  const { paginationModel, updatePageParams } = usePagination();

  const [filters, setFilters] = useState<{ key: string; value: string }[]>([]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      fetchMyNews({
        page: paginationModel.page,
        count: paginationModel.pageSize,
        filters: filters,
      })
    ).then(() => setIsLoading(false));
  }, [dispatch, setIsLoading, paginationModel, filters]);

  return (
    <NewsTable
      rowsData={entries}
      isLoading={isLoading}
      paginationModel={paginationModel}
      setPaginationModel={updatePageParams}
      onFilterChange={(filters) => handleColumnFilters(filters, setFilters)}
    />
  );
}

export default PressRelease;
