import { useContext } from "react";
import { Link } from "react-router-dom";
import ArrowRightIcon from "../../../assets/icons/ArrowRightIcon";
import { FeaturesContext } from "../../../contexts/APIContext/Features/FeaturesContext";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import logoImg from "../../../assets/images/logo.png";
import { useAppSelector } from "../../../store";

const ResponsiveNavbar = ({
  showNavbar,
  setShowNavbar,
  handleLinkClick,
}: {
  showNavbar: boolean;
  setShowNavbar: React.Dispatch<React.SetStateAction<boolean>>;
  handleLinkClick: (link: string) => void;
}) => {
  const { selectedTab, redirectURL } = useContext(FeaturesContext);

  const isAuthenticated = useAppSelector(
    (state) => state.signin.isAuthenticated
  );

  return (
    <div
      className={`responsivenavbar-wrapper ${showNavbar && "active-navbar"}`}
    >
      <div className="navbar-header">
        <button className="logoContainer text-lg sm:text-2xl">
          <img className="logoImage" src={logoImg} alt="Logo" />
          <span className="sm:font-bold font-semibold">Lead</span>Hawk
        </button>

        <IconButton
          color="inherit"
          aria-label="close drawer"
          onClick={() => setShowNavbar(false)}
          edge="start"
        >
          <CloseIcon />
        </IconButton>
      </div>
      <ul className="navbar_list responsive-list">
        <li
          className="navbar_item responsive-item"
          onClick={() => {
            setShowNavbar(false);
            if (selectedTab === "home") {
              handleLinkClick("features");
            } else {
              handleLinkClick("home");
            }
          }}
        >
          <Link to="/#myleadHawk" className="navbar_link">
            Features
          </Link>
        </li>
        <li className="navbar_item responsive-item">
          <Link
            to="/pricing"
            className="navbar_link"
            onClick={() => {
              setShowNavbar(false);
              handleLinkClick("pricing");
            }}
          >
            Pricing
          </Link>
        </li>
        <li className="navbar_item responsive-item">
          <Link
            to="/about-us"
            className="navbar_link"
            onClick={() => {
              setShowNavbar(false);
              handleLinkClick("about-us");
            }}
          >
            About Us
          </Link>
        </li>
        <li className="navbar_item responsive-item">
          <Link
            to="/contact-us"
            className="navbar_link"
            onClick={() => {
              setShowNavbar(false);
              handleLinkClick("contact-us");
            }}
          >
            Contact Us
          </Link>
        </li>

        {isAuthenticated ? (
          <Link
            to={redirectURL}
            className="login_btn responsive-login-btn"
            onClick={() => setShowNavbar(false)}
          >
            Go to Dashboard <ArrowRightIcon />
          </Link>
        ) : (
          <Link
            to="/auth"
            className="login_btn responsive-login-btn"
            onClick={() => setShowNavbar(false)}
          >
            SIGN UP <ArrowRightIcon />
          </Link>
        )}
      </ul>
    </div>
  );
};

export default ResponsiveNavbar;
