import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import { useContext, useEffect } from "react";
import {
  RESPONSE_STATUS,
  SEARCHPARAMS_KEYS,
  USER_ROLE,
} from "../../enums/enums";
import useNotification from "../../hooks/useNotification";
import { userPathConstants } from "../helper/userRoutes";
import { FeaturesContext } from "../../contexts/APIContext/Features/FeaturesContext";
import CenterLoader from "../../components/Loaders/CenterLoader";
import LoadingContext from "../../contexts/APIContext/Loading/LoadingContext";
import PageLayout from "../../components/Layouts/PageLayout";
import { authPathConstants } from "../helper/authRoutes";
import { setLeadsFlag } from "../../store/slice/userFlowSlice";

export default function AuthGuard({ role }: { role: USER_ROLE }) {
  const isAuthenticated = useAppSelector(
    (state) => state.signin.isAuthenticated
  );
  const user = useAppSelector((state) => state.signin.user);
  const userFlowLoadStatus = useAppSelector((state) => state.userflow.status);
  const subscriptionChartStatus = useAppSelector(
    (state) => state.subscriptionChart.status
  );
  const filtersStatus = useAppSelector((state) => state.filters.status);

  const { setIsLoading } = useContext(LoadingContext);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { showNotification } = useNotification();
  const { redirectURL } = useContext(FeaturesContext);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLeadsFlag(true));
  }, [dispatch, pathname]);

  useEffect(() => {
    setIsLoading(
      userFlowLoadStatus === RESPONSE_STATUS.LOADING ||
        subscriptionChartStatus === RESPONSE_STATUS.LOADING ||
        filtersStatus === RESPONSE_STATUS.LOADING
    );
  }, [
    userFlowLoadStatus,
    subscriptionChartStatus,
    filtersStatus,
    setIsLoading,
  ]);

  useEffect(() => {
    if (
      isAuthenticated &&
      user?.showSubscription &&
      !localStorage.getItem(SEARCHPARAMS_KEYS.SELECTED_PLAN)
    ) {
      setIsLoading(false);
      navigate(userPathConstants.PRICING_TABLE);
    }
  }, [user, isAuthenticated, navigate, setIsLoading]);

  const canView = isAuthenticated && user && user.role === role;

  useEffect(() => {
    if ((isAuthenticated && user && user.role !== role) || !isAuthenticated) {
      showNotification("error", "Access Denied");
    }
  }, [isAuthenticated, user, role, showNotification, navigate]);

  if (canView) {
    return <PageLayout />;
  }

  if (user && redirectURL) {
    return <Navigate to={redirectURL} />;
  }

  if (!isAuthenticated) {
    return <Navigate to={authPathConstants.AUTH} />;
  }

  return <CenterLoader />;
}
