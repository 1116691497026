import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import "./TableComponent.scss";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { newHiresData } from "../../../interfaces/interfaces";
import {
  DATA_ENTRY,
  dataPathConstants,
} from "../../../Routes/helper/dataEntryRoutes";
import LinkCell from "../../LinkCell/LinkCell";
import { useAppSelector } from "../../../store";
import { filterPanel } from "../../../constants/filterPanelConstant";
import CustomGridPagination from "../../../features/CustomPagination/CustomGridPagination";

export default function TableComponent({
  rowsData,
  isLoading,
  paginationModel,
  setPaginationModel,
  onFilterChange,
}: {
  rowsData: newHiresData[];
  isLoading: boolean;
  paginationModel: {
    page: number;
    pageSize: number;
  };
  onFilterChange: (filter: GridFilterModel) => void;
  setPaginationModel: (pageData: { page: number; pageSize: number }) => void;
}) {
  const maxRecords = useAppSelector((state) => state.dataHires.maxRecords);

  const navigate = useNavigate();

  const EditCell = ({ id }: { id: string }) => {
    const handleEditClick = () => {
      navigate(
        `/${DATA_ENTRY}/${dataPathConstants.DATA_HIRES}/${dataPathConstants.EDIT_HIRES}/${id}`
      );
    };

    return (
      <div onClick={handleEditClick} style={{ cursor: "pointer" }}>
        <EditIcon style={{ color: "#45A29E" }} />
      </div>
    );
  };

  const columns: GridColDef<newHiresData>[] = [
    {
      field: "firstName",
      headerName: "First name",
      width: 130,
      sortable: false,
    },
    { field: "lastName", headerName: "Last name", width: 130, sortable: false },
    { field: "position", headerName: "Position", width: 200 },
    {
      field: "companyName",
      headerName: "Company Name",
      width: 200,
      sortable: false,
    },
    {
      field: "companyWebsite",
      headerName: "Company Website",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <LinkCell url={params.value || ""} />
      ),
    },
    { field: "companyHQ", headerName: "Company HQ", width: 180 },
    { field: "industry", headerName: "Industry", width: 180 },
    {
      field: "companyEmployeeCount",
      headerName: "Employee Count",
      width: 150,
      type: "number",
    },
    {
      field: "companyLinkedIn",
      headerName: "Company LinkedIn Profile",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <LinkCell url={params.value || ""} />
      ),
    },

    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params: { row: { id: any } }) => (
        <EditCell id={params.row.id} />
      ), // Pass the id to EditCell
    },
  ];

  return (
    <div className="tableParent">
      <div className="min-h-[300px] h-[calc(100vh-300px)] py-[20px]">
        <DataGrid
          rows={rowsData}
          columns={columns}
          loading={isLoading}
          disableVirtualization={true}
          filterDebounceMs={400}
          slots={{
            loadingOverlay: () => <></>,
            pagination: () => <CustomGridPagination />,
          }}
          isRowSelectable={() => false}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          rowCount={maxRecords ?? 0}
          pageSizeOptions={[25]}
          slotProps={{
            filterPanel,
          }}
          disableRowSelectionOnClick
        />
      </div>
    </div>
  );
}
