import * as yup from "yup";
import { regex } from "../regex/regex";

export const passwordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is a required field")
    .matches(
      regex.passwordFormat,
      "Password must be at least 6 characters with 1 uppercase, 1 lowercase and 1 digit"
    ),
});
