import "./pricing.scss";
import PricingCards from "./PricingCards";
import Footer from "../LandingPage/Footer/Footer";
import Header from "../LandingPage/header/Header";
import EnterpriseFormModal from "../EnterpriseFormModal/EnterpriseFormModal";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SEARCHPARAMS_KEYS, SUBSCRIPTION, USER_ROLE } from "../../enums/enums";
import { FeaturesContext } from "../../contexts/APIContext/Features/FeaturesContext";
import { useAppSelector } from "../../store";
import { userPathConstants } from "../../Routes/helper/userRoutes";

const Pricing = () => {
  const [showEnterpriseModal, setShowEnterpriseModal] = useState(false);
  const navigate = useNavigate();
  const { setSigninTab } = useContext(FeaturesContext);
  const isAuthenticated = useAppSelector(
    (state) => state.signin.isAuthenticated
  );
  const user = useAppSelector((state) => state.signin.user);

  const handleProPricing = () => {
    localStorage.setItem(SEARCHPARAMS_KEYS.SELECTED_PLAN, "pro");

    if (!isAuthenticated) {
      navigate("/auth");
      setSigninTab("signup");
      return;
    }

    navigate(`/${userPathConstants.PAYMENT}`);
  };

  const pricingPlans = [
    {
      name: "Starter",
      features: "For individuals",
      price: "3 Day Free Trial",
      button: "Select Plan",
      includes: ["4 saved searches", "Email alerts", "One user"],
      onClick: () => {
        navigate("/auth");
        setSigninTab("signup");
      },
      disabled: isAuthenticated,
    },
    {
      name: "Pro",
      features: "For individuals",
      price: "$49.99/month",
      button: "Select Plan",
      includes: ["8 saved searches", "Email alerts", "One user"],
      onClick: () => handleProPricing(),
      disabled: isAuthenticated && ((user?.subscription === SUBSCRIPTION.TIER_I) || (user?.role !== USER_ROLE.USER))
    },
    {
      name: "Enterprise",
      features: "For scaling teams",
      price: "Let’s Talk",
      button: "Contact Sales",
      includes: ["Unlimited saved searches", "Email alerts", "Multiple users"],
      onClick: () => setShowEnterpriseModal(true),
      disabled: isAuthenticated && user?.role !== USER_ROLE.USER,
    },
  ];

  return (
    <>
      <Header />
      <div className="text-center">
        <div className="pricing_main">
          <div className="container">
            <div className="row align-items-center justify-content-center pricing_first">
              <div className="col-lg-6">
                <div className="left_title">
                  <h1>
                    Simple transparent <span>pricing</span>
                  </h1>
                  <p>No surprises. We keep pricing simple</p>
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  src="/assets/images/sandy_bus.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pricing_bottom_bg">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div>
                <div className="btn_text_sec">
                  <button>Pricing Plans</button>
                  <h2>Choose the plan that fits your needs</h2>
                </div>
                <div className="parent_card">
                  {pricingPlans.map((plan) => (
                    <PricingCards key={plan.name} {...plan} />
                  ))}
                  {showEnterpriseModal && (
                    <EnterpriseFormModal
                      onClose={() => setShowEnterpriseModal(false)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Pricing;
