const Funding = () => {
  return (
    <div className="newhires_wrapper funding_wrapper">
      <div className="container">
        <div className="row align-items-center newhires_main">
          <div className="col-md-6">
            <img
              src="/assets/images/funding-image.svg"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-6 funding_right">
            <button className="newhires_btn">Funding</button>
            <h1 className="newhires_heading text-capitalize">
              See new funding events
            </h1>
            <p className="funding_desc">
              Funding data on LeadHawk is sourced in real time from the SEC and
              other leading sites. From pre-seed, all the way to late round
              funding, you'll be the first to know.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Funding;
