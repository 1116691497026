import {
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import {
  DATA_ENTRY,
  dataPathConstants,
} from "../../../Routes/helper/dataEntryRoutes";
import { newsData } from "../../../interfaces/interfaces";
import LinkCell from "../../LinkCell/LinkCell";
import { useAppSelector } from "../../../store";
import AdminTable from "../../AdminFlow/Tables/AdminTable";

function NewsTable({
  rowsData,
  isLoading,
  paginationModel,
  setPaginationModel,
  onFilterChange,
}: {
  rowsData: newsData[];
  isLoading: boolean;
  paginationModel: {
    page: number;
    pageSize: number;
  };
  onFilterChange: (filter: GridFilterModel) => void;
  setPaginationModel: (pageData: { page: number; pageSize: number }) => void;
}) {
  const maxRecords = useAppSelector((state) => state.news.maxRecords);

  const navigate = useNavigate();

  const EditCell = ({ id }: { id: string }) => {
    const handleEditClick = () => {
      navigate(
        `/${DATA_ENTRY}/${dataPathConstants.BUSINESS_NEWS}/${dataPathConstants.EDIT_NEWS}/${id}`
      );
    };

    return (
      <div onClick={handleEditClick} style={{ cursor: "pointer" }}>
        <EditIcon style={{ color: "#45A29E" }} />
      </div>
    );
  };

  const columns: GridColDef<newsData>[] = [
    { field: "trigger", headerName: "Trigger", width: 130 },
    {
      field: "triggerArticle",
      headerName: "Trigger Article",
      width: 130,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <LinkCell url={params.value || ""} />
      ),
    },
    { field: "categories", headerName: "Categories", width: 130 },
    {
      field: "companyName",
      headerName: "Company Name",
      width: 200,
      sortable: false,
    },
    { field: "companyHQ", headerName: "Company HQ", width: 180 },
    { field: "industry", headerName: "Industry", width: 180 },
    {
      field: "companyEmployeeCount",
      headerName: "Employee Count",
      width: 150,
      type: "number",
    },
    {
      field: "companyLinkedIn",
      headerName: "Company LinkedIn Profile",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <LinkCell url={params.value || ""} />
      ),
    },
    {
      field: "companyWebsite",
      headerName: "Company Website",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <LinkCell url={params.value || ""} />
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <EditCell id={params.row.id} />
      ),
    },
  ];

  return (
    <>
      <div className="tableParent">
        <div className="min-h-[300px] h-[calc(100vh-300px)] py-[20px]">
          <AdminTable
            rowsData={rowsData}
            columns={columns}
            isLoading={isLoading}
            isRowSelectable={() => false}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            onFilterModelChange={onFilterChange}
            maxRecords={maxRecords}
          />
        </div>
      </div>
    </>
  );
}

export default NewsTable;
