import { useContext, useEffect } from "react";
import { fetchProfiles } from "../../../store/slice/adminSlice/createProfileSlice";
import ActivityLogTable from "../ActivityLogTable/ActivityLogTable";
import { useAppDispatch, useAppSelector } from "../../../store";
import LoadingContext from "../../../contexts/APIContext/Loading/LoadingContext";

function ActivityLog() {
  const dispatch = useAppDispatch();
  const entries = useAppSelector((state) => state.adminProfile.entries);

  const { setIsLoading, isLoading } = useContext(LoadingContext);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchProfiles()).then(() => setIsLoading(false));
  }, [dispatch, setIsLoading]);

  return (
    <>
      <ActivityLogTable rowsData={entries} isLoading={isLoading} />
    </>
  );
}

export default ActivityLog;
