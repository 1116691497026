const OurMisson = () => {
  return (
    <div className="ourmission_wrapper">
      <img src="/assets/images/dots-icon.svg" alt="" className="dots_icon" />
      <div className="container">
        <div className="text-center trusted_btn">Our Mission</div>
        <h1 className="text-center py-1 text-capitalize">
          Built by sales reps, for sales reps
        </h1>
        <p className="text-center">
          Our mission is to automate the process of finding trigger events
          first.
        </p>
        <p className="text-center">
          We draw on our knowledge and that of our clients to improve our
          product every day so that you can easily...
        </p>
        <div className="card_list">
          <div className="card_item">
            <div className="card_image">
              <img src="/assets/images/better-leads.svg" alt="" />
            </div>
            <h6>Work Better Leads</h6>
          </div>
          <div className="card_item">
            <div className="card_image_active">
              <img src="/assets/images/pipeline.svg" alt="" />
            </div>
            <h6>Create More Pipeline</h6>
          </div>
          <div className="card_item">
            <div className="card_image">
              <img src="/assets/images/deals-image.svg" alt="" />
            </div>
            <h6>Close More Deals</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurMisson;
