import { HTMLAttributes, ReactNode, useEffect, useRef } from "react";

interface PopOverMenuProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  onOutsideClick: () => void;
}

export default function PopOverMenu({
  children,
  onOutsideClick,
  ...divProps
}: PopOverMenuProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideComponentClick = (e: MouseEvent): void => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target as Node)
      ) {
        try {
          onOutsideClick();
        } catch (error) {
          console.error(error);
        }
      }
    };

    document.addEventListener("click", handleOutsideComponentClick);
    return () => {
      document.removeEventListener("click", handleOutsideComponentClick);
    };
  }, [wrapperRef, onOutsideClick]);

  return (
    <div ref={wrapperRef} {...divProps}>
      {children}
    </div>
  );
}
