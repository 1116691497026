import { useContext, useEffect, useState } from "react";
import TableComponent from "../TableComponent/TableComponent";
import { fetchMyEntries } from "../../../store/slice/dataHiresSlice";
import { useAppDispatch, useAppSelector } from "../../../store";
import LoadingContext from "../../../contexts/APIContext/Loading/LoadingContext";
import usePagination from "../../../hooks/usePagination";
import { RESPONSE_STATUS } from "../../../enums/enums";
import { handleColumnFilters } from "../../../utils/handleColumnFilters";

function ExecutiveHires() {
  const dispatch = useAppDispatch();
  const entries = useAppSelector((state) => state.dataHires.entries);
  const status = useAppSelector((state) => state.dataHires.status);

  const { setIsLoading, isLoading } = useContext(LoadingContext);

  const { paginationModel, updatePageParams } = usePagination();

  const [filters, setFilters] = useState<{ key: string; value: string }[]>([]);

  useEffect(() => {
    setIsLoading(status === RESPONSE_STATUS.LOADING);
  }, [setIsLoading, status]);

  useEffect(() => {
    dispatch(
      fetchMyEntries({
        page: paginationModel.page,
        count: paginationModel.pageSize,
        filters: filters,
      })
    );
  }, [dispatch, setIsLoading, paginationModel, filters]);

  return (
    <TableComponent
      rowsData={entries}
      isLoading={isLoading}
      paginationModel={paginationModel}
      setPaginationModel={updatePageParams}
      onFilterChange={(filters) => handleColumnFilters(filters, setFilters)}
    />
  );
}

export default ExecutiveHires;
