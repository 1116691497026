import { useContext, useEffect } from "react";
import { PieChart, Pie, Tooltip, Legend, Cell } from "recharts";
import { fetchUserCount } from "../../../store/slice/chartSlice/subscriptionChartSlice";
import "./chart.scss";
import { useAppDispatch, useAppSelector } from "../../../store";
import LoadingContext from "../../../contexts/APIContext/Loading/LoadingContext";

const COLORS = ["#7B68EE", "#808080", "#A9A9A9"]; //... add more colors as needed

const SubscriptionChart = () => {
  const dispatch = useAppDispatch();
  const entries = useAppSelector((state) => state.subscriptionChart.entries);

  const { setIsLoading, isLoading } = useContext(LoadingContext);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchUserCount()).then(() => setIsLoading(false)); // Your action creator
  }, [dispatch, setIsLoading]);

  const chartData = entries
    ? entries.map((item) => ({
        name: item.subscription,
        value: item.count,
      }))
    : [];

  return (
    <div>
      {!isLoading && (
        <>
          <h3 className="chart-subscription-h3">Subscription Chart</h3>

          {chartData && (
            <PieChart width={220} height={220}>
              <Pie
                data={chartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={90}
                fill="#732aa8"
              >
                {chartData.map((_, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          )}
        </>
      )}
    </div>
  );
};

export default SubscriptionChart;
