import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RESPONSE_STATUS } from "../../../enums/enums";
import { axiosInstance } from "../../../secutityUtils/axiosInstance";

export interface Asset {
  id: string;
  key: string;
  value: string[];
  hasChanges?: boolean;
  group?: string;
}

// Define an initial state
const initialState: {
  entries: Asset[];
  status: RESPONSE_STATUS;
  error: string;
  addStatus: RESPONSE_STATUS;
  hasChanges: boolean;
} = {
  entries: [],
  status: RESPONSE_STATUS.IDLE, // Can be 'idle', 'loading', 'succeeded', or 'failed'
  error: "",
  addStatus: RESPONSE_STATUS.IDLE,
  hasChanges: false,
};

export const fetchIndustries = createAsyncThunk(
  "assets/fetchIndustries",
  async () => {
    try {
      // Make the GET request with headers
      const response = await axiosInstance.get(
        `${import.meta.env.VITE_DEV_ADMIN_URL}/api/admin/assets/getAllIndustries`
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateIndustries = createAsyncThunk(
  "assets/updateIndustry",
  async (updates: Asset[]) => {
    try {
      // Make the GET request with headers
      await axiosInstance.put(
        `${import.meta.env.VITE_DEV_ADMIN_URL}/api/admin/assets/updateIndustry`,
        updates
      );

      return true;
    } catch (error) {
      throw error;
    }
  }
);

export const industrySlice = createSlice({
  name: "industries",
  initialState,
  reducers: {
    fetchIndustriesList: (state) => {
      state.entries = [];
      state.status = RESPONSE_STATUS.IDLE;
      state.error = "";
    },
    updateIndustriesLocal: (state, action) => {
      state.hasChanges = true;
      state.entries = state.entries.map(entry => {
        if (entry.id === action.payload.id) {
          return action.payload;
        }
        return entry;
      })
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIndustries.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchIndustries.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.entries = action.payload;
      })
      .addCase(fetchIndustries.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        state.error = action.error.message || "";
      })
      .addCase(updateIndustries.pending, (state) => {
        state.addStatus = RESPONSE_STATUS.LOADING;
      })
      .addCase(updateIndustries.fulfilled, (state) => {
        state.hasChanges = false;
        state.addStatus = RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(updateIndustries.rejected, (state, action) => {
        state.addStatus = RESPONSE_STATUS.FAILED;
        state.error = action.error.message || "";
      });
  },
});

// Export actions and reducer
export const { fetchIndustriesList, updateIndustriesLocal } = industrySlice.actions;
export default industrySlice.reducer;
