import { createContext } from "react";
import { FeaturesContextType } from "./FeaturesContext.interface";

export const FeaturesContext = createContext<FeaturesContextType>({
  selectedTab: "",
  signinTab: "signup",
  setSelectedTab: function (): void {
    throw new Error("Function not implemented.");
  },
  setSigninTab: function (): void {
    throw new Error("Function not implemented.");
  },
  redirectURL: "",
  setRedirectURL: function (): void {
    throw new Error("Function not implemented.");
  },
});
