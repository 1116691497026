import { Fragment, useEffect } from "react";
import { generateHeaderText } from "../../utils/userFlow";
import HeaderComponent from "../CommonAppComponents/HeaderComponent/HeaderComponent";
import JobPostings from "./JobPostings/JobPostings";
import MyLeadsComponent from "./MyLeads/MyLeadsComponent";
import NewHires from "./NewHires/NewHires";
import PressRelease from "./PressRelease/PressRelease";
import Fundings from "./Fundings/Fundings";
import "./UserFlow.scss";
import { setNewSelectedRowsEmpty } from "../../store/slice/userFlowSlice";
import { useAppDispatch } from "../../store";

const componentsMap: Record<string, () => JSX.Element> = {
  newHires: NewHires,
  jobPostings: JobPostings,
  pressReleases: PressRelease,
  fundings: Fundings,
  myLeads: MyLeadsComponent,
};

function UserFlow({ selectedItem }: { selectedItem: string }) {
  const dispatch = useAppDispatch();
  const { primaryText, secondaryText } = generateHeaderText(selectedItem);
  const selectedHeader = selectedItem !== "myLeads" ? "All" : "myLeads";
  // const buttonName = "Add to lead";
  const SelectedComponent = componentsMap[selectedItem];

  useEffect(() => {
    dispatch(setNewSelectedRowsEmpty());
  }, [dispatch]);

  return (
    <Fragment>
      <HeaderComponent
        primaryText={primaryText}
        secondaryText={secondaryText}
        selectedScreen={selectedHeader}
      />
      {SelectedComponent && <SelectedComponent />}
    </Fragment>
  );
}

export default UserFlow;
