const rows = [
  {
    id: 1,
    name: "Brewington",
    username: "Jaclyn ",
    role: "Financial Controller",
    email: "a@gmail.com",
    contact: +726389,
  },
  {
    id: 2,
    name: "Jackson",
    username: "Jack123 ",
    role: "Controller",
    email: "jack@gmail.com",
    contact: +726489,
  },
  {
    id: 3,
    name: "Mandella",
    username: "mand987",
    role: "Controller",
    email: "mand@gmail.com",
    contact: +726389,
  },
  {
    id: 4,
    name: "Samhitha",
    username: "sam87",
    role: "Assitant Controller",
    email: "sam@gmail.com",
    contact: +726389,
  },
  {
    id: 5,
    name: "Ritesh",
    username: "Rith67 ",
    role: "jr Controller",
    email: "rit@gmail.com",
    contact: +726389,
  },
];

const columns = [
  { field: "id", headerName: "id", width: 130 },
  { field: "name", headerName: "Name", width: 200 },
  { field: "username", headerName: "Username", width: 200 },
  { field: "role", headerName: "Role", width: 250 },
  { field: "email", headerName: "Email", width: 250 },
  { field: "contact", headerName: "Contact", width: 200 },
];
const draftTabs = ["live", "draft"];
// const UserList = [

// ]

export { rows, columns, draftTabs };
