import {
  GridColDef,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  deleteDraftHires,
  editDraftHires,
  fetchDraftAdminHires,
  resetErrors,
  setSelectedIds,
} from "../../../store/slice/adminSlice/adminHireSlice";
import { useAppDispatch, useAppSelector } from "../../../store";
import { newHiresData } from "../../../interfaces/interfaces";
import DeleteConfirmationModal from "../../DeleteConfirmationModal/DeleteConfirmationModal";
import { useContext, useEffect, useState } from "react";
import { RESPONSE_STATUS } from "../../../enums/enums";
import useNotification from "../../../hooks/useNotification";
import LinkCell from "../../LinkCell/LinkCell";
import LoadingContext from "../../../contexts/APIContext/Loading/LoadingContext";
import AdminTable from "./AdminTable";
import { Close, Done } from "@mui/icons-material";
import { regex } from "../../../regex/regex";
import LinkedInEditCell from "../../LinkedInEditCell/LinkedInEditCell";
import URLEditCell from "../../URLEditCell/URLEditCell";
import NumberEditCell from "../../NumberEditCell/NumberEditCell";
import ConfidenceRatioCell from "../../ConfidenceRatioCell/ConfidenceRatioCell";

export default function DraftHiresTable({
  rowsData,
  paginationModel,
  setPaginationModel,
}: {
  rowsData: newHiresData[];
  paginationModel: {
    page: number;
    pageSize: number;
  };

  setPaginationModel: (pageData: { page: number; pageSize: number }) => void;
}) {
  const hires = useAppSelector((state) => state.adminHires);

  const dispatch = useAppDispatch();
  const { showNotification } = useNotification();
  const { setIsLoading, isLoading } = useContext(LoadingContext);

  const [showModal, setShowModal] = useState(true);
  const [dispatchStatus, setDispatchStatus] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [checkedRowIds, setCheckedRowIds] = useState<GridRowSelectionModel>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const processRowUpdate = (newRow: newHiresData) => {
    const updatedRow = { ...newRow, isNew: false };
    setDispatchStatus(true);
    dispatch(editDraftHires({ id: newRow.id, formData: newRow }));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const EditCell = ({ id }: { id: string }) => {
    const handleEditClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };
    return (
      <button onClick={handleEditClick}>
        <EditIcon className="text-[#45A29E]" />
      </button>
    );
  };

  const EditModeCell = ({ id }: { id: string }) => {
    const handleSaveClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    };

    return (
      <div className="flex gap-3 text-[#45A29E]">
        <button onClick={handleSaveClick}>
          <Done />
        </button>
        <button onClick={handleCancelClick}>
          <Close />
        </button>
      </div>
    );
  };
  const DeleteCell = ({ id }: { id: string }) => {
    const handleDeleteClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      setShowModal(true);
      setDeleteId(id);
    };

    return (
      <button onClick={handleDeleteClick}>
        <DeleteIcon className="text-[#45A29E]" />
      </button>
    );
  };

  const columns: GridColDef<newHiresData>[] = [
    {
      field: "firstName",
      headerName: "First name",
      width: 130,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      editable: true,
    },
    {
      field: "lastName",
      headerName: "Last name",
      width: 130,
      disableColumnMenu: true,
      filterable: false,
      editable: true,
      sortable: false,
    },
    {
      field: "position",
      headerName: "Position",
      width: 200,
      disableColumnMenu: true,
      filterable: false,
      editable: true,
    },
    {
      field: "companyName",
      headerName: "Company Name",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      editable: true,
    },
    {
      field: "companyCity",
      headerName: "City",
      width: 200,
      disableColumnMenu: true,
      filterable: false,
      editable: true,
    },
    {
      field: "companyHQ",
      headerName: "State",
      width: 180,
      disableColumnMenu: true,
      filterable: false,
      editable: true,
    },
    {
      field: "industry",
      headerName: "Industry",
      width: 180,
      disableColumnMenu: true,
      filterable: false,
      editable: true,
    },
    {
      field: "companyEmployeeCount",
      headerName: "Employee Count",
      width: 150,
      type: "number",
      disableColumnMenu: true,
      filterable: false,
      editable: true,
      preProcessEditCellProps: (params) => {
        if (params.props.value > Number.MAX_SAFE_INTEGER) {
          return {
            ...params.props,
            error: `Employee count must be less than ${Number.MAX_SAFE_INTEGER}`,
          };
        }
        return { ...params.props, error: false };
      },
      renderEditCell: (params) => <NumberEditCell params={params} />,
    },
    {
      field: "companyLinkedIn",
      headerName: "Company LinkedIn Profile",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      editable: true,
      preProcessEditCellProps: (params) => {
        if (params.props.value) {
          const hasError = !regex.linkedinUrlFormat.test(params.props.value);

          return { ...params.props, error: hasError };
        }
        return { ...params.props, error: false };
      },
      renderEditCell: (params) => <LinkedInEditCell params={params} />,
      renderCell: (params) => <LinkCell url={params.value || ""} />,
    },
    {
      field: "companyWebsite",
      headerName: "Company Website",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      editable: true,
      preProcessEditCellProps: (params) => {
        if (params.props.value) {
          const hasError = !regex.urlFormat.test(params.props.value);

          return { ...params.props, error: hasError };
        }
        return { ...params.props, error: false };
      },
      renderEditCell: (params) => <URLEditCell params={params} />,
      renderCell: (params) => <LinkCell url={params.value || ""} />,
    },
    {
      field: "confidenceRatio",
      headerName: "Confidence Ratio",
      width: 200,
      type: "number",
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return params.value ?
          <ConfidenceRatioCell ratio={params.value} /> :
          ''
      },
      editable: false,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 180,
      disableColumnMenu: true,
      filterable: true,
      editable: true,
      renderCell: (params) => {
        return new Intl.DateTimeFormat('en-US', {
          dateStyle: 'short',
          timeStyle: 'short',
        }).format(new Date(params.value));
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 70,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: ({ id, row }: { id: GridRowId; row: { id: string } }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return <EditModeCell id={row.id} />;
        }

        return <EditCell id={row.id} />;
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params) => <DeleteCell id={params.row.id} />,
    },
  ];

  useEffect(() => {
    setIsLoading(hires.status === RESPONSE_STATUS.LOADING);

    if (deleteId && isLoading && hires.status === RESPONSE_STATUS.SUCCEEDED) {
      showNotification("success", "Executive hire is deleted successfully");
      setShowModal(false);
      setIsLoading(false);
      setDeleteId("");
      dispatch(
        fetchDraftAdminHires({
          page: paginationModel.page,
          count: paginationModel.pageSize,
        })
      );
    }

    if (dispatchStatus && hires.status === RESPONSE_STATUS.SUCCEEDED) {
      showNotification("success", "Executive Hire Edited successfully");
      setDispatchStatus(false);
    }

    if (hires.error && typeof hires.error === "string") {
      showNotification("error", hires.error);
      dispatch(resetErrors());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hires, deleteId, isLoading, setIsLoading]);

  const handleSelectionRowChange = (newSelection: GridRowSelectionModel) => {
    setCheckedRowIds(newSelection); // Update state with the new selection

    dispatch(setSelectedIds(newSelection));
  };

  return (
    <div className="tableParent">
      <div className="min-h-[300px] h-[calc(100vh-300px)] py-[20px] overflow-auto">
        <AdminTable
          rowsData={rowsData}
          columns={columns}
          isLoading={isLoading}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          maxRecords={hires.maxRecords}
          checkedRowIds={checkedRowIds}
          handleSelectionRowChange={handleSelectionRowChange}
          rowModesModel={rowModesModel}
          handleRowModesModelChange={handleRowModesModelChange}
          processRowUpdate={processRowUpdate}
          checkboxSelection={true}
        />
      </div>
      {showModal && deleteId && (
        <DeleteConfirmationModal
          deleteFn={() => dispatch(deleteDraftHires({ id: deleteId }))}
          onClose={() => setShowModal(false)}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}
