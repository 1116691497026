import { createSlice } from "@reduxjs/toolkit";

const universalDataSlice = createSlice({
  name: "universalData",
  initialState: {
    verificationMessage: {},
  },
  reducers: {
    // Action to set verification messages
    setVerificationMessage: (state, action) => {
      state.verificationMessage = action.payload;
    },
    // Action to clear verification messages
    clearVerificationMessage: (state) => {
      state.verificationMessage = {};
    },
  },
});

export const { setVerificationMessage, clearVerificationMessage } =
  universalDataSlice.actions;

export default universalDataSlice.reducer;
