import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { fetchRecentEntries } from "../../../store/slice/adminSlice/recentEntriesSlice";
import "./RecentEntriesGrid.scss";

const RecentEntriesGrid = () => {
  const dispatch = useAppDispatch();
  const { entries, status, error } = useAppSelector((state) => state.recentEntries);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchRecentEntries());
    }
  }, [status, dispatch]);

  if (status === "loading") return <div className="loading">Loading...</div>;
  if (status === "failed") return <div className="error">Error: {error}</div>;
  if (!entries) return <div className="no-data">No recent entries found.</div>;

  const formatRelativeTime = (dateString: string) => {
    const now = new Date();
    const past = new Date(dateString);
    const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000);

    if (diffInSeconds < 60) {
      return 'Just now';
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
  };

  const renderJobPostings = () => (
    <div className="entry-section">
      <h3>Job Postings</h3>
      <table>
        <thead>
          <tr>
            <th>Job Title</th>
            <th>Company</th>
            <th>Location</th>
            <th>Industry</th>
            <th>Last Modified</th>
          </tr>
        </thead>
        <tbody>
          {entries.jobPostings.slice(0, 20).map((job) => (
            <tr key={job.jobLink + job.jobTitle}>
              <td><a href={job.jobLink} target="_blank" rel="noopener noreferrer">{job.jobTitle}</a></td>
              <td>{job.companyName}</td>
              <td>{job.companyCity && job.companyHQ ? `${job.companyCity}, ${job.companyHQ}` : job.companyCity || job.companyHQ || 'N/A'}</td>
              <td>{job.industry || 'N/A'}</td>
              <td>{formatRelativeTime(job.lastModifiedDate)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderFunding = () => (
    <div className="entry-section">
      <h3>Funding</h3>
      <table>
        <thead>
          <tr>
            <th>Company</th>
            <th>Location</th>
            <th>Industry</th>
            <th>Funding Raised</th>
            <th>Last Modified</th>
          </tr>
        </thead>
        <tbody>
          {entries.funding.slice(0, 20).map((fund) => (
            <tr key={fund.fundingRaised + fund.companyName + fund.lastModifiedDate}>
              <td><a href={fund.companyLinkedIn} target="_blank" rel="noopener noreferrer">{fund.companyName}</a></td>
              <td>{fund.companyCity && fund.companyHQ ? `${fund.companyCity}, ${fund.companyHQ}` : fund.companyCity || fund.companyHQ || 'N/A'}</td>
              <td>{fund.industry || 'N/A'}</td>
              <td>${fund.fundingRaised.toLocaleString()}</td>
              <td>{formatRelativeTime(fund.lastModifiedDate)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderLinkedInData = () => (
    <div className="entry-section">
      <h3>LinkedIn Data</h3>
      <table>
        <thead>
          <tr>
            <th>Company</th>
            <th>Location</th>
            <th>Industry</th>
            <th>Employee Count</th>
            <th>Last Modified</th>
          </tr>
        </thead>
        <tbody>
          {entries.linkedInData.slice(0, 20).map((data) => (
            <tr key={data.companyName + data.companyLinkedIn + data.lastModifiedDate}>
              <td><a href={data.companyLinkedIn} target="_blank" rel="noopener noreferrer">{data.companyName}</a></td>
              <td>{data.companyCity && data.companyHQ ? `${data.companyCity}, ${data.companyHQ}` : data.companyCity || data.companyHQ || 'N/A'}</td>
              <td>{data.industry || 'N/A'}</td>
              <td>{data.companyEmployeeCount}</td>
              <td>{formatRelativeTime(data.lastModifiedDate)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderBusinessNews = () => (
    <div className="entry-section">
      <h3>Business News</h3>
      <table>
        <thead>
          <tr>
            <th>Company</th>
            <th>Trigger</th>
            <th>Industry</th>
            <th>Location</th>
            <th>Last Modified</th>
          </tr>
        </thead>
        <tbody>
          {entries.businessNews.slice(0, 20).map((news) => (
            <tr key={news.companyName + news.triggerArticle + news.lastModifiedDate}>
              <td><a href={news.companyLinkedIn} target="_blank" rel="noopener noreferrer">{news.companyName}</a></td>
              <td><a href={news.triggerArticle} target="_blank" rel="noopener noreferrer">{news.trigger}</a></td>
              <td>{news.industry || 'N/A'}</td>
              <td>{news.companyCity && news.companyHQ ? `${news.companyCity}, ${news.companyHQ}` : news.companyCity || news.companyHQ || 'N/A'}</td>
              <td>{formatRelativeTime(news.lastModifiedDate)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderExecutiveHires = () => (
    <div className="entry-section">
      <h3>Executive Hires</h3>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Position</th>
            <th>Company</th>
            <th>Industry</th>
            <th>Location</th>
            <th>Last Modified</th>
          </tr>
        </thead>
        <tbody>
          {entries.executiveHires.slice(0, 20).map((hire) => (
            <tr key={hire.firstName + hire.lastName + hire.position + hire.companyName + hire.lastModifiedDate}>
              <td>{`${hire.firstName} ${hire.lastName}`}</td>
              <td>{hire.position}</td>
              <td><a href={hire.companyLinkedIn} target="_blank" rel="noopener noreferrer">{hire.companyName}</a></td>
              <td>{hire.industry || 'N/A'}</td>
              <td>{hire.companyCity && hire.companyHQ ? `${hire.companyCity}, ${hire.companyHQ}` : hire.companyCity || hire.companyHQ || 'N/A'}</td>
              <td>{formatRelativeTime(hire.lastModifiedDate)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="recent-entries-container">
      <p className="latest-info">Displaying the latest 20 values scraped for each category</p>
      <div className="recent-entries-grid">
        {renderJobPostings()}
        {renderFunding()}
        {renderLinkedInData()}
        {renderBusinessNews()}
        {renderExecutiveHires()}
      </div>
    </div>
  );
};

export default RecentEntriesGrid;
