import axios, { AxiosError } from "axios";
import { TOKEN } from "../enums/enums";
import { authPathConstants } from "../Routes/helper/authRoutes";

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem(TOKEN.JWT_TOKEN);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const refreshTokenURL = `${
  import.meta.env.VITE_DEV_USER_URL
}/api/users/refresh-token`;

axiosInstance.interceptors.response.use(
  (response) => response,
  async function (error) {
    if (error instanceof AxiosError) {
      const originalRequest = error.config;
      if (!originalRequest) {
        return;
      }

      if (error.response?.status === 403) {
        try {
          const response = await axios.post(refreshTokenURL, {
            token: localStorage.getItem(TOKEN.REFRESH_TOKEN),
          });

          if (!response) {
            localStorage.clear();
            window.location.href = authPathConstants.AUTH;
            return;
          }

          const data = response.data;

          localStorage.setItem(TOKEN.JWT_TOKEN, data.token);
          localStorage.setItem(TOKEN.REFRESH_TOKEN, data.refreshToken);
          originalRequest.headers.Authorization = `Bearer ${data.token}`;

          return axiosInstance(originalRequest);
        } catch (error) {
          localStorage.clear();
          window.location.href = authPathConstants.AUTH;
          return;
        }
      }
    }
    return Promise.reject(error);
  }
);
