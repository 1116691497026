import { useContext, useEffect, useMemo, useState } from "react";
import { GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid";
import {
  fetchDataforScreens,
  setAddRowIdtoLeads,
  setDeletedLeadsData,
} from "../../../store/slice/userFlowSlice";
import {
  queryTabs,
  userFlowScreens,
} from "../../../constants/userFlowConstants";
import {
  generateHeaderColumns,
  processFilterFormat,
} from "../../../utils/userFlow";
import Table from "../../../features/Table/Table";
import { fetchDataforFilters } from "../../../store/slice/filterSlice";
import { useAppDispatch, useAppSelector } from "../../../store";
import { rowsInterface } from "../../../interfaces/interfaces";
import LoadingContext from "../../../contexts/APIContext/Loading/LoadingContext";
import { RESPONSE_STATUS } from "../../../enums/enums";
import usePagination from "../../../hooks/usePagination";
import { handleColumnFilters } from "../../../utils/handleColumnFilters";

function Fundings() {
  const rowsReal: rowsInterface[] = useAppSelector(
    (state) => state.userflow[userFlowScreens[1]]
  );
  const maxRecords = useAppSelector((state) => state.userflow.maxRecords);
  const maxFilteredData = useAppSelector((state) => state.filters.maxRecords);
  const userFlowStatus = useAppSelector((state) => state.userflow.status);
  const leadRowData: Array<{ id: string }> = useAppSelector(
    (state) => state.userflow[queryTabs[1]]
  );
  const selectedFilters = useAppSelector(
    (state) => state.filters.selectedFilters
  );
  const viewFilters = useAppSelector((state) => state.filters.viewFilter);
  const { isLoading, setIsLoading } = useContext(LoadingContext);

  const apiRef = useGridApiRef();

  const isFiltersSelected = useAppSelector(
    (state) => state.filters.applyFilter
  );

  const noFiltersPresent = useMemo(
    () => selectedFilters && Object.keys(selectedFilters).length === 0,
    [selectedFilters]
  );

  const [checkedRowIds, setCheckedRowIds] = useState<GridRowSelectionModel>([]); // Rename initialCheckedRowIds to checkedRowIds
  const [filters, setFilters] = useState<{ key: string; value: string }[]>([]);

  const { paginationModel, updatePageParams } = usePagination();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (viewFilters.viewFlag) {
      const filterValue = processFilterFormat(viewFilters.filters);

      dispatch(
        fetchDataforFilters({
          appliedFilters: filterValue,
          screen: userFlowScreens[1],
        })
      );
    }
  }, [viewFilters, dispatch]);

  useEffect(() => {
    if (isFiltersSelected) {
      dispatch(
        fetchDataforFilters({
          appliedFilters: selectedFilters,
          screen: userFlowScreens[1],
        })
      );
    }
  }, [selectedFilters, dispatch, isFiltersSelected]);

  useEffect(() => {
    if (noFiltersPresent) {
      dispatch(
        fetchDataforScreens({
          selectedScreen: userFlowScreens[1],
          page: paginationModel.page,
          filtersArray: filters,
        })
      );
    }
  }, [dispatch, paginationModel, noFiltersPresent, filters]);

  useEffect(() => {
    if (leadRowData.length > 0) {
      setCheckedRowIds(leadRowData.map((x) => x.id));
    }
  }, [leadRowData, rowsReal]);

  useEffect(() => {
    if (userFlowStatus === RESPONSE_STATUS.SUCCEEDED) {
      setIsLoading(false);
    }
  }, [setIsLoading, userFlowStatus]);

  const columns = generateHeaderColumns(userFlowScreens[1]);
  const checkboxSelection = true;

  const handleSelectionRowChange = (newSelection: GridRowSelectionModel) => {
    setCheckedRowIds(newSelection); // Update state with the new selection

    const idsFromDataObjects = leadRowData.map((object) => object.id);
    let idsNotInDataObjects = idsFromDataObjects.filter(
      (id) => !newSelection.includes(id)
    );

    dispatch(
      setDeletedLeadsData({
        deletedRowID: idsNotInDataObjects,
        screen: userFlowScreens[1],
        currentSelection: newSelection,
      })
    );

    dispatch(
      setAddRowIdtoLeads({
        data: newSelection,
        screen: userFlowScreens[1],
        oldData: leadRowData,
      })
    );
  };

  return (
    <div className="min-h-[300px] h-[calc(100vh-300px)] py-[20px]">
      <Table
        rows={rowsReal}
        columns={columns}
        checkboxSelection={checkboxSelection}
        onRowSelectionModelChange={handleSelectionRowChange}
        rowCount={maxFilteredData ?? maxRecords}
        isLoading={isLoading}
        apiRef={apiRef}
        paginationModel={paginationModel}
        selectionModel={checkedRowIds}
        onFilterChange={(filters) => handleColumnFilters(filters, setFilters)}
        onPaginationModelChange={updatePageParams}
        paginationMode={"server"}
      />
    </div>
  );
}

export default Fundings;
