import { Player } from "@lottiefiles/react-lottie-player";

export default function CenterLoader() {
  return (
    <div className="flex w-screen h-screen bg-[rgba(0,0,0,0.1)] backdrop-blur-sm fixed z-[2000] top-0 right-0 justify-center items-center">
      <Player
        autoplay
        loop
        src="https://lottie.host/aeeb7a15-19ed-4774-8a58-ee58f6566d59/teUqJpjWw6.json"
        className="h-[300px] "
      />
    </div>
  );
}
