export const profileFields = [
  {
    label: "First Name",
    name: "firstName",
  },
  {
    label: "Last Name",
    name: "lastName",
  },
  {
    label: "Phone Number",
    name: "phoneNumber",
  },
  {
    label: "Email",
    name: "email",
  },
];
