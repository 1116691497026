import "./LogoComponent.scss";

import { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../../store/slice/signinSlice.js";
import logoImg from "../../../assets/images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import PopOverMenu from "../../PopOver/PopOverMenu.js";
import { useAppDispatch, useAppSelector } from "../../../store/index.js";
import { FeaturesContext } from "../../../contexts/APIContext/Features/FeaturesContext.js";
import { USER_ROLE } from "../../../enums/enums.js";
import { userPathConstants } from "../../../Routes/helper/userRoutes.js";
import { adminPathConstants } from '../../../Routes/helper/adminRoutes.js';

const LogoComponent = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { setRedirectURL } = useContext(FeaturesContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pdfLoadingState = useAppSelector((state) => state.userflow.pdfLoader);
  const userRole = useAppSelector((state) => state.signin.role);

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    setIsDropdownOpen(false);
    dispatch(logoutUser());
    setRedirectURL("");
    navigate("/auth");
  };

  return (
    <div className="LogoParent">
      <div className="logo ml-9 xl:ml-0">
        <span className="logoContainer text-lg sm:text-2xl">
          <img className="logoImage" src={logoImg} alt="Logo" />
          <span className="sm:font-bold font-semibold">Lead</span>Hawk
        </span>
      </div>
      <PopOverMenu onOutsideClick={() => setIsDropdownOpen(false)}>
        <div className="logoutSection">
          <button
            className="material-symbols-outlined"
            onClick={handleToggleDropdown}
            style={{ fontSize: "35px" }} // Making it appear clickable
          >
            <FontAwesomeIcon icon={faUserCircle} />
          </button>

          {isDropdownOpen && (
            <div className="dropdownMenu">
              <div
                className="dropdownItem"
                onClick={() => {
                  setIsDropdownOpen(false); // Close dropdown
                  navigate("/profile");
                }}
              >
                View Profile
              </div>
              {userRole === USER_ROLE.USER && (
                <div
                  className={`dropdownItem text-wrap ${
                    pdfLoadingState ? "cursor-not-allowed" : ""
                  }`}
                  onClick={() => {
                    setIsDropdownOpen(false);
                    navigate(userPathConstants.INVOICES);
                  }}
                >
                  Invoices
                </div>
              )}
              {(userRole === USER_ROLE.ADMIN || userRole === USER_ROLE.DATA_ENTRY_OPERATOR) && (
                <div
                  className={`dropdownItem text-wrap`}
                  onClick={() => {
                    setIsDropdownOpen(false);
                    navigate(adminPathConstants.SETTINGS);
                  }}
                >
                  Settings
                </div>
              )}

              <div className="dropdownItem" onClick={handleLogout}>
                Sign Out
              </div>
            </div>
          )}
        </div>
      </PopOverMenu>
    </div>
  );
};

export default LogoComponent;
