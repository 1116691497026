import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App.js";
import reportWebVitals from "./reportWebVitals.js";
import { Provider } from "react-redux";
import { store } from "./store/index.js";
import NotificationProvider from "./contexts/APIContext/Notification/NotificationProvider.js";
import LoadingProvider from "./contexts/APIContext/Loading/LoadingProvider.js";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import FeaturesProvider from "./contexts/APIContext/Features/FeaturesProvider.js";
import "vite/modulepreload-polyfill";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <LoadingProvider>
        <FeaturesProvider>
          <NotificationProvider>
            <App />
          </NotificationProvider>
        </FeaturesProvider>
      </LoadingProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
