import "./FilterComponent.scss";
import DefaultScreen from "./DefaultScreen/DefaultScreen";
import CancelIcon from "@mui/icons-material/Cancel";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setFilterDrawerStatus } from "../../../store/slice/filterSlice";

function FilterComponent({
  filterTitles,
  handleClearFilters,
}: {
  filterTitles: string[];
  handleClearFilters: () => void;
}) {
  const dispatch = useAppDispatch();

  let selectedFilters = useAppSelector(
    (state) => state.filters.selectedFilters
  );

  const handleDrawerClose = () => {
    dispatch(setFilterDrawerStatus(false));
  };

  return (
    <div className="FilterComponent flex flex-column h-full relative">
      <div className="flex justify-between items-center p-2 border-b border-b-teal-700">
        <h3 className="FilterHeader">Filters</h3>
        <div className="gap-2 flex">
          <button className="clearButton" onClick={handleClearFilters}>
            Clear
          </button>
          <button className="text-teal-700" onClick={handleDrawerClose}>
            <CancelIcon />
          </button>
        </div>
      </div>

      <DefaultScreen
        selectedFilters={selectedFilters}
        filtersForScreen={filterTitles}
      />
    </div>
  );
}

export default FilterComponent;
