import { useState, useRef, useEffect, SyntheticEvent } from "react";
import { TextField } from "@mui/material";
import { mapTofilterCommonTitles } from "../../../../constants/userFlowConstants";
import {
  saveAppliedFilters,
  setApplyFilterStatus,
} from "../../../../store/slice/filterSlice";
import ButtonCustom from "../../../../features/ButtonCustom/ButtonCustom";
import InfoIcon from "@mui/icons-material/Info";
import { useAppDispatch, useAppSelector } from "../../../../store";
import SaveFilterScreen from "../SaveFilterScreen/SaveFilterScreen";
import Autocomplete from "./Autocomplete";
import "./DefaultScreen.scss";

const InfoText = () => (
  <div className="flex gap-1 text-sm mt-2 text-gray-500 items-center">
    <InfoIcon className="w-1" />
    <p className="font-light text-sm italic mb-0">
      {">"}50 , {"<"}50 , =50 , 50-60
    </p>
  </div>
);

function DefaultScreen({
  selectedFilters,
  filtersForScreen,
}: {
  selectedFilters: Record<string, string[]>;
  filtersForScreen: string[];
}) {
  const viewFilters = useAppSelector((state) => state.filters.viewFilter);
  const [inputValue, setInputValue] = useState({ ...selectedFilters });
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useAppDispatch();
  const [applyFilterFlag, setApplyFilterFlag] = useState(true);
  const [showSaveFilterModal, setShowSaveFilterModal] = useState(false);
  const [clear, setClear] = useState(false);

  const applyButtonStyle = !applyFilterFlag
    ? { width: "75%", backgroundColor: "#009688", color: "white" }
    : {
        width: "75%",
        backgroundColor: "rgba(255, 255, 255, 0.12)",
        color: "#00000052",
      };

  useEffect(() => {
    setClear(!Object.keys(inputValue).length);
  }, [inputValue]);

  useEffect(() => {
    setInputValue(selectedFilters);

    if (
      selectedFilters &&
      Object.values(selectedFilters).every((value) => {
        return (
          value?.length === 0 || (Array.isArray(value) && value.length === 0)
        );
      })
    ) {
      setApplyFilterFlag(true);
    }
  }, [selectedFilters]);

  const inputEvent = (
    _: SyntheticEvent<Element, Event>,
    type: string,
    value: string[]
  ) => {
    // Update local state immediately
    setInputValue((prev) => ({
      ...prev,
      [type]: value,
    }));
    setApplyFilterFlag(false);
    // Debounce the dispatch to save the selected filters to the redux store
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    const updatedFiltersCopy = { [type]: value };

    debounceTimer.current = setTimeout(() => {
      dispatch(saveAppliedFilters(updatedFiltersCopy));
    }, 500);
  };

  const handleInputevent = (
    event: SyntheticEvent<Element, Event>,
    value: string[],
    title: string
  ) => {
    inputEvent(event, mapTofilterCommonTitles[title], value);
  };

  useEffect(() => {
    if (Object.keys(inputValue).length) {
      setApplyFilterFlag(false);
    }
  }, [inputValue]);

  return (
    <>
      <div className=" FilterComponent flex-1">
        <div className="Filters">
          {filtersForScreen?.map((x) => (
            <div key={`i-${x}`} className="FilterItem">
              <span>{x}</span>

              {x !== "Employee Count" && x !== "Funding Raised" ? (
                <Autocomplete
                  fieldName={x}
                  value={inputValue[mapTofilterCommonTitles[x]]}
                  clear={clear}
                />
              ) : (
                <div
                  style={{ display: "flex", gap: "2px", alignItems: "center" }}
                >
                  {(x === "Employee Count" || x === "Funding Raised") && (
                    <div className="w-full">
                      <TextField
                        className="check w-full"
                        id="outlined-basic"
                        variant="outlined"
                        placeholder={x}
                        onChange={(event) => {
                          const value = [event.target.value];
                          handleInputevent(event, value, x);
                        }}
                        inputProps={{
                          style: {
                            padding: "10px",
                          },
                        }}
                        value={
                          (Array.isArray(
                            inputValue?.[mapTofilterCommonTitles?.[x]]
                          )
                            ? inputValue?.[mapTofilterCommonTitles[x]][0]
                            : inputValue?.[mapTofilterCommonTitles[x]]) || ""
                        }
                      />
                      <InfoText />
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
          <div className="apply w-full">
            <button
              className="applyButton"
              disabled={applyFilterFlag}
              onClick={() => dispatch(setApplyFilterStatus(true))}
            >
              Preview Filters
            </button>
          </div>
        </div>
      </div>

      <div className="apply w-full">
        <ButtonCustom
          className="button_submit"
          disabled={applyFilterFlag}
          onClick={() => setShowSaveFilterModal(true)}
          style={applyButtonStyle}
        >
          {viewFilters?.filterName ? "Update Filters" : "Save filters"}
        </ButtonCustom>
      </div>
      {showSaveFilterModal && (
        <SaveFilterScreen
          selectedFilters={selectedFilters}
          filterName={viewFilters?.filterName}
          onClose={() => setShowSaveFilterModal(false)}
        />
      )}
    </>
  );
}

export default DefaultScreen;
