import { useContext, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  RESPONSE_STATUS,
  SEARCHPARAMS_KEYS,
  SUBSCRIPTION,
  USER_ROLE,
} from "../../enums/enums";
import PageLoader from "../Loaders/PageLoader";
import { createCheckoutSession } from "../../store/slice/signinSlice";
import { Navigate } from "react-router-dom";
import { FeaturesContext } from "../../contexts/APIContext/Features/FeaturesContext";

export default function PaymentGateway() {
  const user = useAppSelector((state) => state.signin.user);
  const isAuthenticated = useAppSelector(
    (state) => state.signin.isAuthenticated
  );
  const status = useAppSelector((state) => state.signin.status);
  const checkoutURL = useAppSelector((state) => state.signin.checkoutURL);

  const dispatch = useAppDispatch();
  const { redirectURL } = useContext(FeaturesContext);

  const selectedPlan = !!localStorage.getItem(SEARCHPARAMS_KEYS.SELECTED_PLAN);

  const redirectToPayment = useMemo(() => {
    return (
      (isAuthenticated &&
        selectedPlan &&
        user &&
        user?.role === USER_ROLE.USER &&
        user?.subscription !== SUBSCRIPTION.TIER_I) ??
      false
    );
  }, [isAuthenticated, selectedPlan, user]);

  useEffect(() => {
    if (redirectToPayment) {
      dispatch(createCheckoutSession());
    }
  }, [dispatch, redirectToPayment]);

  useEffect(() => {
    if (checkoutURL) {
      window.location.href = checkoutURL;
      localStorage.removeItem(SEARCHPARAMS_KEYS.SELECTED_PLAN);
    }
  }, [checkoutURL]);

  if (redirectToPayment) {
    return (
      <div className="w-screen h-screen">
        <PageLoader message="Please wait for a while. You are being redirected to payment" />
      </div>
    );
  }

  if (status !== RESPONSE_STATUS.LOADING) {
    return <Navigate to={redirectURL} />;
  }
}
