import { InputHTMLAttributes, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { FormErrorMessage } from "../../features/FormErrorMessage/FormErrorMessage";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

interface FormPasswordProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  placeholder: string;
  label: string;
  errorMessage?: string;
}

export default function FormPassword({
  label,
  errorMessage,
  ...inputProps
}: FormPasswordProps) {
  const [showPassword, setShowPassword] = useState(false);

  const { register } = useFormContext();

  const {
    fieldState: { error },
  } = useController({
    name: inputProps.name,
  });

  return (
    <div className="password_group">
      <label htmlFor={inputProps.name}>{label}</label>
      <div>
        <input
          {...inputProps}
          type={showPassword ? "text" : "password"}
          {...register(inputProps.name)}
        />
        <button
          type="button"
          className="me-2"
          onClick={() => {
            setShowPassword((prev) => !prev);
          }}
        >
          {showPassword ? (
            <VisibilityOffOutlinedIcon />
          ) : (
            <VisibilityOutlinedIcon />
          )}
        </button>
      </div>
      {error ? (
        <FormErrorMessage error={error} />
      ) : (
        errorMessage && <p className="error-msg">{errorMessage}</p>
      )}
    </div>
  );
}
