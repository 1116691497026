import ChangePassword from "../../components/AuthComponent/ChangePassword";
import ForgotPassword from "../../components/AuthComponent/ForgotPassword";
import Register from "../../components/AuthComponent/Register";
import VerifyAccount from "../../components/AuthComponent/VerifyAccount";

export const authPathConstants = {
  AUTH: "auth",
  FORGOT_PASSWORD: "reset",
  RESET_PASSWORD: "reset-password",
  VERIFY_PASSWORD: "verify-account",
};

export const authRoutes = [
  {
    path: `/${authPathConstants.AUTH}`,
    element: <Register />,
  },
  {
    path: `/${authPathConstants.FORGOT_PASSWORD}`,
    element: <ForgotPassword />,
  },
  {
    path: `/${authPathConstants.RESET_PASSWORD}/:token`,
    element: <ChangePassword />,
  },
  {
    path: `/${authPathConstants.VERIFY_PASSWORD}/:token`,
    element: <VerifyAccount />,
  },
];
