import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FeaturesContext } from "../../contexts/APIContext/Features/FeaturesContext";
import { useAppSelector } from "../../store";

export default function NotFound() {
  const isAuthenticated = useAppSelector(
    (state) => state.signin.isAuthenticated
  );
  const { redirectURL } = useContext(FeaturesContext);
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-3 w-screen h-screen justify-center items-center">
      <h3 className="text-[15rem] text-[#44a29e]">404</h3>
      <div className="flex gap-2">
        <Link
          className="link-button"
          to={isAuthenticated ? redirectURL : "/auth"}
        >
          Go to {isAuthenticated ? "dashboard" : "login"}
        </Link>
        <button className="link-button" onClick={() => navigate(-1)}>
          Go Back
        </button>
      </div>
    </div>
  );
}
