import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function usePagination() {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page") || 1;

  const [paginationModel, setPaginationModel] = useState({
    page: Number(pageParam) - 1,
    pageSize: 25,
  });

  const updatePageParams = (pageData: { page: number; pageSize: number }) => {
    setPaginationModel(pageData);
    const params = Object.fromEntries([...searchParams]);

    setSearchParams({
      ...params,
      page: `${pageData.page + 1}`,
    });
  };

  return {
    paginationModel,
    updatePageParams,
  };
}
