import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RESPONSE_STATUS } from "../../../enums/enums";
import { axiosInstance } from "../../../secutityUtils/axiosInstance";
import { Asset } from './industrySlice';

// Define an initial state
const initialState: {
  entries: Asset[];
  status: RESPONSE_STATUS;
  error: string;
  addStatus: RESPONSE_STATUS;
  hasChanges: boolean;
} = {
  entries: [],
  status: RESPONSE_STATUS.IDLE, // Can be 'idle', 'loading', 'succeeded', or 'failed'
  error: "",
  addStatus: RESPONSE_STATUS.IDLE,
  hasChanges: false,
};

export const fetchJobTitles = createAsyncThunk(
  "assets/fetchJobTitles",
  async () => {
    try {
      // Make the GET request with headers
      const response = await axiosInstance.get(
        `${import.meta.env.VITE_DEV_ADMIN_URL}/api/admin/assets/getAllJobTitles`
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const updateJobTitles = createAsyncThunk(
  "assets/updateJobTitles",
  async (updates: Asset[]) => {
    try {
      // Make the GET request with headers
      const response = await axiosInstance.put(
        `${import.meta.env.VITE_DEV_ADMIN_URL}/api/admin/assets/updateJobTitle`,
        updates
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const jobTitlesSlice = createSlice({
  name: "jobTitles",
  initialState,
  reducers: {
    fetchJobTitle: (state) => {
      state.entries = [];
      state.status = RESPONSE_STATUS.IDLE;
      state.error = "";
    },
    updateJobTitlesLocal: (state, action) => {
      state.hasChanges = true;
      state.entries = state.entries.map(entry => {
        if (entry.id === action.payload.id) {
          return action.payload;
        }
        return entry;
      })
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobTitles.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchJobTitles.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.entries = action.payload;
      })
      .addCase(fetchJobTitles.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        state.error = action.error.message || "";
      })
      .addCase(updateJobTitles.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(updateJobTitles.fulfilled, (state) => {
        state.hasChanges = false;
        state.status = RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(updateJobTitles.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        state.error = action.error.message || "";
      });
  },
});

// Export actions and reducer
export const { fetchJobTitle, updateJobTitlesLocal } = jobTitlesSlice.actions;
export default jobTitlesSlice.reducer;
