import CircularLoader from "./CircularLoader";

export default function PageLoader({ message }: { message: string }) {
  return (
    <div className="w-full text-2xl h-full flex justify-center gap-3 text-center items-center">
      <p className="mb-0">{message}</p>
      <CircularLoader />
    </div>
  );
}
