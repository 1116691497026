import { GridFilterModel } from "@mui/x-data-grid";

export const handleColumnFilters = (
  filter: GridFilterModel,
  setFilters: React.Dispatch<
    React.SetStateAction<
      {
        key: string;
        value: string;
      }[]
    >
  >
) => {
  const selectedFilter = filter.items[0];

  if (!filter.items.length) {
    setFilters([]);
    return;
  }

  if (selectedFilter?.value) {
    setFilters([
      { key: selectedFilter.field, value: selectedFilter.value },
      {
        key: `${selectedFilter.field}Operator`,
        value: selectedFilter.operator,
      },
    ]);
    return;
  }

  if (["isEmpty", "isNotEmpty"].includes(selectedFilter.operator)) {
    setFilters([
      {
        key: `${selectedFilter.field}Operator`,
        value: selectedFilter.operator,
      },
    ]);
    return;
  }

  if (
    selectedFilter?.field &&
    "value" in selectedFilter &&
    !selectedFilter?.value
  ) {
    setFilters([]);
  }
};
