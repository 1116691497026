const PricingCards = ({
  name,
  features,
  price,
  button,
  includes,
  onClick,
  disabled,
}: {
  name: string;
  features: string;
  price: string;
  button: string;
  includes: string[];
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <div className="pricing_card">
      <div className="card">
        <div className="card-body">
          <h5>{name}</h5>
          <p>{features}</p>
          <h3>{price}</h3>
          <div className="btn_body">
            <button disabled={disabled} onClick={onClick}>
              {button}
            </button>
          </div>

          <div className="list_sec">
            <h4>Includes</h4>
            <div className="list_item">
              <ul className="list">
                {includes.map((item, index) => (
                  <li key={index}>
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="5" cy="5" r="5" fill="#D9D9D9" />
                    </svg>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingCards;
