import { Fragment, useEffect, useState } from "react";
import InvoiceCard from "./Invoice-Card";
import { axiosInstance } from "../../secutityUtils/axiosInstance";
import { baseUrl, devURL } from "../../constants/userFlowConstants";
import useNotification from "../../hooks/useNotification";
import CenterLoader from "../Loaders/CenterLoader";

interface PFDResponseInterface {
  id: string;
  amountDue: number;
  amountPaid: number;
  paymentDate: string;
  pdf: string;
  periodEnd: string;
  periodStart: string;
  status: string;
  viewInvoice: string;
  invoiceNumber: string;
}

export default function Invoice() {
  const [pdfData, setPDFData] = useState<PFDResponseInterface[]>([]);
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();
  const fetchPDFData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${devURL}${baseUrl}getInvoices`
      );
      console.log("responsePDF", response.data);
      setPDFData(response.data);
      setLoading(false);
    } catch (error: any) {
      showNotification("error", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPDFData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <div className="flex py-4 flex-column">
        <div className="HeaderContainer mb-2">
          <div className="HeaderParent mt-1">
            <div className="left">
              <div className="primaryHeader my-0">My Invoices</div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap w-full gap-3">
          {pdfData.length > 0 &&
            pdfData.map((item) => (
              <InvoiceCard
                key={item.id}
                duration={
                  `${new Date(
                    Number(item.periodStart) * 1000
                  ).toLocaleDateString()} - ${new Date(
                    Number(item.periodEnd) * 1000
                  ).toLocaleDateString()}` || "NA"
                }
                paymentDate={
                  new Date(
                    Number(item.paymentDate) * 1000
                  ).toLocaleDateString() || "NA"
                }
                status={item.status || "NA"}
                amount={
                  (item.status === "paid" ? item.amountPaid : item.amountDue) ||
                  0
                }
                invoiceUrl={item.viewInvoice}
                invoiceNumber={item.invoiceNumber}
              />
            ))}

          {!loading && !pdfData.length && (
            <div className="secondaryHeader">No invoices found</div>
          )}

          {loading && <CenterLoader />}
        </div>
      </div>
    </Fragment>
  );
}
