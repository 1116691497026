import AdminNewHires from "./AdminNewHires/AdminNewHires.jsx";
import HeaderComponent from "./HeaderComponent/HeaderComponent.jsx";
import { generateAdminHeader } from "../../utils/adminFlow.js";
import ActivityLog from "./ActivityLog/ActivityLog.jsx";
import CreateProfile from "./CreateProfiles/CreateProfiles.jsx";
import AdminFunding from "./AdminFunding/AdminFunding.jsx";
import AdminJobs from "./AdminJobs/AdminJobs.jsx";
import AdminBusinessNews from "./AdminBusinessNews/AdminBusinessNews.jsx";
import AdminDashboard from "./AdminDashboard/AdminDashboard.jsx";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchDraftAnalytics } from '../../store/slice/adminSlice/adminHireSlice.js';
import { useAppDispatch, useAppSelector } from '../../store/index.js';

function AdminFlow({ selectedItem }: { selectedItem: string }) {
  const componentsMap: Record<string, () => JSX.Element> = {
    newHires: AdminNewHires,
    activityLog: ActivityLog,
    profiles: CreateProfile,
    funding: AdminFunding,
    jobs: AdminJobs,
    news: AdminBusinessNews,
    adminDashboard: AdminDashboard,
  };

  const { primaryText, secondaryText, buttonName, linkTo } =
    generateAdminHeader(selectedItem);

  const [selectedTab, setSelectedTab] = useState<string>();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab) {
      setSelectedTab(tab);
    } else {
      setSelectedTab(undefined);
    }
  }, [searchParams]);

  const dispatch = useAppDispatch();
  const draftMetrics = useAppSelector((state) => state.adminHires.metrics);

  useEffect(() => {
    dispatch(
      fetchDraftAnalytics(selectedItem)
    );
  }, [selectedItem, dispatch]);

  const SelectedComponent = componentsMap[selectedItem];
  return (
    <>
      <HeaderComponent
        primaryText={primaryText}
        secondaryText={secondaryText}
        buttonName={buttonName}
        selectedTab={selectedTab}
        linkTo={linkTo}
        draftMetrics={selectedTab === 'draft' ? draftMetrics : null}
      ></HeaderComponent>
      {SelectedComponent && <SelectedComponent />}
    </>
  );
}

export default AdminFlow;
