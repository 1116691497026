import { SEARCHPARAMS_KEYS, SUBSCRIPTION, USER_ROLE } from "../../enums/enums";
import { userData } from "../../interfaces/interfaces";
import { adminPathConstants } from "./adminRoutes";
import { dataPathConstants } from "./dataEntryRoutes";
import { userPathConstants } from "./userRoutes";

const dataPrefix = "/data";
const adminPrefix = "/admin";

export const updateRedirectURL = (user: userData) => {
  if (user.role === USER_ROLE.USER) {
    if (
      localStorage.getItem(SEARCHPARAMS_KEYS.SELECTED_PLAN) &&
      user.subscription !== SUBSCRIPTION.TIER_I
    ) {
      return `/${userPathConstants.PAYMENT}`;
    }
    if (user.showSubscription) {
      return `/${userPathConstants.PRICING_TABLE}`;
    }
    return `/${userPathConstants.MY_LEADS}?tab=hires`;
  } else if (user.role === USER_ROLE.DATA_ENTRY_OPERATOR) {
    return `${dataPrefix}/${dataPathConstants.DATA_HIRES}?page=1&count=25`;
  } else if (user.role === USER_ROLE.ADMIN) {
    return `${adminPrefix}/${adminPathConstants.DASHBOARD}`;
  } else {
    return `/${userPathConstants.PRICING_TABLE}`;
  }
};
