import { generateHeader } from "../../utils/dataFlow";
import DataHeader from "./DataHeader/DataHeader";
import ExecutiveHires from "./ExecutiveHires/ExecutiveHires";
import DataFunding from "./DataFunding/DataFunding";
import JobPosting from "./JobPosting/JobPosting";
import PressRelease from "./PressRelease/PressRelease";

const componentsMap: Record<string, () => JSX.Element> = {
  newExecutiveHires: ExecutiveHires,
  dataFunding: DataFunding,
  postJobs: JobPosting,
  businessNews: PressRelease,
};

function DataFlow({ selectedItem }: { selectedItem: string }) {
  const { primaryText, secondaryText, buttonName, linkTo } =
    generateHeader(selectedItem);
  const selectedHeader = selectedItem !== "myLeads" ? "All" : "myLeads";
  const SelectedComponent = componentsMap[selectedItem];
  return (
    <>
      <DataHeader
        primaryText={primaryText}
        secondaryText={secondaryText}
        selectedScreen={selectedHeader}
        buttonName={buttonName}
        linkTo={linkTo}
      />

      {SelectedComponent && <SelectedComponent />}
    </>
  );
}

export default DataFlow;
