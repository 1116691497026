import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  deleteDEOProfile,
  resetDeleteStatus,
  resetErrors,
} from "../../../store/slice/adminSlice/createProfileSlice";
import { ProfileData } from "../../../interfaces/interfaces";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  adminPath,
  adminPathConstants,
} from "../../../Routes/helper/adminRoutes";
import DeleteConfirmationModal from "../../DeleteConfirmationModal/DeleteConfirmationModal";
import { useContext, useEffect, useMemo, useState } from "react";
import useNotification from "../../../hooks/useNotification";
import { RESPONSE_STATUS } from "../../../enums/enums";
import LoadingContext from "../../../contexts/APIContext/Loading/LoadingContext";
import { filterPanel } from "../../../constants/filterPanelConstant";
import CustomGridPagination from "../../../features/CustomPagination/CustomGridPagination";

const EditCell = ({ id }: { id: string }) => {
  const navigate = useNavigate();
  const handleEditClick = (id: string) => {
    navigate(
      `/${adminPath}/${adminPathConstants.PROFILES}/${adminPathConstants.EDIT_PROFILE}/${id}`
    );
  };

  return (
    <div onClick={() => handleEditClick(id)} style={{ cursor: "pointer" }}>
      <EditIcon style={{ color: "#45A29E" }} />
    </div>
  );
};

const DeleteCell = ({ id }: { id: string }) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useAppDispatch();
  const { setIsLoading, isLoading } = useContext(LoadingContext);
  const { showNotification } = useNotification();
  const profiles = useAppSelector((state) => state.adminProfile);

  const handleDeleteClick = () => {
    setShowModal(true);
  };

  useEffect(() => {
    setIsLoading(profiles.deleteStatus === RESPONSE_STATUS.LOADING);

    if (
      profiles.deleteStatus === RESPONSE_STATUS.SUCCEEDED
    ) {
      showNotification("success", "User deleted successfully");
      setShowModal(false);
      setIsLoading(false);
      dispatch(resetDeleteStatus());
    }
    if (profiles.error && typeof profiles.error === "string") {
      showNotification("error", profiles.error);
      dispatch(resetErrors());
      dispatch(resetDeleteStatus());
    }
  }, [dispatch, profiles, setIsLoading, showNotification]);

  return (
    <>
      <div onClick={handleDeleteClick} style={{ cursor: "pointer" }}>
        <DeleteIcon style={{ color: "#45A29E" }} />
      </div>
      {showModal && (
        <DeleteConfirmationModal
          deleteFn={() => dispatch(deleteDEOProfile({ id }))}
          onClose={() => setShowModal(false)}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

function ProfileTable({ rowsData }: { rowsData: ProfileData[] }) {
  const columns: GridColDef<ProfileData>[] = useMemo(() => [
    {
      field: "verified",
      headerName: "",
      width: 10,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<ProfileData, string>) => (
        params.value  ? <VerifiedIcon/ > : <></>
      ),
    },
    {
      field: "firstName",
      headerName: "Name",
      width: 130,
      sortable: true,
      filterable: true,
      valueGetter: (_, row) => {
        const name = row.firstName ? [row.firstName] : [];
        if (row.lastName) {
          name.push(row.lastName);
        }

        return name.length > 0 ? name.join(' ') : 'N/A';
      }
    },
    { field: "phoneNumber", headerName: "Phone Number", width: 200 },
    { field: "email", headerName: "E-mail", width: 200 },
    {
      field: "role",
      headerName: "Role",
      width: 150,
      sortable: true,
      filterable: true,
      type: 'singleSelect',
      valueOptions: ['ADMIN', 'USER', 'DATA_ENTRY_OPERATOR']
    },
    {
      field: "subscription",
      headerName: "Subscription",
      width: 150,
      sortable: true,
      filterable: true,
      type: 'singleSelect',
      valueOptions: ['FREE', 'Tier1', 'Tier2']
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        params.row.role === 'DATA_ENTRY_OPERATOR' ? <EditCell id={params.row.id} /> : <></>
      ), // Pass the id to EditCell
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 100,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <DeleteCell id={params.row.id} />
      ),
    },
  ], []);

  return (
    <div className="tableParent">
      <div className="min-h-[300px] h-[calc(100vh-300px)] py-[20px]">
        <DataGrid
          rows={rowsData}
          columns={columns}
          filterDebounceMs={400}
          slots={{
            loadingOverlay: () => <></>,
            pagination: () => <CustomGridPagination />,
            toolbar: GridToolbar
          }}
          isRowSelectable={() => false}
          slotProps={{
            filterPanel,
          }}
          disableRowSelectionOnClick
        />
      </div>
    </div>
  );
}

export default ProfileTable;
