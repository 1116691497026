import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useState } from "react";

export default function CancelButton() {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);

  return (
    <Button
      style={{
        backgroundColor: hover ? "#fff" : "rgba(0,0,0,0.8)",
        color: hover ? "rgba(0,0,0,0.8)" : "#fff",
        border: "1px solid black",
      }}
      variant={"contained"}
      onClick={() => navigate(-1)}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      Cancel
    </Button>
  );
}
