import { useEffect } from "react";
import { resetErrors, signupUser } from "../../store/slice/signinSlice";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import { useAppDispatch, useAppSelector } from "../../store/index";
import {
  useForm,
  SubmitHandler,
  FormProvider,
  useWatch,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { signUpSchema } from "./helper/SignUp.schema";
import { SignUpInterface } from "./helper/SignUp.interface";
import ButtonCustom from "../../features/ButtonCustom/ButtonCustom";
import { RESPONSE_STATUS, SEARCHPARAMS_KEYS } from "../../enums/enums";
import useNotification from "../../hooks/useNotification";
import FormInput from "../FormComponents/FormInput";
import CircularLoader from "../Loaders/CircularLoader";
import FormPassword from "../FormComponents/FormPassword";

export default function SignUp() {
  const dispatch = useAppDispatch();

  let authStatus = useAppSelector((state) => state.signin.signstatus);
  const error = useAppSelector((state) => state.signin.error);

  const { showNotification } = useNotification();

  const form = useForm<SignUpInterface>({
    resolver: yupResolver(signUpSchema),
  });

  useEffect(() => {
    if (authStatus === RESPONSE_STATUS.SUCCEEDED) {
      form.reset();
      showNotification(
        "success",
        "An email has been sent to your email address. Please verify to sign in",
        10000
      );
    } else if (error && typeof error === "string") {
      showNotification("error", error);
      dispatch(resetErrors());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus, error]);

  const onSubmit: SubmitHandler<SignUpInterface> = (formdata) => {
    dispatch(
      signupUser({
        user: formdata,
        selectedPlan: localStorage.getItem(SEARCHPARAMS_KEYS.SELECTED_PLAN)
          ? "pro"
          : "",
      })
    );
  };

  const email = useWatch({ control: form.control, name: "email" });

  useEffect(() => {
    if (email) {
      form.setValue("email", email.toLowerCase());
    }
  }, [email, form]);

  return (
    <FormProvider {...form}>
      <h2>Sign up</h2>
      <p className="content">Create your account</p>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        onChange={() => dispatch(resetErrors())}
      >
        <div className="grid md:grid-cols-2 gap-2">
          <FormInput
            name="firstName"
            placeholder="Enter First Name"
            label="First Name"
            autoComplete="firstName"
            errorMessage={typeof error !== "string" ? error["firstName"] : ""}
          />
          <FormInput
            name="lastName"
            placeholder="Enter Last Name"
            label="Last Name"
            autoComplete="lastName"
            errorMessage={typeof error !== "string" ? error["lastName"] : ""}
          />
        </div>
        <FormInput
          name="email"
          placeholder="Enter Email"
          label="Email"
          autoComplete="username"
          errorMessage={typeof error !== "string" ? error["email"] : ""}
        />
        <FormPassword
          type="password"
          name="password"
          placeholder="Enter Password"
          label="Password"
          autoComplete="new-password"
          errorMessage={typeof error !== "string" ? error["password"] : ""}
        />
        <FormPassword
          name="confirmPassword"
          placeholder="Enter Password Again"
          label="Confirm Password"
          errorMessage={
            typeof error !== "string" ? error["confirmPassword"] : ""
          }
        />

        <div className="bottom_buttons">
          <ButtonCustom
            className="button_submit"
            type="submit"
            endIcon={
              authStatus === RESPONSE_STATUS.LOADING ? (
                <CircularLoader size={20} />
              ) : (
                <ArrowRightIcon />
              )
            }
          >
            Sign Up
          </ButtonCustom>
        </div>
      </form>
    </FormProvider>
  );
}
