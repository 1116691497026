import { useContext } from "react";
import "./register.scss";
import RegisterLeft from "./RegisterLeft";
import SignUp from "./SignUp";
import SignIn from "./Signin";
import Header from "../LandingPage/header/Header";
import Footer from "../LandingPage/Footer/Footer";
import { FeaturesContext } from "../../contexts/APIContext/Features/FeaturesContext";
import { useAppDispatch } from "../../store";
import { logoutUser } from "../../store/slice/signinSlice";

const Register = () => {
  const { signinTab, setSigninTab } = useContext(FeaturesContext);
  const dispatch = useAppDispatch();

  return (
    <>
      <Header />
      <div className="register_main">
        <div className="container h-full">
          <div className="row align-items-center justify-content-center h-full">
            <RegisterLeft />
            <div className="col-lg-6">
              <div className="form_card">
                <div className="button_group">
                  <button
                    type="button"
                    className={
                      signinTab === "signin"
                        ? "button_primary"
                        : "button_secondary"
                    }
                    onClick={() => {
                      dispatch(logoutUser());
                      setSigninTab("signin");
                    }}
                  >
                    Sign In
                  </button>
                  <button
                    type="button"
                    className={
                      signinTab === "signup"
                        ? "button_primary"
                        : "button_secondary"
                    }
                    onClick={() => {
                      dispatch(logoutUser());
                      setSigninTab("signup");
                    }}
                  >
                    Sign Up
                  </button>
                </div>

                {signinTab === "signup" ? <SignUp /> : <SignIn />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Register;
