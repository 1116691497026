import * as Yup from "yup";
import { regex } from "../regex/regex";
import { companyValidationSchema } from "./company-validation-schema";

export const newsValidationSchema = (condition: boolean) =>
  Yup.object()
    .shape({
      trigger: Yup.string()
        .required("Trigger is required")
        .min(2, "Trigger must have at least 2 characters")
        .max(40, "Trigger must be under 40 characters")
        .trim(),
      triggerArticle: Yup.string()
        .required("Trigger Article is required")
        .matches(regex.urlFormat, "Please enter a valid URL!")
        .trim(),
      categories: Yup.string()
        .when([], {
          is: condition,
          then: (schema) => {
            if (condition) {
              return schema
                .required("Category is required")
                .min(2, "Category must have at least 2 characters")
                .max(40, "Category must be under 40 characters");
            }
            return schema.optional();
          },
        })
        .trim(),
    })
    .concat(companyValidationSchema);
