import { useEffect, useState } from "react";
import { Theme, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import GroupsIcon from "@mui/icons-material/Groups";
import PaidIcon from "@mui/icons-material/Paid";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import SummarizeIcon from "@mui/icons-material/Summarize";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import "./Menu.scss";
import { Link, useLocation } from "react-router-dom";
import { generatePathBasedOnUser } from "../../../utils/userFlow";
import { userFlowScreens } from "../../../constants/userFlowConstants";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import WorkIcon from "@mui/icons-material/Work";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useAppSelector } from "../../../store";
import { SEARCHPARAMS_KEYS, USER_ROLE } from "../../../enums/enums";
import { IconButton, SvgIconTypeMap, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import logoImg from "../../../assets/images/logo.png";
import usePagination from "../../../hooks/usePagination";
import {
  adminPath,
  adminPathConstants,
} from "../../../Routes/helper/adminRoutes";
import { userPathConstants } from "../../../Routes/helper/userRoutes";

const openedMixin = (theme: Theme) => ({
  width: "100%",
  height: "100%",
  [theme.breakpoints.up(768)]: {
    width: "260px",
    height: "calc(100vh - 130px)",
  },
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
  [theme.breakpoints.up(768)]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: 0,
  [theme.breakpoints.up(768)]: {
    width: "68px",
  },
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiDrawer-paper": open ? openedMixin(theme) : closedMixin(theme),
}));

export default function Menu() {
  const user = useAppSelector((state) => state.signin.user);
  const role = useAppSelector((state) => state.signin.role);

  const { paginationModel } = usePagination();

  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);
  const [itemsToBeRendered, setItemsToBeRendered] = useState<
    Array<{
      text: string;
      logo: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
        muiName: string;
      };
      path: string;
    }>
  >([]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const MenuItems = [
      {
        text: "My Leads",
        logo: LeaderboardIcon,
        path: generatePathBasedOnUser(role, userFlowScreens[4]),
      },
      {
        text: "New Hires",
        logo: GroupsIcon,
        path: generatePathBasedOnUser(role, userFlowScreens[0]),
      },
      {
        text: "Funding",
        logo: PaidIcon,
        path: generatePathBasedOnUser(role, userFlowScreens[1]),
      },
      {
        text: "Job Postings",
        logo: PersonSearchIcon,
        path: generatePathBasedOnUser(role, userFlowScreens[2]),
      },
      {
        text: "Company Press Releases",
        logo: SummarizeIcon,
        path: generatePathBasedOnUser(role, userFlowScreens[3]),
      },
    ];

    const dataMenuItems = [
      {
        text: "New Executive Hires",
        logo: GroupsIcon,
        path: generatePathBasedOnUser(role, "dataHires"),
      },
      {
        text: "Funding",
        logo: PaidIcon,
        path: generatePathBasedOnUser(role, "dataFunding"),
      },
      {
        text: "Job Postings",
        logo: PersonSearchIcon,
        path: generatePathBasedOnUser(role, "postJobs"),
      },
      {
        text: "Company Press Releases",
        logo: SummarizeIcon,
        path: generatePathBasedOnUser(role, "businessNews"),
      },
    ];

    const adminMenuItems = [
      {
        text: "Admin Dashboard",
        logo: DashboardIcon,
        path: generatePathBasedOnUser(role, "dashboard"),
      },
      {
        text: "Users",
        logo: ManageAccountsIcon,
        path: generatePathBasedOnUser(role, "profiles"),
      },
      // {
      //   text: "Activity Log",
      //   logo: ArticleIcon,
      //   path: generatePathBasedOnUser(role, "activityLog"),
      // },
      {
        text: "Hires",
        logo: GroupAddIcon,
        path: generatePathBasedOnUser(role, "newHires"),
      },
      {
        text: "Funding",
        logo: PaidIcon,
        path: generatePathBasedOnUser(role, "funding"),
      },
      {
        text: "Jobs",
        logo: WorkIcon,
        path: generatePathBasedOnUser(role, "jobs"),
      },
      {
        text: "Business News",
        logo: SummarizeIcon,
        path: generatePathBasedOnUser(role, "news"),
      },
    ];
    if (role === USER_ROLE.DATA_ENTRY_OPERATOR) {
      setItemsToBeRendered(dataMenuItems);
    } else if (role === USER_ROLE.USER) {
      setItemsToBeRendered(MenuItems);
    } else {
      setItemsToBeRendered(adminMenuItems);
    }
  }, [role]);

  if (!user) return <></>;

  return (
    <Box className="overflow-hidden">
      <div className="left-2 absolute top-1">
        <Toolbar>
          {!open && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(true)}
              edge="start"
              className="md:invisible"
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </div>

      <Drawer
        variant="permanent"
        open={open}
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
        className={`${!open && "closed-drawer"} `}
      >
        <div className="flex justify-between w-full items-center my-2 md:invisible">
          <span className="logoContainer ml-6 text-lg sm:text-2xl">
            <img className="logoImage" src={logoImg} alt="Logo" />
            <span className="sm:font-bold font-semibold">Lead</span>Hawk
          </span>
          <div className="mr-4">
            <IconButton
              color="inherit"
              aria-label="close drawer"
              onClick={() => setOpen(false)}
              edge="start"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <List>
          {itemsToBeRendered.map((ele) => {
            const excludePaths = [
              `/${adminPath}/${adminPathConstants.DASHBOARD}`,
              `/${adminPath}/${adminPathConstants.PROFILES}`,
              `/${adminPath}/${adminPathConstants.ACTIVITY_LOG}`,
              `/${userPathConstants.MY_LEADS}`,
            ];
            let path = excludePaths.includes(ele.path)
              ? ele.path
              : `${ele.path}?page=1&count=${paginationModel.pageSize}`;

            if (
              user?.showSubscription &&
              !localStorage.getItem(SEARCHPARAMS_KEYS.SELECTED_PLAN)
            ) {
              path = `/${userPathConstants.PRICING_TABLE}`;
            }

            const livePages = [
              `/${adminPath}/${adminPathConstants.FUNDING}`,
              `/${adminPath}/${adminPathConstants.JOBS}`,
              `/${adminPath}/${adminPathConstants.NEW_HIRES}`,
              `/${adminPath}/${adminPathConstants.NEWS}`,
            ];

            if (livePages.includes(ele.path)) {
              path = `${ele.path}?page=1&count=${paginationModel.pageSize}&tab=live`;
            }

            if (ele.path === `/${userPathConstants.MY_LEADS}`) {
              path = `/${userPathConstants.MY_LEADS}?tab=hires`;
            }

            return (
              <ListItem
                key={ele.text}
                disablePadding
                sx={{
                  display: "block",
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 60,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  component={Link}
                  to={path}
                  selected={pathname.includes(ele.path)}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      fontSize: "2rem",
                      height: "2rem",
                      width: "1.5rem",
                      justifyContent: "center",
                      color: pathname.includes(ele.path) ? "teal" : "black",
                    }}
                  >
                    <ele.logo></ele.logo>
                  </ListItemIcon>
                  <ListItemText
                    primary={ele.text}
                    sx={{ opacity: open ? 1 : 0, fontSize: 10 }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Drawer>
    </Box>
  );
}
