import { ReactNode, useContext, useEffect, useRef } from "react";
import { useAppSelector } from "../../store";
import { Navigate } from "react-router-dom";
import { FeaturesContext } from "../../contexts/APIContext/Features/FeaturesContext";
import CenterLoader from "../../components/Loaders/CenterLoader";
import useNotification from "../../hooks/useNotification";

export default function UnAuthGuard({ children }: { children: ReactNode }) {
  const isAuthenticated = useAppSelector(
    (state) => state.signin.isAuthenticated
  );
  const { redirectURL } = useContext(FeaturesContext);
  const { showNotification } = useNotification();
  const ref = useRef<number>(0);

  useEffect(() => {
    if (isAuthenticated) {
      ref.current++;
    }
    if (ref.current > 1) {
      showNotification("error", "Access Denied");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return (
      <>
        <CenterLoader />
        {redirectURL && <Navigate to={redirectURL} />}
      </>
    );
  } else {
    return children;
  }
}
