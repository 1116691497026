import "./contactUs.scss";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import Header from "../LandingPage/header/Header";
import Footer from "../LandingPage/Footer/Footer";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactUsValidationSchema } from "../../validators/contactUs-validation-schema";
import FormInput from "../FormComponents/FormInput";
import TextAreaInput from "../FormComponents/TextAreaInput";
import ButtonCustom from "../../features/ButtonCustom/ButtonCustom";
import { contactForm, resetErrors } from "../../store/slice/signinSlice";
import { useAppDispatch, useAppSelector } from "../../store";
import { ContactUsValues } from "../../interfaces/interfaces";
import { RESPONSE_STATUS } from "../../enums/enums";
import useNotification from "../../hooks/useNotification";
import { useEffect, useState } from "react";
import CircularLoader from "../Loaders/CircularLoader";

const ContactUs = () => {
  const user = useAppSelector((state) => state.signin.user);
  const contactValues = useAppSelector((state) => state.signin);
  const form = useForm<ContactUsValues>({
    resolver: yupResolver(contactUsValidationSchema),
  });
  const dispatch = useAppDispatch();
  const { showNotification } = useNotification();

  const [dispatchStatus, setDispatchStatus] = useState(false);

  useEffect(() => {
    if (dispatchStatus && contactValues.status === RESPONSE_STATUS.SUCCEEDED) {
      form.reset();
      showNotification("success", `Mail sent successfully`);
    }
    if (contactValues.error && typeof contactValues.error === "string") {
      showNotification("error", contactValues.error);
      dispatch(resetErrors());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactValues, dispatchStatus, form]);

  const handleContact: SubmitHandler<ContactUsValues> = (data) => {
    dispatch(contactForm({ data, type: "contact-us" }));
    setDispatchStatus(true);
  };

  return (
    <>
      <Header />
      <div className="contact_main">
        <div className="container h-full">
          <div className="row align-items-center justify-content-center h-full">
            <div className="col-lg-6 d-flex flex-column align-items-center justify-content-center">
              <div className="center_img_text">
                <div className="title_section">
                  <h1>contact Us</h1>
                  <p>
                    Use the contact form to get in touch. We’ll get back to you
                    ASAP.
                  </p>
                </div>
                <img
                  src="/assets/images/icon_3.svg"
                  alt=""
                  className="left_icon img-fluid"
                />
              </div>
            </div>
            <FormProvider {...form}>
              <div className="col-lg-6">
                <div className="form_card">
                  <form
                    onSubmit={form.handleSubmit(handleContact)}
                    onChange={() => dispatch(resetErrors())}
                  >
                    <div className="grid md:grid-cols-2 gap-3 mb-3">
                      <FormInput
                        name="firstName"
                        placeholder="Enter First Name"
                        label="First Name"
                        autoComplete="firstName"
                        errorMessage={
                          typeof contactValues.error !== "string"
                            ? contactValues.error["firstName"]
                            : ""
                        }
                        defaultValue={user?.firstName}
                      />
                      <FormInput
                        name="lastName"
                        placeholder="Enter Last Name"
                        label="Last Name"
                        autoComplete="lastName"
                        errorMessage={
                          typeof contactValues.error !== "string"
                            ? contactValues.error["lastName"]
                            : ""
                        }
                        defaultValue={user?.lastName}
                      />

                      <FormInput
                        name="email"
                        placeholder="Enter Email"
                        label="Email"
                        autoComplete="email"
                        errorMessage={
                          typeof contactValues.error !== "string"
                            ? contactValues.error["email"]
                            : ""
                        }
                        defaultValue={user?.email}
                      />
                      <FormInput
                        name="phoneNumber"
                        placeholder="Enter Phone Number"
                        label="Phone Number"
                        autoComplete="phoneNumber"
                        errorMessage={
                          typeof contactValues.error !== "string"
                            ? contactValues.error["phoneNumber"]
                            : ""
                        }
                        defaultValue={user?.phoneNumber}
                      />
                    </div>

                    <FormInput
                      name="subject"
                      placeholder="Enter Subject"
                      label="Subject"
                      autoComplete="subject"
                      errorMessage={
                        typeof contactValues.error !== "string"
                          ? contactValues.error["subject"]
                          : ""
                      }
                    />
                    <div className="mt-3">
                      <TextAreaInput
                        placeholder="Type your message..."
                        label="How can we help you?"
                        name="message"
                        errorMessage={
                          typeof contactValues.error !== "string"
                            ? contactValues.error["message"]
                            : ""
                        }
                      />
                    </div>
                    <div className="bottom_buttons">
                      <ButtonCustom
                        type="submit"
                        className="button_submit"
                        endIcon={
                          !contactValues.error &&
                          contactValues.status === RESPONSE_STATUS.LOADING ? (
                            <CircularLoader size={20} />
                          ) : (
                            <ArrowRightIcon />
                          )
                        }
                        disabled={
                          contactValues.status === RESPONSE_STATUS.LOADING
                        }
                      >
                        Get In Touch
                      </ButtonCustom>
                    </div>
                  </form>
                </div>
              </div>
            </FormProvider>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
