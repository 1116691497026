/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CircularProgress,
  Autocomplete as MuiAutocomplete,
  TextField,
} from "@mui/material";
import { mapTofilterCommonTitles } from "../../../../constants/userFlowConstants";
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { saveAppliedFilters } from "../../../../store/slice/filterSlice";
import { useLocation } from "react-router-dom";
import { axiosInstance } from "../../../../secutityUtils/axiosInstance";
import { useAppDispatch } from "../../../../store";
import { userPathConstants } from "../../../../Routes/helper/userRoutes";
import { useDebounce } from "@uidotdev/usehooks";

const optionsCache: {
  [key: string]: string[];
} = {};

export default function Autocomplete({
  fieldName,
  value = [],
  clear,
}: {
  fieldName: string;
  value: string[];
  clear: boolean;
}) {
  const [fieldDbName] = useState(mapTofilterCommonTitles[fieldName]);
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);
  const [pageFieldValue, setPageFieldValue] = useState<string[]>(value);
  const [options, setOptions] = useState<string[]>(value);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<string>();
  const [inputValue, setInputValue] = useState<string>("");
  const location = useLocation();
  const dispatch = useAppDispatch();
  const debouncedSearchTerm = useDebounce(inputValue, 300);

  useEffect(() => {
    const pagePath = location.pathname.split("/")[1];

    if (pagePath.includes(userPathConstants.NEW_HIRES)) {
      setPage("HIRES");
    } else if (pagePath.includes(userPathConstants.FUNDINGS)) {
      setPage("FUNDING");
    } else if (pagePath.includes(userPathConstants.JOB_POSTINGS)) {
      setPage("JOBS");
    } else if (pagePath.includes(userPathConstants.PRESS_RELEASES)) {
      setPage("NEWS");
    }
  }, [location]);

  useEffect(() => {
    if (clear) {
      setPageFieldValue([]);
    }
  }, [clear]);

  const doSearch = useCallback(
    async function (searchString?: string) {
      // Create a unique key to store options
      const cacheKey = `${page}_${fieldDbName}`;

      // Check if options are already cached and search string is empty
      if (optionsCache[cacheKey] && !searchString) {
        setOptions(optionsCache[cacheKey]);
        return;
      }

      setLoading(true);
      const url = new URL(
        `${import.meta.env.VITE_DEV_USER_URL}/api/v1/searchFilters/${page}`
      );
      url.searchParams.append("fieldToSearch", fieldDbName);
      if (searchString) {
        url.searchParams.append("searchString", searchString);
      }
      const options = await axiosInstance.get(url.toString());

      const newOptions = Array.from(
        new Set([...options.data, ...pageFieldValue]).values()
      );
      // To store first set of options in cache and to not get overwritten if searched something
      if (!searchString) {
        optionsCache[cacheKey] = newOptions;
      }

      setOptions(newOptions);
      setLoading(false);
    },
    [fieldDbName, page, pageFieldValue]
  );

  useEffect(() => {
    if (!page || !fieldDbName) {
      return;
    }

    doSearch(debouncedSearchTerm);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, fieldDbName, debouncedSearchTerm]);

  const inputEvent = (
    _: SyntheticEvent<Element, Event>,
    innervalue: string[]
  ) => {
    // Debounce the dispatch to save the selected filters to the redux store
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    const updatedFiltersCopy = { [fieldDbName]: innervalue };
    setPageFieldValue(innervalue);

    debounceTimer.current = setTimeout(() => {
      dispatch(saveAppliedFilters(updatedFiltersCopy));
    }, 500);
  };

  return (
    <MuiAutocomplete
      id="multiple-limit-tags"
      suppressContentEditableWarning
      fullWidth
      multiple
      limitTags={2}
      size="small"
      options={options}
      value={pageFieldValue}
      loading={loading}
      onChange={inputEvent}
      filterOptions={(options, state) => 
        options
          .filter(option =>
              option.toLowerCase().includes(state.inputValue.toLowerCase()))}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={fieldName}
          InputProps={{
            ...params.InputProps,
            style: {
              minWidth: "50px",
            },
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
