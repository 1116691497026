const JobPostings = () => {
  return (
    <div className="newhires_wrapper postings_wrapper">
      <div className="container">
        <div className="row align-items-center newhires_main">
          <div className="col-md-6">
            <img
              src="/assets/images/posting-image.svg"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-6 funding_right">
            <button className="newhires_btn">Job Postings</button>
            <h1 className="newhires_heading text-capitalize">
              See new job Postings
            </h1>
            <p className="jobposting_desc">
              Recent executive job postings in LeadHawk are sourced from the
              major job boards. Skim through the job description and find your
              next deal{" "}
              <b>
                <u>before</u>
              </b>{" "}
              they bring on your champion.
            </p>
          </div>
        </div>
      </div>
      <img className="bg_element" src="/assets/images/job_posting.webp" alt="Job Posting" />
    </div>
  );
};

export default JobPostings;
