import AddEditFunds from "../../components/AddEditFunds/AddEditFunds";
import AddEditHires from "../../components/AddEditHires/AddEditHires";
import AddEditNews from "../../components/AddEditNews/AddEditNews";
import AddEditJobs from "../../components/AddEditJobs/AddEditJobs";
import DataFlow from "../../components/DataOperatorFlow/DataFlow";

// const userRole = "/dataEntry";
export const DATA_ENTRY = "data";

export const dataPathConstants = {
  DATA_HIRES: "dataHires",
  DATA_FUNDING: "dataFunding",
  POST_JOBS: "postJobs",
  BUSINESS_NEWS: "businessNews",
  ADD_FUNDS: "add-funds",
  EDIT_FUNDS: "edit-funds",
  ADD_HIRES: "add-hires",
  EDIT_HIRES: "edit-hires",
  ADD_JOBS: "add-jobs",
  EDIT_JOBS: "edit-jobs",
  ADD_NEWS: "add-news",
  EDIT_NEWS: "edit-news",
};

export const dataEntryRoutes = [
  {
    path: `/${DATA_ENTRY}/${dataPathConstants.DATA_HIRES}`,
    element: <DataFlow selectedItem="newExecutiveHires" />,
  },
  {
    path: `/${DATA_ENTRY}/${dataPathConstants.DATA_FUNDING}`,
    element: <DataFlow selectedItem={dataPathConstants.DATA_FUNDING} />,
  },
  {
    path: `/${DATA_ENTRY}/${dataPathConstants.POST_JOBS}`,
    element: <DataFlow selectedItem={dataPathConstants.POST_JOBS} />,
  },
  {
    path: `/${DATA_ENTRY}/${dataPathConstants.BUSINESS_NEWS}`,
    element: <DataFlow selectedItem={dataPathConstants.BUSINESS_NEWS} />,
  },
  {
    path: `/${DATA_ENTRY}/${dataPathConstants.DATA_HIRES}/${dataPathConstants.ADD_HIRES}`,
    element: <AddEditHires />,
  },
  {
    path: `/${DATA_ENTRY}/${dataPathConstants.DATA_FUNDING}/${dataPathConstants.ADD_FUNDS}`,
    element: <AddEditFunds />,
  },
  {
    path: `/${DATA_ENTRY}/${dataPathConstants.POST_JOBS}/${dataPathConstants.ADD_JOBS}`,
    element: <AddEditJobs />,
  },
  {
    path: `/${DATA_ENTRY}/${dataPathConstants.BUSINESS_NEWS}/${dataPathConstants.ADD_NEWS}`,
    element: <AddEditNews />,
  },
  {
    path: `/${DATA_ENTRY}/${dataPathConstants.BUSINESS_NEWS}/${dataPathConstants.EDIT_NEWS}/:id`,
    element: <AddEditNews />,
  },
  {
    path: `/${DATA_ENTRY}/${dataPathConstants.POST_JOBS}/${dataPathConstants.EDIT_JOBS}/:id`,
    element: <AddEditJobs />,
  },
  {
    path: `/${DATA_ENTRY}/${dataPathConstants.DATA_FUNDING}/${dataPathConstants.EDIT_FUNDS}/:id`,
    element: <AddEditFunds />,
  },
  {
    path: `/${DATA_ENTRY}/${dataPathConstants.DATA_HIRES}/${dataPathConstants.EDIT_HIRES}/:id`,
    element: <AddEditHires />,
  },
];
