import * as Yup from "yup";
import { transformToNull } from "./helper/transformToNull";
import { companyValidationSchema } from "./company-validation-schema";

export const fundsValidationSchema = Yup.object()
  .shape({
    fundingRaised: Yup.number()
      .required("Funding Raised is required")
      .transform(transformToNull)
      .min(0, "Funding Raised cannot be a negative number")
      .max(
        Number.MAX_SAFE_INTEGER,
        `Funding Raised must be less than ${Number.MAX_SAFE_INTEGER}`
      ),
  })
  .concat(companyValidationSchema);
