import { useEffect, useState } from "react";
import {
  fetchAdminJobs,
  fetchDraftAdminJobs,
} from "../../../store/slice/adminSlice/adminJobSlice";
import JobTable from "../Tables/JobTable";
import { useAppDispatch, useAppSelector } from "../../../store";
import usePagination from "../../../hooks/usePagination";
import { useSearchParams } from "react-router-dom";
import { draftTabs } from "../../../constants/adminConstant";
import { Tab, Tabs } from "@mui/material";
import AdminTabPanel from "../AdminTabPanel/AdminTabPanel";
import DraftJobTable from "../Tables/DraftJobTable";
import { handleColumnFilters } from "../../../utils/handleColumnFilters";

function AdminJobs() {
  const dispatch = useAppDispatch();
  const entries = useAppSelector((state) => state.adminJob.entries);
  const draftEntries = useAppSelector((state) => state.adminJob.draftEntries);
  const [searchParams, setSearchParams] = useSearchParams();

  const { paginationModel, updatePageParams } = usePagination();

  const [filters, setFilters] = useState<{ key: string; value: string }[]>([]);

  const index = draftTabs.findIndex((tab) => searchParams.get("tab") === tab);
  const [value, setValue] = useState(index < 0 ? 0 : index);

  const handleChange = (
    _: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setValue(newValue);
    updatePageParams({ page: 0, pageSize: 25 });
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: "1",
      tab: draftTabs[newValue],
    });
    setFilters([]);
  };

  useEffect(() => {
    if (index < 0) {
      setValue(0);
      return;
    }

    setValue(index);
  }, [index]);

  useEffect(() => {
    if (value === 0) {
      // Dispatch the fetchMyEntries action when the component mounts
      dispatch(
        fetchAdminJobs({
          page: paginationModel.page,
          count: paginationModel.pageSize,
          filters: filters,
        })
      );
    } else {
      dispatch(
        fetchDraftAdminJobs({
          page: paginationModel.page,
          count: paginationModel.pageSize,
        })
      );
    }
  }, [dispatch, paginationModel, filters, value]);

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          overflow: "scroll",
        }}
      >
        {draftTabs.map((tab, index) => {
          return <Tab label={tab} key={index} />;
        })}
      </Tabs>
      <AdminTabPanel value={value} index={0}>
        <JobTable
          rowsData={entries}
          paginationModel={paginationModel}
          setPaginationModel={updatePageParams}
          onFilterChange={(filters) => handleColumnFilters(filters, setFilters)}
        />
      </AdminTabPanel>
      <AdminTabPanel value={value} index={1}>
        <DraftJobTable
          rowsData={draftEntries}
          paginationModel={paginationModel}
          setPaginationModel={updatePageParams}
        />
      </AdminTabPanel>
    </>
  );
}

export default AdminJobs;
