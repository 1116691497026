export const filterPanel = {
  sx: {
    "& .MuiDataGrid-filterForm": {
      width: "100%",
      flexDirection: `${window.innerWidth < 640 ? "column" : "row"}`,
      padding: "20px",
    },
    "& .MuiDataGrid-filterFormDeleteIcon": {
      position: "absolute",
      top: "-11px",
      right: "-11px",
      background: "#fff",
      borderRadius: "50%",
      boxShadow: "0 0 12px 0 rgba(0,0,0,0.4)",
      margin: "0px",
    },
    "& .MuiDataGrid-filterFormColumnInput": {
      width: `${window.innerWidth < 640 ? "100%" : "auto"}`,
    },
    "& .MuiDataGrid-filterFormOperatorInput": {
      width: `${window.innerWidth < 640 ? "100%" : "auto"}`,
      mt: `${window.innerWidth < 640 ? "10px" : "0px"}`,
    },
    "& .MuiDataGrid-filterFormValueInput": {
      width: `${window.innerWidth < 640 ? "100%" : "150px"}`,
      mt: `${window.innerWidth < 640 ? "10px" : "0px"}`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      borderBottom: "1px solid #0000006b",
      borderRadius: 0,
    },
    "& .MuiAutocomplete-inputRoot": {
      padding: "8px 0 0",
      margin: "0px",
    },
    "& .MuiInputLabel-outlined": {
      left: "-12px",
    },
  },
};
