import * as Sentry from '@sentry/react';

export function sentryInit() {
  if (import.meta.env.VITE_SENTRY_ENV) {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.VITE_SENTRY_ENV,
      debug: false,
      tracesSampleRate: 0.1,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 0.1,
    });
  }
}
