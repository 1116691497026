import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertColor } from "@mui/material/Alert";
import { ReactNode } from "react";

function Notification({
  severity,
  message,
  open,
  handleClose,
  duration,
  action,
}: {
  severity: AlertColor;
  message: string;
  open: boolean;
  handleClose: () => void;
  duration: number;
  action?: ReactNode;
}) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      className="snack-bar"
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
        {action}
      </Alert>
    </Snackbar>
  );
}

export default Notification;
