import React, { ReactNode } from "react";
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import { useParams } from "react-router-dom";
import { rows } from "../../../constants/adminConstant";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

function CustomTabPanel(props: {
  children: ReactNode;
  value: number;
  index: number;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// CustomTabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

function UserDataInfo() {
  const { id } = useParams();

  const user = rows.find((row) => row.id === Number(id));
  const primaryText = "Data Operator Activity Log > " + user?.name;

  const [value, setValue] = React.useState(0);

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <HeaderComponent primaryText={primaryText} />
      <Box sx={{ width: "100%" }}>
        <div>
          <Button
            variant={value === 0 ? "contained" : "outlined"}
            onClick={() => handleChange(0)}
          >
            New Executive Hires
          </Button>
          <Button
            variant={value === 1 ? "contained" : "outlined"}
            onClick={() => handleChange(1)}
          >
            Job Posting
          </Button>
          <Button
            variant={value === 2 ? "contained" : "outlined"}
            onClick={() => handleChange(2)}
          >
            Business News
          </Button>
        </div>
        <CustomTabPanel value={value} index={0}>
          Item One Content
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          Item Two Content
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          Item Three Content
        </CustomTabPanel>
      </Box>
    </>
  );
}

export default UserDataInfo;
