import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridFilterModel,
  GridRowEditStopReasons,
  GridRowModesModel,
  GridRowSelectionModel,
  GridSlotsComponent,
  GridSlotsComponentsProps,
} from "@mui/x-data-grid";
import "./Table.scss";
import { filterPanel } from "../../../constants/filterPanelConstant";
import CustomGridPagination from "../../../features/CustomPagination/CustomGridPagination";

export default function AdminTable<T>({
  rowsData,
  columns,
  isLoading,
  paginationModel,
  setPaginationModel,
  maxRecords,
  checkedRowIds,
  handleSelectionRowChange,
  rowModesModel,
  handleRowModesModelChange,
  processRowUpdate,
  isRowSelectable,
  onFilterModelChange,
  checkboxSelection = false,
  slots,
  slotProps,
}: {
  rowsData: T[];
  columns: GridColDef[];
  isLoading: boolean;
  paginationModel: {
    page: number;
    pageSize: number;
  };
  setPaginationModel: (pageData: { page: number; pageSize: number }) => void;
  maxRecords?: number;
  checkedRowIds?: GridRowSelectionModel;
  handleSelectionRowChange?: (newSelection: GridRowSelectionModel) => void;
  rowModesModel?: GridRowModesModel;
  handleRowModesModelChange?: (newRowModesModel: GridRowModesModel) => void;
  processRowUpdate?: (newRow: T) => {
    isNew: boolean;
  } & T;
  isRowSelectable?: () => boolean;
  onFilterModelChange?: (filter: GridFilterModel) => void;
  checkboxSelection?: boolean;
  slots?: Partial<GridSlotsComponent>;
  slotProps?: GridSlotsComponentsProps;
}) {
  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  return (
    <DataGrid
      rows={rowsData}
      columns={columns}
      loading={isLoading}
      disableVirtualization={true}
      filterDebounceMs={400}
      slots={{
        loadingOverlay: () => <></>,
        pagination: () => <CustomGridPagination />,
        ...slots,
      }}
      paginationMode="server"
      paginationModel={paginationModel}
      isRowSelectable={isRowSelectable}
      onPaginationModelChange={setPaginationModel}
      rowCount={maxRecords ?? 0}
      pageSizeOptions={[25]}
      checkboxSelection={checkboxSelection}
      rowSelectionModel={checkedRowIds}
      onRowSelectionModelChange={handleSelectionRowChange}
      editMode="row"
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      filterMode="server"
      onFilterModelChange={onFilterModelChange}
      slotProps={{
        filterPanel,
        ...slotProps,
      }}
      disableRowSelectionOnClick
    />
  );
}
