import { InputHTMLAttributes } from "react";
import { useController, useFormContext } from "react-hook-form";
import { FormErrorMessage } from "../../features/FormErrorMessage/FormErrorMessage";

interface FormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  placeholder: string;
  label: string;
  errorMessage?: string;
}

export default function FormInput({
  label,
  errorMessage,
  ...inputProps
}: FormInputProps) {
  const { register } = useFormContext();

  const {
    fieldState: { error },
  } = useController({
    name: inputProps.name,
  });

  return (
    <div className="form_group">
      <label htmlFor={inputProps.name}>{label}</label>
      <input {...inputProps} {...register(inputProps.name)} />
      {error ? (
        <FormErrorMessage error={error} />
      ) : (
        errorMessage && <p className="error-msg">{errorMessage}</p>
      )}
    </div>
  );
}
