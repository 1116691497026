import "./FormErrorMessage.scss";
import { FieldError } from "react-hook-form";

export const FormErrorMessage = ({
  error,
  className,
}: {
  error: FieldError;
  className?: string;
}) => {
  return <p className={`${className ?? ""} error-msg`}>{error?.message}</p>;
};
