// LoadingProvider.js
import { ReactNode, useEffect, useState } from "react";
import { FeaturesContext } from "./FeaturesContext";
import { useAppSelector } from "../../../store";
import { updateRedirectURL } from "../../../Routes/helper/updateRedirectURL";

const FeaturesProvider = ({ children }: { children: ReactNode }) => {
  const [selectedTab, setSelectedTab] = useState("");
  const [signinTab, setSigninTab] = useState("signin");
  const [redirectURL, setRedirectURL] = useState("");

  const isAuthenticated = useAppSelector(
    (state) => state.signin.isAuthenticated
  );
  const user = useAppSelector((state) => state.signin.user);

  useEffect(() => {
    if (isAuthenticated && user) {
      setRedirectURL(updateRedirectURL(user));
    }
  }, [isAuthenticated, user]);

  return (
    <FeaturesContext.Provider
      value={{
        selectedTab,
        setSelectedTab,
        signinTab,
        setSigninTab,
        redirectURL,
        setRedirectURL,
      }}
    >
      {children}
    </FeaturesContext.Provider>
  );
};

export default FeaturesProvider;
