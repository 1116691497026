import { Routes, Route, useLocation } from "react-router-dom";
import { USER_ROLE } from "../enums/enums";
import { useAppDispatch, useAppSelector } from "../store";
import { Box } from "@mui/material";
import { adminRoutes } from "./helper/adminRoutes";
import { dataEntryRoutes } from "./helper/dataEntryRoutes";
import { commonRoutes } from "./helper/commonRoutes";
import { userFlowScreens } from "./helper/userRoutes";
import ProtectedFooter from "../components/ProtectedFooter";
import Profile from "../components/ProfileComponent/profile";
import LogoComponent from "../components/CommonAppComponents/LogoComponent/LogoComponent";
import AuthGuard from "./guards/AuthGuard";
import UnAuthGuard from "./guards/UnAuthGuard";
import { authRoutes } from "./helper/authRoutes";
import { useEffect } from "react";
import { fetchUserDetails } from "../store/slice/signinSlice";
import NotFound from "../components/NotFound/NotFound";

export default function Router() {
  const isAuthenticated = useAppSelector(
    (state) => state.signin.isAuthenticated
  );

  const { pathname } = useLocation();

  const showMenu =
    isAuthenticated && !commonRoutes.some((route) => route.path === pathname);

  const dispatch = useAppDispatch();

  const token = localStorage.getItem("jwtToken");
  useEffect(() => {
    if (token) {
      dispatch(fetchUserDetails(token));
    }
  }, [dispatch, token]);

  return (
    <Box component="main" className={`${showMenu && "mainComponent "}`}>
      {showMenu && <LogoComponent />}

      <Routes>
        {commonRoutes.map((item, index) => {
          return <Route key={index} path={item.path} element={item.element} />;
        })}
        {authRoutes.map((item, index) => {
          return (
            <Route
              key={index}
              path={item.path}
              element={<UnAuthGuard>{item.element}</UnAuthGuard>}
            />
          );
        })}
        <Route path="/profile" element={<Profile />} />
        <Route path="*" element={<NotFound />} />

        {adminRoutes.map((item, index) => {
          return (
            <Route key={index} element={<AuthGuard role={USER_ROLE.ADMIN} />}>
              <Route path={item.path} element={item.element} />
            </Route>
          );
        })}

        {dataEntryRoutes.map((item, index) => {
          return (
            <Route
              key={index}
              element={<AuthGuard role={USER_ROLE.DATA_ENTRY_OPERATOR} />}
            >
              <Route path={item.path} element={item.element} />
            </Route>
          );
        })}

        {userFlowScreens.map((item, index) => {
          return (
            <Route key={index} element={<AuthGuard role={USER_ROLE.USER} />}>
              <Route path={item.path} element={item.element} />
            </Route>
          );
        })}
      </Routes>
      {showMenu && <ProtectedFooter />}
    </Box>
  );
}
