import { TextField } from "@mui/material";
import { useController, useFormContext } from "react-hook-form";

interface MuiFormInputProps {
  name: string;
  label: string;
  type?: string;
  className?: string;
  errorMessage?: string;
  disabled?: boolean;
}

export default function MuiFormInput({
  label,
  type = "text",
  name,
  className,
  errorMessage,
  disabled = false
}: MuiFormInputProps) {
  const { register } = useFormContext();

  const {
    fieldState: { error },
  } = useController({
    name,
  });

  return (
    <div className="form-field">
      <label htmlFor={name} className="label-name">
        {label}
      </label>
      <TextField
        type={type}
        id={`outlined-${name}`}
        {...register(name)}
        className={`${className ?? ""} `}
        error={!!errorMessage || !!error?.message}
        helperText={errorMessage || error?.message}
        disabled={disabled}
      />
    </div>
  );
}
