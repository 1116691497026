import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  ProfileData,
  profileFormValues,
  rowsInterface,
} from "../../../interfaces/interfaces";
import { RESPONSE_STATUS } from "../../../enums/enums";
import { errorHandler } from "../helper/errorHandler";
import { axiosInstance } from "../../../secutityUtils/axiosInstance";

const fetchUrl = `${import.meta.env.VITE_DEV_ADMIN_URL}/api/admin`;

type InitialState = {
  entries: ProfileData[];
  status: RESPONSE_STATUS;
  deleteStatus: RESPONSE_STATUS;
  error: string | Record<string, string>;
  dataEntries: Record<string, rowsInterface[]>;
};

// Define an initial state
const initialState: InitialState = {
  entries: [],
  status: RESPONSE_STATUS.IDLE, // Can be 'idle', 'loading', 'succeeded', or RESPONSE_STATUS.FAILED
  deleteStatus: RESPONSE_STATUS.IDLE, // Can be 'idle', 'loading', 'succeeded', or RESPONSE_STATUS.FAILED
  error: "",
  dataEntries: {
    newHires: [],
    fundings: [],
    jobPostings: [],
    pressReleases: [],
  },
};

export const fetchDataEntriesForUserId = createAsyncThunk(
  "adminProfile/fetchDataEntries",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      // Make the GET request with headers
      const response = await axiosInstance
        .get(`${fetchUrl}/analysis/getDataEntries/${id}`)
        .catch((err) => {
          throw rejectWithValue(err.response.data);
        });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchProfiles = createAsyncThunk(
  "adminProfile/fetchProfiles",
  async (_, { rejectWithValue }) => {
    try {
      // Make the GET request with headers
      const response = await axiosInstance
        .get(`${fetchUrl}/users/list`)
        .catch((err) => {
          throw rejectWithValue(err.response.data);
        });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const postNewProfiles = createAsyncThunk(
  "adminProfile/postNewProfiles",
  async (
    {
      formData,
    }: {
      formData: profileFormValues;
    },
    { rejectWithValue }
  ) => {
    // Make the POST request with headers and new hires data
    const response = await axiosInstance
      .post(`${fetchUrl}/profileCreation/createProfile`, formData)
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

export const deleteDEOProfile = createAsyncThunk(
  "adminProfile/deleteDEOProfile",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    // Define the body as an object including the id
    const body = {
      id,
    };

    // Make the DELETE request with headers and body
    await axiosInstance
      .delete(`${fetchUrl}/profileCreation/deleteDEOProfile`, {
        data: body, // Pass the body with the 'data' property
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return id;
  }
);

export const editDEOProfile = createAsyncThunk(
  "adminProfile/editDEOProfile",
  async (
    {
      id,
      newData,
    }: {
      id: string;
      newData: profileFormValues;
    },
    { rejectWithValue }
  ) => {
    // Wrap the ID and new data into a single object to send in the body
    const body = {
      id,
      ...newData, // Assuming newData is an object containing the rest of the data to be updated
    };
    const response = await axiosInstance
      .put(`${fetchUrl}/profileCreation/updateProfile`, body)
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

export const createProfileSlice = createSlice({
  name: "adminProfile",
  initialState,
  reducers: {
    fetchProfileEntries: (state) => {
      state.entries = [];
      state.status = RESPONSE_STATUS.IDLE;
      state.error = "";
    },
    resetErrors: (state) => {
      state.error = "";
    },
    resetDeleteStatus: (state) => {
      state.deleteStatus = RESPONSE_STATUS.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfiles.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchProfiles.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.entries = action.payload;
      })
      .addCase(fetchProfiles.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(postNewProfiles.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(postNewProfiles.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        // Optionally, you can update the state with the newly created hire data
      })
      .addCase(postNewProfiles.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(deleteDEOProfile.pending, (state) => {
        state.deleteStatus = RESPONSE_STATUS.LOADING;
      })
      .addCase(deleteDEOProfile.fulfilled, (state, action) => {
        state.deleteStatus = RESPONSE_STATUS.SUCCEEDED;
        state.entries = state.entries.filter(
          (job) => job.id !== action.payload
        );
      })
      .addCase(deleteDEOProfile.rejected, (state, action) => {
        state.deleteStatus = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(editDEOProfile.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(editDEOProfile.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(editDEOProfile.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;

        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(fetchDataEntriesForUserId.fulfilled, (state, action) => {
        const data = { ...action.payload };
        state.dataEntries = {
          newHires: [...data?.excecHires],
          fundings: [...data.fund],
          jobPostings: [...data.jobpost],
          pressReleases: [...data.bussNews],
        };
      });
  },
});

// Export actions and reducer
export const { fetchProfileEntries, resetErrors, resetDeleteStatus } = createProfileSlice.actions;
export default createProfileSlice.reducer;
