import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import RegisterLeft from "./RegisterLeft";
import Footer from "../LandingPage/Footer/Footer";
import Header from "../LandingPage/header/Header";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotPasswordValidationSchema } from "../../validators/forgotPassword-validation-schema";
import FormInput from "../FormComponents/FormInput";
import ButtonCustom from "../../features/ButtonCustom/ButtonCustom";
import CircularLoader from "../Loaders/CircularLoader";
import { RESPONSE_STATUS } from "../../enums/enums";
import useNotification from "../../hooks/useNotification";
import { useAppDispatch, useAppSelector } from "../../store";
import { useEffect, useState } from "react";
import { forgotPassword, resetErrors } from "../../store/slice/signinSlice";

export type ForgotPasswordValues = {
  email: string;
};

const ForgotPassword = () => {
  const authStatus = useAppSelector((state) => state.signin.status);
  const error = useAppSelector((state) => state.signin.error);

  const dispatch = useAppDispatch();
  const { showNotification } = useNotification();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const form = useForm<ForgotPasswordValues>({
    resolver: yupResolver(forgotPasswordValidationSchema),
  });

  useEffect(() => {
    if (authStatus === RESPONSE_STATUS.LOADING) {
      setIsButtonDisabled(true);
    }
    if (authStatus === RESPONSE_STATUS.SUCCEEDED) {
      setIsButtonDisabled(false);
      form.reset();
      showNotification(
        "success",
        "An email has been sent to reset your password."
      );
    } else if (
      authStatus === RESPONSE_STATUS.FAILED &&
      error &&
      typeof error === "string"
    ) {
      setIsButtonDisabled(false);
      showNotification("error", error);
      dispatch(resetErrors());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus, error, form]);

  const handleForgotPassword: SubmitHandler<ForgotPasswordValues> = (data) => {
    dispatch(forgotPassword(data));
  };
  return (
    <>
      <Header />
      <div className="register_main forgot_wrapper">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <RegisterLeft />
            <div className="col-lg-6">
              <div className="form_card">
                <h2 className="forgot_head">Forgot Password</h2>
                <p>Please enter your email address to receive a reset link</p>
                <FormProvider {...form}>
                  <form
                    onSubmit={form.handleSubmit(handleForgotPassword)}
                    onChange={() => dispatch(resetErrors())}
                  >
                    <FormInput
                      type="email"
                      name="email"
                      label="Email"
                      placeholder="Enter your email"
                      errorMessage={
                        typeof error !== "string" ? error["email"] : ""
                      }
                    />
                    <div className="bottom_buttons">
                      <ButtonCustom
                        className="button_submit"
                        type="submit"
                        disabled={isButtonDisabled}
                        endIcon={
                          authStatus === RESPONSE_STATUS.LOADING ? (
                            <CircularLoader size={20} />
                          ) : (
                            <ArrowRightIcon />
                          )
                        }
                      >
                        Send
                      </ButtonCustom>
                    </div>
                  </form>
                </FormProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ForgotPassword;
