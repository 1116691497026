import { CSSProperties, useState } from "react";
import "./ButtonCustom.scss";
import { Button, ButtonOwnProps } from "@mui/material";

interface ButtonProps extends ButtonOwnProps {
  className?: string;
  type?: "button" | "reset" | "submit";
  style?: CSSProperties;
  onClick?: () => void;
}

const ButtonCustom = ({ ...buttonProps }: ButtonProps) => {
  const buttonClasses = `baseButton ${buttonProps.className}`;

  const [hover, setHover] = useState(false);

  const customStyle = buttonProps.disabled
    ? {
        backgroundColor: hover ? "#45a29e" : "white",
        color: hover ? "#fff" : "rgba(0, 0, 0, 0.26)",
        border: "1px solid rgba(0, 0, 0, 0.12)",
      }
    : {
        backgroundColor: hover ? "#fff" : "#45a29e",
        color: hover ? "#45A29E" : "#fff",
        border: "1px solid #45A29E",
      };

  const buttonStyles = buttonProps.style ?? customStyle;

  return (
    <Button
      type={buttonProps?.type || "button"}
      variant={"contained"}
      style={buttonProps.className ? {} : buttonStyles}
      className={buttonClasses}
      {...buttonProps}
      endIcon={buttonProps.endIcon}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {buttonProps.children}
    </Button>
  );
};

export default ButtonCustom;
