import LinearProgress from "@mui/material/LinearProgress";
import "./confidenceRatio.scoped.scss";
import { Tooltip } from "@mui/material";

export default function ConfidenceRatioCell({ ratio }: { ratio: number }) {
  function getBarClass() {
    if (ratio < 50) {
      return "red";
    }
    if (ratio < 80) {
      return "warning";
    }
    return "success";
  }
  return (
    <div className="flex items-center justify-center h-full gap-2">
      {ratio ? (
        <Tooltip title={`${ratio}%`} arrow>
          <LinearProgress
            value={ratio}
            variant="determinate"
            className="w-full h-4"
            classes={{ bar: `${getBarClass()}` }}
          />
        </Tooltip>
      ) : (
        "----"
      )}
    </div>
  );
}
