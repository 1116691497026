import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sendToFilterServicePercolate } from "../../../services/filterService";
import {
  fundsDataInterface,
  fundsFormValuesInterface,
} from "../../../interfaces/interfaces";
import { RESPONSE_STATUS } from "../../../enums/enums";
import { errorHandler } from "../helper/errorHandler";
import { axiosInstance } from "../../../secutityUtils/axiosInstance";
import { GridRowId } from "@mui/x-data-grid";

const fetchUrl = `${import.meta.env.VITE_DEV_ADMIN_URL}/api/admin/dataentry`;
const draftURL = `${import.meta.env.VITE_DEV_ADMIN_URL}/api/admin/draft`;

type InitialState = {
  entries: fundsDataInterface[];
  draftEntries: fundsDataInterface[];
  status: RESPONSE_STATUS;
  error: string | Record<string, string>;
  maxRecords?: number;
  selectedIds?: string[];
  publishResponse?: {
    invalidDraftIds: Array<GridRowId>;
    validDraftIds: Array<GridRowId>;
    validDrafts: number;
    invalidDrafts: number;
  };
};

// Define an initial state
const initialState: InitialState = {
  entries: [],
  draftEntries: [],
  status: RESPONSE_STATUS.IDLE, // Can be 'idle', 'loading', 'succeeded', or 'failed'
  error: "",
};

const pageName = "FUNDING";

export const fetchAdminFunds = createAsyncThunk(
  "adminFunds/fetchAdminFunds",
  async (
    {
      page,
      count = 25,
      filters,
    }: {
      page: number;
      count: number;
      filters?: { key: string; value: string }[];
    },
    { rejectWithValue }
  ) => {
    try {
      let filtersQueries = "";
      filters?.forEach((item) => {
        filtersQueries += `&${item.key}=${item.value}`;
      });
      // Make the GET request with headers
      const response = await axiosInstance
        .get(
          `${fetchUrl}/myEntries/funds?page=${page}&count=${count}${filtersQueries}`
        )
        .catch((err) => {
          throw rejectWithValue(err.response.data);
        });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchDraftAdminFunds = createAsyncThunk(
  "adminFunds/fetchDraftAdminFunds",
  async (
    {
      page,
      count = 25,
    }: {
      page: number;
      count?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      // Make the GET request with headers
      const response = await axiosInstance
        .get(`${draftURL}/fundings?page=${page}&count=${count}`)
        .catch((err) => {
          throw rejectWithValue(err.response.data);
        });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const postAdminFunds = createAsyncThunk(
  "adminFunds/postAdminHires",
  async (
    {
      formData,
    }: {
      formData: fundsFormValuesInterface;
    },
    { rejectWithValue }
  ) => {
    const formattedData = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [
        key,
        typeof value === "number" ? value : String(value).toLowerCase(),
      ])
    );

    // Make the POST request with headers and new hires data
    const response = await axiosInstance
      .post(`${fetchUrl}/${pageName}`, formattedData)
      .then(async (res) => {
        const { id, ...restOfData } = res.data;
        await sendToFilterServicePercolate({
          ...restOfData,
          page: "NewFunding",
        });
        return res.data;
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

export const publishDraftFunds = createAsyncThunk(
  "adminFunds/publishDraftFunds",
  async (
    {
      ids,
      all = false,
    }: {
      ids: string[];
      all?: boolean;
    },
    { rejectWithValue }
  ) => {
    // Make the POST request with headers and new hires data
    const response = await axiosInstance
      .post(`${draftURL}/draftToLive/fundings?all=${all}`, ids)
      .then(async (res) => {
        return res.data;
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response;
  }
);

export const deleteAdminFunds = createAsyncThunk(
  "adminFunds/deleteAdminFunds",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    // assuming you are identifying the job to be deleted by "jobId"

    // Make the DELETE request with headers
    await axiosInstance.delete(`${fetchUrl}/${pageName}/${id}`).catch((err) => {
      throw rejectWithValue(err.response.data);
    });

    return id; // return deleted jobId to remove it from the state
  }
);

export const deleteDraftFunds = createAsyncThunk(
  "adminFunds/deleteDraftFunds",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    // Make the DELETE request with headers
    await axiosInstance.delete(`${draftURL}/fundings/${id}`).catch((err) => {
      throw rejectWithValue(err.response.data);
    });

    return id;
  }
);

// Create an asynchronous thunk action to edit existing data
export const editAdminFunds = createAsyncThunk(
  "adminFunds/editAdminFunds",
  async (
    {
      id,
      formData,
    }: {
      id: string;
      formData: fundsFormValuesInterface;
    },
    { rejectWithValue }
  ) => {
    const data = { ...formData };
    delete data.createdDate;
    delete data.lastModifiedDate;

    const response = await axiosInstance
      .put(`${fetchUrl}/${pageName}/${id}`, data)
      .then(async (res) => {
        const { id, ...restOfData } = res.data;
        await sendToFilterServicePercolate({
          ...restOfData,
          page: "NewFunding",
        });
        return res.data;
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

export const editDraftFunds = createAsyncThunk(
  "adminFunds/editDraftFunds",
  async (
    {
      id,
      formData,
    }: {
      id: string;
      formData: fundsFormValuesInterface;
    },
    { rejectWithValue }
  ) => {
    const response = await axiosInstance
      .put(`${draftURL}/fundings/${id}`, formData)
      .then(async (res) => {
        return res.data;
      })
      .catch((err) => {
        throw rejectWithValue(err.response.data);
      });

    return response.data;
  }
);

export const adminFundingSlice = createSlice({
  name: "adminFunds",
  initialState,
  reducers: {
    fetchFunds: (state) => {
      state.entries = [];
      state.status = RESPONSE_STATUS.IDLE;
      state.error = "";
    },
    resetErrors: (state) => {
      state.error = "";
    },
    setSelectedIds: (state, action) => {
      state.selectedIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminFunds.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchAdminFunds.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.entries = action.payload.data;
        state.maxRecords = action.payload.totalElements;
      })
      .addCase(fetchAdminFunds.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(fetchDraftAdminFunds.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchDraftAdminFunds.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.draftEntries = action.payload.data;
        state.maxRecords = action.payload.totalElements;
      })
      .addCase(fetchDraftAdminFunds.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(postAdminFunds.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(postAdminFunds.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        // Optionally, you can update the state with the newly created hire data
      })
      .addCase(postAdminFunds.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(publishDraftFunds.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(publishDraftFunds.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.publishResponse = action.payload;
      })
      .addCase(publishDraftFunds.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(deleteAdminFunds.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(deleteAdminFunds.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.entries = state.entries.filter(
          (job) => job.id !== action.payload
        );
      })
      .addCase(deleteAdminFunds.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(deleteDraftFunds.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(deleteDraftFunds.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.entries = state.entries.filter(
          (job) => job.id !== action.payload
        );
      })
      .addCase(deleteDraftFunds.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(editAdminFunds.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(editAdminFunds.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(editAdminFunds.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      })
      .addCase(editDraftFunds.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(editDraftFunds.fulfilled, (state) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
      })
      .addCase(editDraftFunds.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        const payload = action.payload as { message: string } | string;
        errorHandler<InitialState>(state, payload);
      });
  },
});

// Export actions and reducer
export const { fetchFunds, resetErrors, setSelectedIds } =
  adminFundingSlice.actions;
export default adminFundingSlice.reducer;
