import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { chartInterface } from "../../../interfaces/interfaces";
import { RESPONSE_STATUS } from "../../../enums/enums";
import { axiosInstance } from "../../../secutityUtils/axiosInstance";

// Define an initial state
const initialState: {
  entries: chartInterface[];
  status: RESPONSE_STATUS;
  error: string;
} = {
  entries: [],
  status: RESPONSE_STATUS.IDLE, // Can be 'idle', 'loading', 'succeeded', or 'failed'
  error: "",
};

export const fetchBusinessCount = createAsyncThunk(
  "bussinessCount/fetchBusinessCount",
  async () => {
    try {
      // Make the GET request with headers
      const response = await axiosInstance.get(
        `${
          import.meta.env.VITE_DEV_ADMIN_URL
        }/api/admin/analysis/BussinessCount`
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const businessCountSlice = createSlice({
  name: "bussinessCount",
  initialState,
  reducers: {
    fetchCounts: (state) => {
      state.entries = [];
      state.status = RESPONSE_STATUS.IDLE;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinessCount.pending, (state) => {
        state.status = RESPONSE_STATUS.LOADING;
      })
      .addCase(fetchBusinessCount.fulfilled, (state, action) => {
        state.status = RESPONSE_STATUS.SUCCEEDED;
        state.entries = action.payload;
      })
      .addCase(fetchBusinessCount.rejected, (state, action) => {
        state.status = RESPONSE_STATUS.FAILED;
        state.error = action.error.message || "";
      });
  },
});

// Export actions and reducer
export const { fetchCounts } = businessCountSlice.actions;
export default businessCountSlice.reducer;
