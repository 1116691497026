import * as yup from "yup";
import { regex } from "../../../regex/regex";
import { nameValidationSchema } from "../../../validators/name-validation-schema";
import { passwordValidationSchema } from "../../../validators/password-validation-schema";

export const signUpSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .required("Email is a required field")
      .email("Invalid email format")
      .matches(regex.mailFormat, "Invalid email format")
      .trim(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match"),
  })
  .concat(passwordValidationSchema)
  .concat(nameValidationSchema);
