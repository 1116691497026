import { useContext, useEffect } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
  Line,
  ComposedChart,
} from "recharts";
import { fetchScrappedCount } from "../../../store/slice/chartSlice/scrapperCountSlice";
import "./chart.scss";
import { useAppDispatch, useAppSelector } from "../../../store";
import LoadingContext from "../../../contexts/APIContext/Loading/LoadingContext";

const ScrappedCountChart = () => {
  const dispatch = useAppDispatch();
  const entries = useAppSelector((state) => state.scrappedCount.entries);

  const { isLoading } = useContext(LoadingContext);

  useEffect(() => {
    dispatch(fetchScrappedCount()); // Your action creator
  }, [dispatch]);

  return (
    <>
      {!isLoading && (
        <>
          {entries && (
            [
              'DraftNewFunding',
              'DraftExecutiveHires',
              'DraftJobPost',
              'DraftBusinessNews',
            ].map(key => (
              <div className="chart" key={key}>
                <div>
                  <h3 className='chart-subscription-h3'>{key}</h3>
                  <ComposedChart
                    key={key}
                    width={500}
                    height={300}
                    data={entries}
                  >
                    <defs>
                      <linearGradient id="DraftNewFunding" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                      </linearGradient>
                      <linearGradient id="DraftExecutiveHires" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                      </linearGradient>
                      <linearGradient id="DraftJobPost" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#ffc658" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#ffc658" stopOpacity={0}/>
                      </linearGradient>
                      <linearGradient id="DraftBusinessNews" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#ff7300" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#ff7300" stopOpacity={0}/>
                      </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Area type="monotone" dataKey={`${key}.count`} name='count' stroke="#8884d8" fillOpacity={1} fill={`url(#${key})`} />
                    <Line type="monotone" dataKey={`${key}.autoScrapeCount`} name='auto approved' stroke="#ff0000" />
                  </ComposedChart>
                </div>
              </div>
            ))
          )}
        </>
      )}
    </>
  );
};

export default ScrappedCountChart;
