import * as Yup from "yup";
import { regex } from "../regex/regex";
import { companyValidationSchema } from "./company-validation-schema";

export const jobsValidationSchema = Yup.object()
  .shape({
    jobTitle: Yup.string()
      .required("Job Title is required")
      .min(2, "Job Title must have at least 2 characters")
      .max(30, "Job Title must be under 30 characters")
      .trim(),
    jobLink: Yup.string()
      .required("Job Link is required")
      .matches(regex.urlFormat, "Please enter a valid URL!")
      .trim(),
  })
  .concat(companyValidationSchema);
