import { generateHeaderColumns } from "../../../../utils/userFlow";
import { userFlowScreens } from "../../../../constants/userFlowConstants";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  deleteLeadsfromRow,
  fetchLeadsData,
} from "../../../../store/slice/userFlowSlice";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { rowsInterface } from "../../../../interfaces/interfaces";
import useNotification from "../../../../hooks/useNotification";
import { useContext, useState } from "react";
import DeleteConfirmationModal from "../../../DeleteConfirmationModal/DeleteConfirmationModal";
import LoadingContext from "../../../../contexts/APIContext/Loading/LoadingContext";
import { useSearchParams } from "react-router-dom";
import { RESPONSE_STATUS } from "../../../../enums/enums";
import { filterPanel } from "../../../../constants/filterPanelConstant";
import CustomGridPagination from "../../../../features/CustomPagination/CustomGridPagination";

function CustomTabPanel(props: { value: number; index: number }) {
  const [searchParams] = useSearchParams();
  const queryTab = searchParams.get("tab") ?? "hires";
  const { value, index, ...other } = props;
  const dispatch = useAppDispatch();
  const { showNotification } = useNotification();
  const { isLoading } = useContext(LoadingContext);
  const deleteStatus = useAppSelector((state) => state.userflow.deleteStatus);

  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const handleEditClick = () => {
    dispatch(
      deleteLeadsfromRow({
        deletedRowID: [deleteId],
        screen: userFlowScreens[index],
      })
    ).then(() => {
      showNotification("success", "Lead deleted successfully");
      setShowModal(false);
      dispatch(fetchLeadsData(queryTab));
    });
  };

  const EditCell = ({ id }: { id: string }) => {
    return (
      <button
        onClick={() => {
          setDeleteId(id);
          setShowModal(true);
        }}
        style={{ textAlign: "center" }}
      >
        <DeleteIcon style={{ color: "#45A29E" }} />
      </button>
    );
  };

  const formatToAddDeleteButton = () => {
    let cols = generateHeaderColumns(userFlowScreens[index]);
    return [
      {
        field: "delete",
        headerName: "Delete",
        filterable: false,
        width: 100,
        renderCell: (params: { row: rowsInterface }) => (
          <EditCell id={params.row.id} />
        ), // Pass the id to EditCell
      },
      ...cols,
    ];
  };
  const columns: GridColDef[] = formatToAddDeleteButton();
  const checkboxSelection = false;

  const leadsRowData = useAppSelector((state) => state.userflow[queryTab]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="min-h-[300px] h-[calc(100vh-350px)] py-[20px]">
          <DataGrid
            sx={{
              "& .MuiDataGrid-cell > p": {
                marginBottom: "0",
              },
            }}
            rows={leadsRowData}
            columns={columns}
            loading={isLoading}
            disableVirtualization={true}
            filterDebounceMs={400}
            slots={{
              loadingOverlay: () => <></>,
              pagination: () => <CustomGridPagination />,
            }}
            isRowSelectable={() => false}
            checkboxSelection={checkboxSelection}
            slotProps={{
              filterPanel,
            }}
            disableRowSelectionOnClick
          />
        </div>
      )}
      {showModal && deleteId && (
        <DeleteConfirmationModal
          deleteFn={() => handleEditClick()}
          onClose={() => setShowModal(false)}
          isLoading={deleteStatus === RESPONSE_STATUS.LOADING}
        />
      )}
    </div>
  );
}

export default CustomTabPanel;
