import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import "./FundingTable.scss";
import { fundsDataInterface } from "../../../interfaces/interfaces";
import {
  DATA_ENTRY,
  dataPathConstants,
} from "../../../Routes/helper/dataEntryRoutes";
import LinkCell from "../../LinkCell/LinkCell";
import { useAppSelector } from "../../../store";
import { filterPanel } from "../../../constants/filterPanelConstant";
import CustomGridPagination from "../../../features/CustomPagination/CustomGridPagination";

function FundingTable({
  rowsData,
  isLoading,
  paginationModel,
  setPaginationModel,
  onFilterChange,
}: {
  rowsData: fundsDataInterface[];
  isLoading: boolean;
  paginationModel: {
    page: number;
    pageSize: number;
  };
  onFilterChange: (filter: GridFilterModel) => void;
  setPaginationModel: (pageData: { page: number; pageSize: number }) => void;
}) {
  const maxRecords = useAppSelector((state) => state.dataFunds.maxRecords);

  const navigate = useNavigate();

  const EditCell = ({ id }: { id: string }) => {
    const handleEditClick = () => {
      navigate(
        `/${DATA_ENTRY}/${dataPathConstants.DATA_FUNDING}/${dataPathConstants.EDIT_FUNDS}/${id}`
      );
    };
    return (
      <div onClick={handleEditClick} style={{ cursor: "pointer" }}>
        <EditIcon style={{ color: "#45A29E" }} />
      </div>
    );
  };

  const columns: GridColDef<fundsDataInterface>[] = [
    {
      field: "fundingRaised",
      headerName: "Funding Raised",
      width: 180,
      type: "number",
    },
    {
      field: "companyName",
      headerName: "Company Name",
      width: 180,
      sortable: false,
    },
    {
      field: "companyWebsite",
      headerName: "Company Website",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <LinkCell url={params.value || ""} />
      ),
    },
    { field: "companyHQ", headerName: "Company HQ", width: 200 },
    { field: "industry", headerName: "Industry", width: 180 },
    {
      field: "companyEmployeeCount",
      headerName: "Employee Count",
      width: 150,
      type: "number",
    },
    {
      field: "companyLinkedIn",
      headerName: "Company LinkedIn",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <LinkCell url={params.value || ""} />
      ),
    },

    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <EditCell id={params.row.id} />
      ), // Pass the id to EditCell
    },
  ];
  return (
    <>
      <div className="tableParent">
        <div className="min-h-[300px] h-[calc(100vh-300px)] py-[20px]">
          <DataGrid
            rows={rowsData}
            columns={columns}
            loading={isLoading}
            disableVirtualization={true}
            filterDebounceMs={400}
            slots={{
              loadingOverlay: () => <></>,
              pagination: () => <CustomGridPagination />,
            }}
            isRowSelectable={() => false}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            filterMode="server"
            onFilterModelChange={onFilterChange}
            rowCount={maxRecords ?? 0}
            pageSizeOptions={[25]}
            slotProps={{
              filterPanel,
            }}
            disableRowSelectionOnClick
          />
        </div>
      </div>
    </>
  );
}

export default FundingTable;
