import { useParams } from "react-router-dom";
import HeaderComponent from "../CommonAppComponents/HeaderComponent/HeaderComponent";
import ProfileForm from "./ProfileForm";

export default function AddEditProfile() {
  const { id } = useParams();
  const primaryText = id ? "Edit Profile" : "Create Profile";
  const secondaryText = id ? "Edit Profile" : "Add New Profile";
  return (
    <>
      <HeaderComponent
        primaryText={primaryText}
        secondaryText={secondaryText}
      />
      <ProfileForm />
    </>
  );
}
