import { useContext, useEffect, useState } from "react";
import FundingTable from "../FundingTable/FundingTable";
import { fetchMyFunds } from "../../../store/slice/fundingSlice";
import { useAppDispatch, useAppSelector } from "../../../store";
import LoadingContext from "../../../contexts/APIContext/Loading/LoadingContext";
import usePagination from "../../../hooks/usePagination";
import { handleColumnFilters } from "../../../utils/handleColumnFilters";

function DataFunding() {
  const dispatch = useAppDispatch();
  const entries = useAppSelector((state) => state.dataFunds.entries);

  const { setIsLoading, isLoading } = useContext(LoadingContext);

  const { paginationModel, updatePageParams } = usePagination();

  const [filters, setFilters] = useState<{ key: string; value: string }[]>([]);

  useEffect(() => {
    setIsLoading(true);
    // Dispatch the fetchMyEntries action when the component mounts
    dispatch(
      fetchMyFunds({
        page: paginationModel.page,
        count: paginationModel.pageSize,
        filters: filters,
      })
    ).then(() => setIsLoading(false));
  }, [dispatch, setIsLoading, paginationModel, filters]);

  return (
    <>
      <FundingTable
        rowsData={entries}
        isLoading={isLoading}
        paginationModel={paginationModel}
        setPaginationModel={updatePageParams}
        onFilterChange={(filters) => handleColumnFilters(filters, setFilters)}
      />
    </>
  );
}

export default DataFunding;
