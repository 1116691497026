const TrustedBy = () => {
  return (
    <div className="trusted_wrapper">
      <div className="container">
        <div className="text-center trusted_btn">Trusted By</div>
        <h1 className="text-center py-4">
          Trusted By Sales Professionals At Leading Companies
        </h1>
        <div className="row align-items-center justify-content-center">
          <div className="col-sm-6 col-md-4 col-lg-3 d-flex align-items-center justify-content-center mb-3">
            <img src="/assets/images/salesforce.png" alt="" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 d-flex align-items-center justify-content-center mb-3">
            <img src="/assets/images/netsuite.png" alt="" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 d-flex align-items-center justify-content-center mb-3">
            <img src="/assets/images/tropic.png" alt="" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 d-flex align-items-center justify-content-center mb-3">
            <img src="/assets/images/coupa.png" alt="" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 d-flex align-items-center justify-content-center mb-3">
            <img src="/assets/images/zappler.png" alt="" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 d-flex align-items-center justify-content-center mb-3">
            <img src="/assets/images/ownbackup.png" alt="" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 d-flex align-items-center justify-content-center mb-3">
            <img src="/assets/images/servicenow.png" alt="" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 d-flex align-items-center justify-content-center mb-3">
            <img src="/assets/images/oracle.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustedBy;
